import ft from '../../components/mainStyles.module.scss'
import Container from '../../components/container'
import { useEffect, useState } from 'react'
import MarkTimesModal from './markTimesModal'
import Translate from '../../components/lang'
import { markTimesApi } from '../../services/markTimesService'
import Loading from '../../components/loading'
import { BeautyDate } from '../../utils'
export default function MarkTimes(props) {
	const [data, setData] = useState([]),
		[loading, setLoading] = useState(true),
		[show, setShow] = useState(false),
		{ lang, setMarkLoading, setMarkTime, user } = props

	useEffect(() => {
		markTimesApi.all().then((res) => {
			setData(res.data.data)
			setLoading(false)
		})
	}, [])

	const handleStopMark = (id) => {
		let temp = window.confirm(Translate(lang, 'global.stopMark'))
		if (temp) {
			setLoading(true)
			markTimesApi.stopMark(id).then((res) => {
				if (res.data.success) {
					markTimesApi.all().then((res) => {
						setMarkTime(null)
						setData(res.data.data)
						setMarkLoading((prev) => !prev)
						setLoading(false)
					})
				}
			})
		}
	}

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'markTimes.startDate')}</h5>
					{/* {!loading && data.find((d) => d.status === 1)?.status !== 1 && (
						<div className={ft.btns}>
							<button className={ft.add__btn} onClick={() => setShow(true)}>
								<span>{Translate(lang, 'markTimes.start')}</span>
							</button>
						</div>
					)} */}
				</div>
				<div className={ft.table}>
					<table className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'markTimes.text')}</td>
								<td>{Translate(lang, 'markTimes.initialDate')}</td>
								<td>{Translate(lang, 'markTimes.endDate')}</td>
								<td className='text-center'>{Translate(lang, 'table.action')}</td>
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, i) => (
									<tr>
										<td>{i + 1}</td>
										<td className='w-50'>{d.text}</td>
										<td>{BeautyDate(d.startTime)}</td>
										<td>{BeautyDate(d.endTime)}</td>
										<td className='text-center'>
											{d.status === 1 && user?.role === 'admin' ? (
												<button
													className={ft.notSuccess__btn}
													style={{ padding: '4px 10px', borderRadius: '20px' }}
													onClick={() => handleStopMark(d._id)}>
													{Translate(lang, 'markTimes.toEnd')}
												</button>
											) : (
												'---'
											)}
										</td>
									</tr>
								))
							) : (
								<Loading size='md' big={true} cl='mt-3' />
							)}
						</tbody>
					</table>
						{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
				</div>
			</Container>
			{!!show && <MarkTimesModal {...props} show={show} setShow={setShow} setLoading={setLoading} setData={setData} />}
		</div>
	)
}
