import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { orgApi } from '../../services/orgListService';
import { docApi } from '../../services/doc/docService';

import Pagin from '../../components/pagin';
import Filter from '../../components/filter';
import Translate from '../../components/lang';
import Loading from '../../components/loading';
import Container from '../../components/container';
import CustomSelect from '../../components/customSelect';

import ExportExcel from './components/ExportExcel';

import ft from '../../components/mainStyles.module.scss';

const Monitoring = ({ lang, periods, selPer }) =>  {
   const { docId } = useParams();
   const history = useHistory();
   const [doc, setDoc] = useState({});
   const [data, setData] = useState([]);
   const [options, setOptions] = useState([]);
   const [selected, setSelected] = useState([]);
   const [showFilter, setShowFilter] = useState(false);
   const [page, setPage] = useState({ limit: 10000, page: 1 });
   const [pageNum, setPageNum] = useState(1);
   const [pc, setPc] = useState(0);
   const [loading, setLoading] = useState(true);
   const [filter, setFilter] = useState({});

   const handleChange = (selected) => {
      setSelected(selected);
   }

   const handleFilter = (e) => {
      e.preventDefault();

      const orgIds = selected.map(s => s.value);

      const { start, end } = e.target.elements;

      const newFilter = {};

      if (orgIds.length) newFilter.orgIds = orgIds;
      if (start.value) newFilter.start = start.value;
      if (end.value) newFilter.end = end.value;

      setFilter(newFilter);


      setShowFilter(false);

      setPage({ ...page, page: 1 });
   }

   useEffect(() => {
      setLoading(true);

      docApi
         .neighborhoodMonitoring(docId, { ...page, ...filter })
         .then(res => {
            if (res.data.success) {
               setPageNum(Math.ceil(res.data.num));
               setPc((page.page - 1) * page.limit);
               setData(res.data.data);
            }
         })
         .catch(err => {
            console.log(err);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [page]);

   useEffect(() => {
      docApi.getById(docId)
        .then(res => {
           if (res.data.success) {
              setDoc(res.data.data);
           }
        })
        .catch(err => {
           console.log(err);
        });
   }, []);

   useEffect(() => {
      orgApi.getAll()
         .then(res => {
            if (res.data.success) {
               const DATA = [];

               res.data.data.map((d) => {
                  DATA.push({
                     value: d._id,
                     label: d.name.uz
                  });
               });

               setOptions(DATA);
            }
         })
         .catch(err => {
            console.log(err);
         });
   }, []);

   return (
      <div>
         <Container>
            <div className={ft.content__head}>
               <h5 className={ft.title}>Monitoring ({doc.number})</h5>

               <div className={ft.btns}>
                  <ExportExcel
                     lang={lang}
                     docId={docId}
                     filter={filter}
                     period={periods[selPer]}
                  />

                  <button
                     className={`${ft.primary__btn} ml-2`}
                     onClick={() => setShowFilter(true)}
                  >
                     {Translate(lang, 'global.filter')}
                  </button>

                  <button
                     className={`${ft.close__btn} ml-2`}
                     onClick={() => history.goBack()}
                  >
                     {Translate(lang, 'global.cancel')}
                  </button>
               </div>
            </div>

            <div className={ft.table}>
               <table className={ft.table__main}>
                  <thead>
                     <tr>
                        <td>#</td>
                        <td className='text-center'>Viloyat</td>
                        <td className='text-center'>Tuman</td>
                        <td className='text-center'>Mahalla</td>
                        <td className='text-center'>Pinfl</td>
                        <td className='text-center'>Ism familiya</td>
                        <td className='text-center'>Telefon raqam</td>
                        <td className='text-center'>Holati</td>
                     </tr>
                  </thead>

                  <tbody>
                     {!loading ? (
                        data?.map((item, index) => (
                           <tr key={index}>
                              <td>{pc + index + 1}</td>
                              <td>{item.obl_name}</td>
                              <td>{item.area_name}</td>
                              <td>{item.disstrict_name}</td>
                              <td>{item.pinfl}</td>
                              <td>{item.full_name}</td>
                              <td>{item.mobile_phone}</td>
                              <td>{Translate(lang, `status.${item.status?.toUpperCase()}`)}</td>
                           </tr>
                        ))
                     ) : (
                        <tr>
                           <td colSpan={8}>
                              <Loading cl='mt-3' big={true} size='md' />
                           </td>
                        </tr>
                     )}
                  </tbody>
               </table>

               <iframe name='print_frame' width='0' height='0' frameBorder='0' src='about:blank'></iframe>

               {!loading && !data.length && (
                  <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>
               )}

               {!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
            </div>
         </Container>

         <Filter show={showFilter} setShow={setShowFilter}>
            <Form className={ft.form} onSubmit={handleFilter}>
               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.orgName')}</div>
                  <CustomSelect handleChange={handleChange} options={options} selected={selected} lang={lang} />
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.from')}</div>
                  <Form.Control name='start' defaultValue={filter.start} type='date' />
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.to')}</div>
                  <Form.Control name='end' defaultValue={filter.end} type='date' />
               </Form.Label>

               <button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
                  {Translate(lang, 'global.search')}
               </button>
            </Form>
         </Filter>
      </div>
   )
}

export default Monitoring;
