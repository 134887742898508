import Container from '../../components/container'
import Back from '../../components/backButton'
import ft from '../../components/mainStyles.module.scss'
import { Form } from 'react-bootstrap'
import { useRef, useState } from 'react'
import Translate from '../../components/lang'
import Loading from '../../components/loading'
import { authApi } from '../../services/authService'
import { GeneratePassword, ValidatePassword } from '../../utils'

const pass = (
	<svg width='150' height='129' viewBox='0 0 150 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<ellipse cx='74.5781' cy='120.266' rx='74.5781' ry='8.73438' fill='#025E92' fillOpacity='0.15' />
		<g clipPath='url(#clip0)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.3034 54.5413H38.7861V53.4902V36.1273C38.7861 26.1903 42.8408 17.1682 49.3595 10.6182V10.6085C55.8781 4.06821 64.8898 0 74.7938 0C84.6882 0 93.6902 4.06821 100.219 10.6085L100.228 10.6182C106.747 17.1585 110.802 26.1903 110.802 36.1273V53.4902V54.5316H123.519C125.061 54.5316 126.322 55.7968 126.322 57.3443V116.771C126.322 118.319 125.061 119.584 123.519 119.584H24.3034C22.761 119.584 21.5 118.319 21.5 116.771V57.354C21.5 55.8065 22.761 54.5413 24.3034 54.5413ZM46.5173 83.4957C50.7563 83.4957 54.1999 86.941 54.1999 91.1941C54.1999 95.4473 50.766 98.9023 46.5173 98.9023C42.2782 98.9023 38.8346 95.457 38.8346 91.1941C38.8346 86.941 42.2685 83.4957 46.5173 83.4957ZM101.305 83.4957C105.544 83.4957 108.978 86.941 108.978 91.1941C108.978 95.4473 105.544 98.9023 101.305 98.9023C97.0659 98.9023 93.6222 95.457 93.6222 91.1941C93.6222 86.941 97.0659 83.4957 101.305 83.4957ZM73.9111 83.4957C78.1502 83.4957 81.5841 86.941 81.5841 91.1941C81.5841 95.4473 78.1502 98.9023 73.9111 98.9023C69.672 98.9023 66.2284 95.457 66.2284 91.1941C66.2284 86.941 69.6623 83.4957 73.9111 83.4957ZM36.6229 77.8216H111.18C115.157 77.8216 118.407 81.082 118.407 85.0626V97.3257C118.407 101.306 115.157 104.567 111.18 104.567H36.6229C32.6554 104.567 29.4058 101.306 29.4058 97.3257V85.0626C29.4058 81.082 32.6554 77.8216 36.6229 77.8216ZM52.774 54.5413H96.804V53.4902V35.9229C96.804 29.8498 94.3304 24.3217 90.3435 20.3216C86.3567 16.3215 80.8469 13.83 74.8035 13.83C68.7505 13.83 63.2407 16.3118 59.2538 20.3119C55.267 24.3119 52.7934 29.84 52.7934 35.9132V53.4804L52.774 54.5413Z'
				fill='url(#paint0_linear)'
			/>
		</g>
		<defs>
			<linearGradient id='paint0_linear' x1='126.312' y1='119.594' x2='21.5' y2='8.77629e-06' gradientUnits='userSpaceOnUse'>
				<stop stopColor='#01598B' />
				<stop offset='1' stopColor='#1593DA' />
			</linearGradient>
			<clipPath id='clip0'>
				<rect width='104.812' height='119.594' fill='white' transform='translate(21.5)' />
			</clipPath>
		</defs>
	</svg>
)

export default function UserSet(props) {
	const { lang, history } = props,
		[view, setView] = useState(false),
		[load, setLoad] = useState(false),
		[err, setErr] = useState(false),
		[errSimp, setErrSimp] = useState(false),
		[errPass, setErrPass] = useState(false),
		password = useRef(''),
		passwordConfirm = useRef('')

	const handleSubmit = (e) => {
		e.preventDefault()
		if (ValidatePassword(e.target[1].value)) {
			setErrPass(false)
			if (e.target[1].value === e.target[2].value) {
				setErrSimp(false)
				setErr(false)
				setLoad(true)
				authApi
					.passEdit({
						oldPassword: e.target[0].value,
						password: e.target[1].value,
					})
					.then((res) => {
						if (res.data.success) {
							setLoad(false)
							e.target[0].value = ''
							e.target[1].value = ''
							e.target[2].value = ''
							history.push('/')
						}
					})
					.catch((err) => {
						setErr(true)
						setLoad(false)
					})
			} else setErrSimp(true)
		} else setErrPass(true)
	}
	return (
		<div className='w-50' style={{ margin: '0 auto' }}>
			<div className='mb-3' style={{ width: 'fit-content' }}>
				<Back {...props} />
			</div>
			<div>
				<Container>
					<div className={ft.content__head}>
						<h6 className={ft.title} style={{ lineHeight: '22px' }}>
							{Translate(lang, 'login.resetPass')}
						</h6>
					</div>
					<div className='mt-3 d-flex'>
						<div className='mr-4 d-flex align-items-center'>{pass}</div>
						<Form className={ft.form} onSubmit={handleSubmit}>
							<Form.Label>
								<div className={ft.lab}>{Translate(lang, 'login.lastPass')}</div>
								<div className='position-relative'>
									<Form.Control type={view ? 'text' : 'password'} minLength={8} required />
									<i
										className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
										style={{
											top: '50%',
											right: 0,
											transform: 'translate(-10px, -8px)',
											cursor: 'pointer',
										}}
										onClick={() => setView(!view)}></i>
								</div>
							</Form.Label>
							<Form.Label>
								<div className={`${ft.lab} d-flex justify-content-between`}>
									{Translate(lang, 'login.pass')}
									<span
										style={{ cursor: 'pointer', lineHeight: '24px' }}
										onClick={() => {
											const temp = GeneratePassword()
											password.current.value = temp
											passwordConfirm.current.value = temp
										}}>
										{Translate(lang, 'global.genPass')}
									</span>
								</div>
								<div className='position-relative'>
									<Form.Control ref={password} minLength={8} type={view ? 'text' : 'password'} required />
									<i
										className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
										style={{
											top: '50%',
											right: 0,
											transform: 'translate(-10px, -8px)',
											cursor: 'pointer',
										}}
										onClick={() => setView(!view)}></i>
								</div>
							</Form.Label>
							<Form.Label>
								<div className={ft.lab}>{Translate(lang, 'login.confNewPass')}</div>
								<div className='position-relative'>
									<Form.Control ref={passwordConfirm} minLength={8} type={view ? 'text' : 'password'} required />
									<i
										className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
										style={{
											top: '50%',
											right: 0,
											transform: 'translate(-10px, -8px)',
											cursor: 'pointer',
										}}
										onClick={() => setView(!view)}></i>
								</div>
							</Form.Label>
							{errPass ? (
								<span style={{ fontSize: '16px' }} className={ft.err__mes}>
									{Translate(lang, 'global.errPass')}
								</span>
							) : (
								''
							)}
							{errSimp ? <span className={ft.err__mes}>{Translate(lang, 'login.noPass')}</span> : null}
							{err ? <span className={ft.err__mes}>{Translate(lang, 'login.noLastPass')}</span> : null}
							<button className={`${ft.save__btn} d-flex align-items-center mt-2 float-right`} type='submit' disabled={load ? true : false}>
								{Translate(lang, 'global.save')} {load ? <Loading size='sm' cl='ml-1' /> : ''}
							</button>
						</Form>
					</div>
				</Container>
			</div>
		</div>
	)
}
