import Container from '../../../components/container';
import { Tabs, Tab } from 'react-bootstrap';
import LeftSide from './leftSide';
import RightSide from './rightSide';
import Back from '../../../components/backButton';
import Translate from '../../../components/lang';
import { useEffect, useState } from 'react';
import { tasksApi } from '../../../services/tasksService';
import { orgApi } from '../../../services/orgListService';

export default function OrgAll(props) {
	const [leftData, setLeftData] = useState([]),
		[rightData, setRightData] = useState([]),
		[title, setTitle] = useState(''),
		[isHund, setIsHund] = useState(true),
		[loading, setLoading] = useState(true),
		[sumOfAll, setSumOfAll] = useState(0),
		{ lang, history, periods, selPer } = props,
		items = [
			{
				uz: 'Huquqiy savodxonlikni oshirishga doir aksiyalar, fleshmoblar va boshqa ommaviy tadbirlar tashkil etilishi',
				oz: 'Ҳуқуқий саводхонликни оширишга доир акциялар, флешмоблар ва бошқа оммавий тадбирлар ташкил этилиши',
				ru: 'Организация акций, флешмобов и других массовых мероприятий по повышению правовой грамотности',
			},
			{
				uz: 'Huquqiy savodxonlik darajasini oshirish bo‘yicha o‘qitish',
				oz: 'Ҳуқуқий саводхонлик даражасини ошириш бўйича ўқитиш',
				ru: 'Обучение юридической грамотности',
			},
			{
				uz: 'Mahallalarda o‘tkazilgan manzilli targ‘ibot tadbirlari',
				oz: 'Маҳаллаларда ўтказилган манзилли тарғибот тадбирлари',
				ru: 'Целенаправленная пропагандистская деятельность в махаллях',
			},
			{
				uz: 'Tadbirkorlik subyektlari o‘rtasida o‘tkazilgan manzilli targ‘ibot tadbirlari',
				oz: 'Тадбиркорлик субъектлари ўртасида ўтказилган манзилли тарғибот тадбирлари',
				ru: 'Целенаправленная информационно-пропагандистская деятельность среди субъектов хозяйственной деятельности',
			},
			{
				uz: 'Huquqiy qo‘llanmalarning yaratilishi va tarqatilishi',
				oz: 'Ҳуқуқий қўлланмаларнинг яратилиши ва тарқатилиши',
				ru: 'Создание и распространение юридической методички (справочника)',
			},
			{
				uz: 'Huquqni targ‘ib qilishga doir ko‘rgazmali targ‘ibot materiallari (flayer, buklet, infografika) tayyorlanishi',
				oz: 'Ҳуқуқни тарғиб қилишга доир кўргазмали тарғибот материаллари (флаер, буклет, инфографика) тайёрланиши',
				ru: 'Подготовка наглядных агитационных материалов (листовки, буклеты, инфографика) по пропаганде правовых актов',
			},
			{
				uz: 'Huquqiy savodxonlikni oshirishga doir multiplikatsion filmlar yaratilishi',
				oz: 'Ҳуқуқий саводхонликни оширишга доир мультипликацион фильмлар яратилиши',
				ru: 'Создание анимационных фильмов для повышения правовой грамотности',
			},
			{
				uz: 'Sohaga oid ijtimoiy huquqiy tusdagi videoroliklar, film va seriallar yaratilishi',
				oz: 'Соҳага оид ижтимоий ҳуқуқий тусдаги видеороликлар, филъм ва сериаллар яратилиши',
				ru: 'Создание социально-правовых видеороликов, фильмов и сериалов на местах',
			},
			{
				uz: 'Korrupsiyaga qarshi kurashishni targ‘ib qilishga doir videoroliklar yaratilishi',
				oz: 'Коррупцияга қарши курашишни тарғиб қилишга доир видеороликликлар яратилиши',
				ru: 'Создание видеороликов для пропаганды борьбы против коррупции',
			},
			{
				uz: 'Davlat organlarining ijtimoiy tarmoqlardagi sahifalari va kanallari foydalanuvchilari',
				oz: 'Давлат органларининг ижтимоий тармоқлардаги саҳифалари ва каналлари фойдаланувчилари',
				ru: 'Пользователи социальных сетей и каналов государственных органов',
			},
			{
				uz: 'Huquqiy targ‘ibotni amalga oshirishda yangi targ‘ibot turlaridan foydalanganlik darajasi',
				oz: 'Ҳуқуқий тарғиботни амалга оширишда янги тарғибот турларидан фойдаланганлик даражаси',
				ru: 'Уровень использования новых видов осведомления при осуществлении правовой пропаганды',
			},
			{
				uz: 'Ommaviy axborot vositalari bilan amalga oshirilgan brifinglar',
				oz: 'Оммавий ахборот воситалари билан амалга оширилган брифинглар',
				ru: 'Средства провдения брифингов для СМИ',
			},
			{
				uz: "Boshqa davlat organlari, ta'lim muassasalari, sudlar, huquqni muhofaza qiluvchi organlar hamkorligidagi tadbirlar",
				oz: 'Бошқа давлат органлари, таълим муассасалари, судлар, ҳуқуқни муҳофаза қилувчи органлар ҳамкорлигидаги тадбирлар',
				ru: 'Сотрудничество с другими государственными органами, образовательными учреждениями, судами, правоохранительными органами',
			},
			{
				uz: '',
				oz: '',
				ru: '',
			},
		],
		isHundred = [6, 9, 10, 10, 8, 5, 9, 10, 10, 5, 6, 6, 6],
		isSeventy = [4, 6, 7, 7, 5, 4, 7, 7, 7, 4, 4, 4, 4];

	useEffect(() => {
		tasksApi
			.checkRight(
				{ limit: 1000, page: 1 },
				{
					organizations: [history.location.search.slice(4)],
					start: periods[selPer]?.start,
					end: periods[selPer]?.end,
					period: periods[selPer]?.id,
				}
			)
			.then((res) => {
				if (res.data.success) {
					const DATA = { DATA: res.data.data };
					orgApi
						.leftSide({
							id: history.location.search.slice(4),
							start: periods[selPer]?.start,
							end: periods[selPer]?.end,
							period: periods[selPer]?.id,
						})
						.then((res) => {
							if (res.data.success) {
								setTitle(res.data?.name);
								const LEFTDATA = res.data.data;
								DATA.result = LEFTDATA.find((item) => item.code === 14);
								console.log(LEFTDATA, 'data', DATA);
								let temp = 0;
								LEFTDATA.forEach((l) => {
									if (l?.score >= 0 && l.code !== 14) temp += l.score;
								});
								setSumOfAll(Math.round(temp * 10) / 10);

								if (DATA.DATA.length) {
									setIsHund(false);
									setLeftData(() => {
										const temp = [];
										items.forEach((item, i) => {
											let d = LEFTDATA.filter((d) => +d.code === i + 1);
											temp.push({
												title: { uz: item.uz, ru: item.ru, oz: item.oz },
												maxNum: isSeventy[i],
												expert: d[0]?.expert ? d[0].expert : '',
												score: `${d[0]?.score}`.length ? d[0]?.score : -1,
												date: d[0]?.updatedAt ? d[0].updatedAt : '',
												comment: d[0]?.comment ? d[0].comment : '',
											});
										});
										return temp;
									});
								} else {
									setLeftData(() => {
										const temp = [];
										items.forEach((item, i) => {
											let d = LEFTDATA.filter((d) => +d.code === i + 1);
											temp.push({
												title: { uz: item.uz, ru: item.ru, oz: item.oz },
												maxNum: isHundred[i],
												expert: d[0]?.expert ? d[0].expert : '',
												score: d[0]?.score || d[0]?.score > -1 ? d[0].score : -1,
												date: d[0]?.updatedAt ? d[0].updatedAt : '',
											});
										});
										return temp;
									});
								}
								setRightData(DATA);
								setLoading(false);
							}
						});
				}
			});
	}, []);

	return (
		<div>
			<div className='d-flex align-items-center mb-3'>
				<Back {...props} />
				<h6 className='m-0' style={{ color: '#07A287', fontWeight: 500 }}>
					{title?.name?.uz ? title.name[lang] : ''}
				</h6>
			</div>
			<div>
				<Tabs defaultActiveKey='first' className='orgAll' id='uncontrolled-tab-example'>
					<Tab eventKey='first' title={Translate(lang, 'orgAll.leftSideTitle')}>
						<Container cl='rounded-0'>
							<LeftSide
								{...props}
								loading={loading}
								data={leftData}
								isHund={isHund}
								sumOfAll={sumOfAll}
							/>
						</Container>
					</Tab>

					<Tab eventKey='sec' title={Translate(lang, 'orgAll.rightSideTitle')}>
						<RightSide
							{...props}
							loading={loading}
							leftData={leftData}
							data={rightData.DATA}
							result={rightData.result}
							setData={setRightData}
						/>
					</Tab>
				</Tabs>
			</div>
		</div>
	);
}
