import CustomModal from '../../../../components/customModal'
import ft from '../../../../components/mainStyles.module.scss'
import Translate from '../../../../components/lang'
import ExportPdf from '../../../../components/exportPdf'
import { useEffect, useState } from 'react'
import Loading from '../../../../components/loading'
import { BeautyDate, GetStat } from '../../../../utils'
import Circle from '../../../../components/circle'
import { orgApi } from '../../../../services/orgListService'

export default function HomeStatModal(props) {
	const [data, setData] = useState([]),
		[load, setLoad] = useState(true),
		{ filter, lang, periods, show, selPer } = props

	useEffect(() => {
		setLoad(true)
		orgApi
			.getStat({
				page: 1,
				limit: 100000,
				start: periods[selPer]?.start,
				end: periods[selPer]?.end,
				period: periods[selPer]?.id,
				...filter,
			})
			.then((res) => {
				if (res.data.success) {
					setData(GetStat(res.data.data))
					setLoad(false)
				}
			})
	}, [show])

	return (
		<CustomModal {...props} size='lg'>
			<div className={`${ft.content__head} justify-content-between`}>
				<div className={`${ft.btns} w-100 justify-content-end`}>
					<ExportPdf {...props} title={Translate(lang, 'orgList.title')} id='taskCheckListModal' />
				</div>
			</div>
			<div id='taskCheckListModal'>
				{!load ? (
					<div className={ft.table}>
						<table id='table' className={ft.table__main}>
							<thead>
								<tr>
									<td>#</td>
									<td>{Translate(lang, 'global.orgName')}</td>
									<td className='text-center'>{Translate(lang, 'home.uncompleted')}</td>
									<td className='text-center'>{Translate(lang, 'homeAll.fileSent')}</td>
									<td className='text-center'>{Translate(lang, 'homeAll.received')}</td>
									<td className='text-center'>{Translate(lang, 'homeAll.notReceived')}</td>
									<td className='text-center'>{Translate(lang, 'home.allTasks')}</td>
								</tr>
							</thead>
							<tbody>
								{data.map((d, index) => (
									<tr>
										<td>{index + 1}</td>
										<td>{d?.name[lang]}</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat0 ? d?.stat0 : 0} col='255, 0, 0' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat1 ? d?.stat1 : 0} col='46, 143, 233' />
											</div>
										</td>

										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat2 ? d?.stat2 : 0} col='7, 162, 136' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat3 ? d?.stat3 : 0} col='239, 93, 168' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d.sum} col='51, 51, 51' />
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className='d-flex justify-content-center'>
						<Loading size='md' cl='text-center' />
					</div>
				)}
			</div>
		</CustomModal>
	)
}
