import Container from '../../components/container';
import st from '../../components/input/input.module.scss';
import ft from '../../components/mainStyles.module.scss';
import React, { useEffect, useState } from 'react';
import Loading from '../../components/loading';
import Del from '../../components/del';
import Translate from '../../components/lang';
import { edit, del, block, exp } from '../../components/icons';
import { BeautyFullTime } from '../../utils';
import Pagin from '../../components/pagin';
import SessionsListModal from './logsListModal';
import Filter from '../../components/filter';
import CustomSelect from '../../components/customSelect';
import { Form } from 'react-bootstrap';
import LogsListModal from './logsListModal';
import { logsApi } from '../../services/logsService';

export default function Logs(props) {
	const [data, setData] = useState([]),
		[options, setOptions] = useState([]),
		[selected, setSelected] = useState([]),
		[showDel, setShowDel] = useState(false),
		[showListModal, setShowListModal] = useState(false),
		[showFilter, setShowFilter] = useState(false),
		[filter, setFilter] = useState({}),
		[loading, setLoading] = useState(),
		[load, setLoad] = useState(false),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[id, setId] = useState(null),
		{ lang, user } = props;

	useEffect(() => {
		setLoading(true);
		logsApi.all(page).then((res) => {
			setData(res.data.data);
			setPageNum(Math.ceil(res.data.count / page.limit));
			setPc((page.page - 1) * page.limit);
			setLoading(false);
		});
	}, [page]);

	const handleSearch = (e) => {
		e.preventDefault();
		setPage({ ...page, page: 1, search: e.target[0].value });
	};

	const handleDelete = () => {
		setLoad(true);
		logsApi.delete().then((res) => {
			if (res.data.success) {
				setLoad(false);
				setShowDel(false);
				logsApi.all({ limit: 2, page: 1 }).then((res) => {
					if (res.data.success) {
						setPageNum(Math.ceil(res.data.count / page.limit));
						setPc(0);
						setData(res.data.data);
						setLoading(false);
					}
				});
			}
		});
	};

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'navbar.logs')}</h5>
					<div className={ft.btns}>
						<button className={ft.export__btn} onClick={() => setShowListModal(true)}>
							<span className='mr-1'>{Translate(lang, 'global.export')}</span> {exp}
						</button>
						<form className='m-0 ml-1' onSubmit={handleSearch}>
							<div className={`position-relative ${st.inputContainer}`}>
								<input
									type='text'
									placeholder={Translate(lang, 'orgList.search')}
									className={st.input}
								/>
								<button type='submit' className={st.icon}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
											stroke='#232323'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M20.9999 21L16.6499 16.65'
											stroke='#232323'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</button>
							</div>
						</form>
						{/* <button className={`${ft.primary__btn} ml-2`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button> */}
						<button className={ft.del__btn} style={{ color: '#FF5252' }} onClick={() => setShowDel(true)}>
							{del}
							{Translate(lang, 'delete.del')}
						</button>
					</div>
				</div>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead className='text-center'>
							<tr>
								<td>#</td>
								<td className='text-left'>{Translate(lang, 'global.login')}</td>
								<td className='text-center'>{Translate(lang, 'logs.section')}</td>
								<td className='text-center'>{Translate(lang, 'logs.name')}</td>
								<td className='text-center'>{Translate(lang, 'table.action')}</td>
								<td className='text-center'>{Translate(lang, 'logs.time')}</td>
							</tr>
						</thead>
						{!loading ? (
							<tbody className='text-center'>
								{data.map((d, i) => (
									<tr>
										<td>{pc + i + 1}</td>
										<td className='text-left'>{d.user}</td>
										<td>{d.product}</td>
										<td>{d.category}</td>
										<td>
											{d.action == '1' ? (
												<span className='text-success'>{Translate(lang, 'logs.added')}</span>
											) : d.action == '2' ? (
												<span className='text-warning'>{Translate(lang, 'logs.edited')}</span>
											) : (
												<span className='text-danger'>{Translate(lang, 'logs.del')}</span>
											)}
										</td>
										<td>{BeautyFullTime(d.createdAt)}</td>
									</tr>
								))}
							</tbody>
						) : (
							<Loading big={true} size='md' cl='mt-3' />
						)}
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			<Del {...props} id={id} load={load} show={showDel} setShow={setShowDel} handleDelete={handleDelete} />

			{!!showListModal && <LogsListModal {...props} show={showListModal} setShow={setShowListModal} />}
		</div>
	);
}
