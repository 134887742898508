import React, { useEffect, useState } from 'react'
import Container from '../../../components/container'
import ft from '../../../components/mainStyles.module.scss'
import Filter from '../../../components/filter'
import Loading from '../../../components/loading'
import CustomSelect from '../../../components/customSelect'
import Top from '../../../components/top'
import { Form } from 'react-bootstrap'
import Back from '../../../components/backButton'
import Translate from '../../../components/lang'
import { orgApi } from '../../../services/orgListService'
import Pagin from '../../../components/pagin'
import Circle from '../../../components/circle'
import ExportPdf from '../../../components/exportPdf'
import { GetStat } from '../../../utils'
import Limit from '../../../components/limit'
import { exp } from '../../../components/icons'
import HomeStatModal from './homeStatModal'

export default function HomeStat(props) {
	const [data, setData] = useState([]),
		[options, setOptions] = useState([]),
		[selected, setSelected] = useState([]),
		[showFilter, setShowFilter] = useState(false),
		[showHomeStatModal, setShowHomeStatModal] = useState(false),
		[filter, setFilter] = useState({}),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[id, setId] = useState(false),
		[load, setLoad] = useState(false),
		[loading, setLoading] = useState(true),
		{ lang, user, periods, selPer } = props

	useEffect(() => {
		orgApi.getAll().then((res) => {
			if (res.data.success) {
				const DATA = []
				res.data.data.map((d) => {
					DATA.push({
						value: d._id,
						label: d.name.uz,
					})
				})
				setOptions(DATA)
			}
		})
	}, [])

	useEffect(() => {
		setLoading(true)
		setFilter({
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?.id,
		})
		orgApi
			.getStat({
				...page,
				start: periods[selPer]?.start,
				end: periods[selPer]?.end,
				period: periods[selPer]?.id,
				...filter,
			})
			.then((res) => {
				if (res.data.success) {
					setPageNum(Math.ceil(res.data.num))
					setPc((page.page - 1) * page.limit)
					setData(GetStat(res.data.data))
					setLoading(false)
				}
			})
	}, [page])

	function handleChange(selected) {
		setSelected(selected)
	}

	const handleFilter = (e) => {
		e.preventDefault()
		setPage({ ...page, page: 1 })
		const selId = []
		selected.map((s) => {
			selId.push(s.value)
		})
		const data = {
			start: periods[e.target[2].value].start,
			end: periods[e.target[2].value].end,
			period: periods[selPer]?.id,
		}
		if (selId.length) data.organizations = selId
		if (e.target[1].value !== 'all') data.type = e.target[1].value
		setFilter(data)
		setShowFilter(false)
		setLoading(true)
		orgApi.getStat({ limit: page.limit, page: 1, ...data }).then((res) => {
			if (res.data.success) {
				setPageNum(Math.ceil(res.data.num))
				setPc(0)
				setData(GetStat(res.data.data))
				setLoading(false)
			}
		})
	}

	return (
		<div>
			<div className='d-flex align-items-center mb-3'>
				<Back {...props} />
				<h5 className='m-0' style={{ color: '#07A287', fontWeight: 500 }}>
					{Translate(lang, 'home.lowRankedOrgs')}
				</h5>
			</div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'homeAll.tableTitle')}</h5>
					<div className={ft.btns}>
						<button className={ft.export__btn} onClick={() => setShowHomeStatModal(true)}>
							<span className='mr-1'>{Translate(lang, 'global.export')}</span> {exp}
						</button>
						<Limit {...props} setPage={setPage} />
						<button className={`${ft.primary__btn} ml-2`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button>
					</div>
				</div>

				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'global.orgName')}</td>
								<td className='text-center'>{Translate(lang, 'home.uncompleted')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.fileSent')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.received')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.notReceived')}</td>
								<td className='text-center'>{Translate(lang, 'home.allTasks')}</td>
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, index) => (
									<tr>
										<td>{pc + index + 1}</td>
										<td>{d?.name[lang]}</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat0 ? d?.stat0 : 0} col='255, 0, 0' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat1 ? d?.stat1 : 0} col='46, 143, 233' />
											</div>
										</td>

										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat2 ? d?.stat2 : 0} col='7, 162, 136' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat3 ? d?.stat3 : 0} col='239, 93, 168' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d.sum} col='51, 51, 51' />
											</div>
										</td>
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			{!!showHomeStatModal && <HomeStatModal {...props} show={showHomeStatModal} setShow={setShowHomeStatModal} filter={filter} />}

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'orgList.orgName')}</div>
						<CustomSelect lang={lang} selected={selected} handleChange={handleChange} options={options} />
					</Form.Label>
					<select className='my-2'>
						<option value='all'>{Translate(lang, 'global.all')}</option>
						<option value='0'>{Translate(lang, 'orgList.orgType0')}</option>
						<option value='1'> {Translate(lang, 'orgList.orgType1')}</option>
					</select>
					<select className='my-2'>
						{periods.map((p, i) => (
							<option value={i}>{`${p.year}-${Translate(lang, 'time.year')} ${p.half}-${Translate(lang, 'time.half')}`}</option>
						))}
					</select>
					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'orgList.search')}
					</button>
				</Form>
			</Filter>
		</div>
	)
}
