import CustomModal from '../../../components/customModal'
import ft from '../../../components/mainStyles.module.scss'
import { Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Loading from '../../../components/loading'
import UploadFiles from '../../../components/uploadFiles'
import { infosApi } from '../../../services/infosService'
import Translate from '../../../components/lang'

export default function AddInfosModal(props) {
	const [newFiles, setNewFiles] = useState([]),
		[uploadedFiles, setUploadedFiles] = useState([]),
		[prots, setProts] = useState([]),
		[load, setLoad] = useState(false),
		[errFiles, setErrFiles] = useState(false),
		{ lang, setLoading, setData, show, setShow, setPageNum, setPc } = props

	useEffect(() => {
		setProts([])
		setNewFiles([])
		setUploadedFiles([])
	}, [show])

	const handleSubmit = (e) => {
		e.preventDefault()
		if (uploadedFiles.length || !!e.target[0].value) {
			setErrFiles(false)

			const file = []
			uploadedFiles.map((f) => {
				file.push({ file: f._id, path: f.path, size: f.size })
			})
			const data = {
				link: e.target[0].value,
				files: file,
			}
			setLoad(true)
			infosApi.add(data).then((res) => {
				setLoad(false)
				setLoading(true)
				infosApi.all({ limit: 10, page: 1 }).then((res) => {
					setPageNum(Math.ceil(res.data.num))
					setPc(0)
					setData(res.data.data)
					setLoading(false)
				})
				setShow(false)
			})
		} else setErrFiles(true)
	}
	return (
		<CustomModal {...props} modalTitle={Translate(lang, 'info.addInfo')} size='lg'>
			<Form className={`${ft.form} row px-2`} onSubmit={handleSubmit}>
				<div className='col-md-8'>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'info.videoLink')}</div>
						<Form.Control type='url' />
					</Form.Label>
				</div>
				<div className='col-md-4'>
					<UploadFiles {...props} newFiles={newFiles} setNewFiles={setNewFiles} setErrFiles={setErrFiles} setUploadedFiles={setUploadedFiles} load={load} setLoad={setLoad} prots={prots} setProts={setProts} />
					{errFiles && <span className={ft.err__mes}>{Translate(lang, 'global.notUploaded')}</span>}
				</div>
				<div className={ft.modalBtns}>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setShow(false)
							setLoad(false)
						}}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load ? true : false}>
						{Translate(lang, 'global.save')} {load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
