import CustomModal from '../../../components/customModal'
import ft from '../../../components/mainStyles.module.scss'
import st from './orgStat.module.scss'
import Translate from '../../../components/lang'
import { PieChart, Pie, Cell } from 'recharts'
import { orgApi } from '../../../services/orgListService'
import React, { useEffect, useState } from 'react'
import Loading from '../../../components/loading'
import { homeApi } from '../../../services/homeService'
import ExportPdf from '../../../components/exportPdf'
import { SortReyt } from '../../../utils'

export default function OrgStat(props) {
	const [data, setData] = useState([]),
		[dataItem, setDataItem] = useState(),
		[load, setLoad] = useState(true),
		[prots, setProts] = useState(),
		[reyt, setReyt] = useState({}),
		COLORS = ['rgba(239, 93, 168, 1)', 'rgba(239, 93, 168, 0.1)'],
		RADIAN = Math.PI / 180,
		{ statTitle, lang, id, periods, selPer, selPerReyt } = props

	useEffect(() => {
		orgApi
			.statModal({
				id: id,
				start: periods[selPer]?.start,
				end: periods[selPer]?.end,
				period: periods[selPer]?.id,
			})
			.then((res) => {
				if (res.data.success) {
					const DATA = res.data.data
					const temp = {}
					temp.done = DATA.count - DATA.count1
					temp.notDone = DATA.count1
					temp.bandSuccess = 0
					temp.code = []
					DATA.band.forEach((d) => {
						if ((d.files.length || d?.code > 0) && !temp?.code?.filter((t) => t === d?.code).length) {
							temp.bandSuccess += 1
							temp.code.push(d.code)
						}
					})
					temp.bandNotSuccess = 13 - temp.bandSuccess

					setProts(() => {
						let temp = []
						const stat0 = temp.done
						const stat1 = temp.notDone
						const sum = stat0 + stat1
						if (stat0) temp.push((stat0 * 100) / sum)
						else temp.push(0)
						if (stat1) temp.push((stat1 * 100) / sum)
						else temp.push(0)
						return temp
					})
					setDataItem([{ value: temp.done }, { value: temp.notDone }])

					setData(temp)

					homeApi
						.all({
							limit: 1000,
							page: 1,
							start: periods[selPer]?.start,
							end: periods[selPer]?.end,
							period: periods[selPer]?.id,
						})
						.then((res) => {
							if (res.data.success) {
								const DATA = SortReyt(res.data.data, res.data.oldData)
								const temp = {}
								const total = DATA.filter((d, i) => d._id === id)[0]
								temp.place = total.place
								temp.total = total.res?.total ? total.res.total : 0
								temp.notDone =
									(+total.stat.filter((s) => s._id === 0)[0]?.count ? +total.stat.filter((s) => s._id === 0)[0]?.count : 0) +
									(+total.stat.filter((s) => s._id === 1)[0]?.count ? +total.stat.filter((s) => s._id === 1)[0]?.count : 0) +
									(+total.stat.filter((s) => s._id === 3)[0]?.count ? +total.stat.filter((s) => s._id === 3)[0]?.count : 0)
								temp.totalTasks =
									(!!total.stat.filter((s) => s._id === 0)[0] ? total.stat.filter((s) => s._id === 0)[0].count : 0) +
									(!!total.stat.filter((s) => s._id === 1)[0] ? total.stat.filter((s) => s._id === 1)[0].count : 0) +
									(!!total.stat.filter((s) => s._id === 2)[0] ? total.stat.filter((s) => s._id === 2)[0].count : 0) +
									(!!total.stat.filter((s) => s._id === 3)[0] ? total.stat.filter((s) => s._id === 3)[0].count : 0)
								setReyt(temp)
								setLoad(false)
							}
						})
				}
			})
	}, [id])

	// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
	// 	const radius = innerRadius + (outerRadius - innerRadius) * 0.4
	// 	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	// 	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	// 	return (
	// 		<text x={x} y={y} fill='dark' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
	// 			{`${percent * 100 === prots[0] ? dataItem[0].value : dataItem[1].value} ta`}
	// 		</text>
	// 	)
	// }

	return (
		<CustomModal modalId='orgStatModal' {...props} size='lg' modalTitle={statTitle}>
			<div className={`${ft.content__head} justify-content-end`}>
				<div className={ft.btns}>
					<ExportPdf {...props} title={props.modalTitle} id='orgStatModal' />
				</div>
			</div>
			{!load ? (
				<>
					<div id='modal' className={st.items}>
						<div className={st.item}>
							<p className={st.item__title}>{Translate(lang, 'taskCheck.taskNum')}</p>
							{!(dataItem[0].value === 0 && dataItem[1].value === 0) ? (
								<PieChart width={200} height={200}>
									<Pie
										data={dataItem}
										cx='50%'
										cy='50%'
										labelLine={false}
										// label={renderCustomizedLabel}
										outerRadius={70}
										fill='rgba(239, 93, 168, 0.1)'
										dataKey='value'>
										{dataItem.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
										))}
									</Pie>
								</PieChart>
							) : (
								<p className='my-5 text-center'>{Translate(lang, 'taskCheck.noTasks')}</p>
							)}
							<div className={st.chart}>
								<div className={st.chart__info}>
									<div className='d-flex align-items-center'>
										<div className={`${st.circle} ${st.circle__done}`}></div>
										<p>{Translate(lang, 'home.completed')}</p>
									</div>
									<p>{dataItem[0].value}</p>
								</div>
								<div className={st.chart__info}>
									<div className='d-flex align-items-center'>
										<div className={`${st.circle} ${st.circle__cancel}`}></div>
										<p>{Translate(lang, 'home.uncompleted')}</p>
									</div>
									<p>{dataItem[1].value}</p>
								</div>
							</div>
						</div>
						<div className={st.item}>
							<p className={st.item__title}>{Translate(lang, 'homeAll.tableTitle')}</p>
							<div className='mt-3'>
								<h4 className='mb-2' style={{ color: '#5D5FEF' }}>
									#{reyt.place} - {Translate(lang, 'table.place')}
								</h4>
								<h6 className='d-flex align-items-center justify-content-between my-3'>
									<span>{Translate(lang, 'table.allBall')}</span> <span>{Math.round(reyt.total * 10) / 10}</span>
								</h6>
								<h6 className='d-flex align-items-center justify-content-between my-3'>
									<span>{Translate(lang, 'table.notDoneTasks')}</span> <span>{reyt.notDone}</span>
								</h6>
								<h6 className='d-flex align-items-center justify-content-between my-3'>
									<span>{Translate(lang, 'home.allTasks')}</span> <span>{reyt.totalTasks}</span>
								</h6>
							</div>
						</div>
						<div className={st.item}>
							<p className={st.item__title}>{Translate(lang, 'taskCheck.bands')}</p>
							<div className='d-flex flex-column justify-content-between'>
								<div className={`row ${st.task} my-3`}>
									<div className={`col-8 ${st.task__item} ${ft.success__btn}`}>{Translate(lang, 'taskCheck.completed')}</div>
									<div className={`col-3 ${st.task__num} ${ft.success__btn}`}>{data.bandSuccess}</div>
								</div>
								<div className={`row ${st.task} my-3`}>
									<div className={`col-8 ${st.task__item} ${ft.notSuccess__btn}`}>{Translate(lang, 'taskCheck.uncompleted')}</div>
									<div className={`col-3 ${st.task__num} ${ft.notSuccess__btn}`}>{data.bandNotSuccess}</div>
								</div>
								<div className={`row ${st.task} my-3`}>
									<div className={`col-8 ${st.task__item} ${ft.info__btn}`} style={{ fontSize: '16px' }}>
										{Translate(lang, 'orgMark.all')}
									</div>
									<div className={`col-3 ${st.task__num} ${ft.info__btn}`} style={{ fontSize: '16px' }}>
										13
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className='d-flex justify-content-center'>
					<Loading size='md' cl='text-center' />
				</div>
			)}
		</CustomModal>
	)
}
