import axiosInstance from './api';

const api = '/organizations';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const orgApi = {
	all: (data, token) => {
		return axiosInstance.post(`${api}/all`, data, {
			headers: {
				Authorization: `Bearer ${token ? token : sessionStorage.getItem('token')}`,
			},
		});
	},
	getStat: (data) => axiosInstance.post(`${api}/get-stat`, data, headers),
	statModal: (data) => axiosInstance.post('/assignments/modal-two/', data, headers),
	getAll: () => {
		return axiosInstance.get(`${api}/get-all`, headers, headers);
	},
	leftSide: (data) => {
		return axiosInstance.post(`/advocacy/results`, data, headers);
	},
	markAll: (data) => {
		return axiosInstance.post(`/advocacy/exp-all`, data, headers);
	},
	markAllEdit: (id, data) => {
		return axiosInstance.put(`/advocacy/update/${id}`, data, headers);
	},
	mark: (data) => {
		return axiosInstance.post(`/advocacy/exp-update`, data, headers);
	},
	markEdit: (data) => {
		return axiosInstance.post(`/advocacy/exp-update-score`, data, headers);
	},
	update: (id, data) => {
		return axiosInstance.put(`/organizations/update/${id}`, data, headers);
	},
	edit: (id, data) => axiosInstance.put(`/auth/admin/reset-org-password/${id}`, data, headers),
	delete: (id) => axiosInstance.delete(`${api}/delete/${id}`, headers, headers),
}
