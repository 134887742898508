import { useState } from 'react';

import { chevron } from '../icons';

import Translate from '../lang';

import st from './limit.module.scss';

export default function Limit({ lang, setPage }) {
	const [isOpen, setIsOpen] = useState(false),
		[selected, setSelected] = useState(0),
		options = [
			{
				value: 10,
				label: `${
					lang !== 'ru' ? `10 ${Translate(lang, 'global.seeOrgs')}` : `${Translate(lang, 'global.seeOrgs').toString().split(' ').join(' 10 ')}`
				}`,
			},
			{
				value: 25,
				label: `${
					lang !== 'ru' ? `25 ${Translate(lang, 'global.seeOrgs')}` : `${Translate(lang, 'global.seeOrgs').toString().split(' ').join(' 20 ')}`
				}`,
			},
			{
				value: 50,
				label: `${
					lang !== 'ru' ? `50 ${Translate(lang, 'global.seeOrgs')}` : `${Translate(lang, 'global.seeOrgs').toString().split(' ').join(' 30 ')}`
				}`,
			},
			{
				value: 100,
				label: `${
					lang !== 'ru' ? `100 ${Translate(lang, 'global.seeOrgs')}` : `${Translate(lang, 'global.seeOrgs').toString().split(' ').join(' 40 ')}`
				}`,
			},
			{
				value: 10000,
				label: Translate(lang, 'global.all'),
			},
		]

	return (
		<div className={st.limit}>
			<div className={st.select}>
				<div className={`${st.selected} ${isOpen ? st.selected__active : ''}`} onClick={() => setIsOpen(!isOpen)}>
					<span className='ml-2' style={isOpen ? { fontWeight: 600 } : {}}>
						{options[selected].label}
					</span>
					{chevron(null, st.chevron, st.chevron__active, isOpen, '000')}
				</div>

				<div className={`${st.select__list} ${isOpen ? st.select__list__active : ''}`}>
					{options.map((op, i) => {
						return (
							<div
								className={st.select__list__item}
								onClick={() => {
									setSelected(i)
									setPage({ limit: op.value, page: 1 })
									setIsOpen(false)
								}}
								style={{ borderBottom: '1px solid #DDEBFF' }}
								key={i}
							>
								<span className='ml-2'>{op.label}</span>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
