import axiosInstance from '../api';

const api = '/docs';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const docApi = {
	getList: (data) => axiosInstance.post(`${api}/list`, data, headers),
	getFullById: (id) => axiosInstance.get(`${api}/get-full/${id}`, headers),
	getById: (id) => axiosInstance.get(`${api}/${id}`, headers),
	create: (data) => axiosInstance.post(api, data, headers),
	update: (id, data) => axiosInstance.put(`${api}/${id}`, data, headers),
	delete: (id) => axiosInstance.delete(`${api}/${id}`, headers),
	neighborhoodMonitoring: (docId, data) => axiosInstance.post(`${api}/neighborhood/monitoring/${docId}`, data, headers),
	sendToNeighborhood: (docId) => axiosInstance.post(`${api}/send-to-neighborhood/${docId}`, {}, headers)
}
