import CustomModal from '../../../components/customModal'
import ft from '../../../components/mainStyles.module.scss'
import { Form } from 'react-bootstrap'
import { useState } from 'react'
import Loading from '../../../components/loading'
import Translate from '../../../components/lang'
import { markTimesApi } from '../../../services/markTimesService'

export default function MarkTimesModal(props) {
	const [load, setLoad] = useState(false),
		{ setShow, lang, setLoading, setData, setMarkTime } = props
	const handleSubmit = (e) => {
		e.preventDefault()
		setLoad(true)
		markTimesApi.markAdd({ text: e.target[0].value, startTime: e.target[1].value, endTime: e.target[2].value }).then((res) => {
			if (res.data.success) {
				setLoad(false)
				setLoading(true)
				markTimesApi.all().then((res) => {
					setMarkTime(res.data.data.filter((d) => d.status === 1)[0])
					setData(res.data.data)
					setLoading(false)
				})
				setShow(false)
			}
		})
	}
	return (
		<CustomModal {...props} modalTitle={Translate(lang, 'navbar.tdate')} size='md'>
			<Form className={ft.form} onSubmit={handleSubmit}>
				<Form.Label>
					<div className={ft.lab}>{Translate(lang, 'markTimes.startDate')}</div>
					<Form.Control as='textarea' required />
				</Form.Label>
				<div className='d-flex'>
					<Form.Label className='px-2'>
						<div className={ft.lab}>{Translate(lang, 'markTimes.initialDate')}</div>
						<Form.Control type='date' required />
					</Form.Label>
					<Form.Label className='px-2'>
						<div className={ft.lab}>{Translate(lang, 'markTimes.endDate')}</div>
						<Form.Control type='date' required />
					</Form.Label>
				</div>
				<div className={ft.modalBtns}>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setShow(false)
							setLoad(false)
						}}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load ? true : false}>
						{Translate(lang, 'global.save')}
						{load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
