import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import { checkIcon } from '../../../components/icons';

import { orgApi } from '../../../services/orgListService';

import { GeneratePassword, ValidatePassword } from '../../../utils';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';

import st from './orgInfo.module.scss';
import ft from '../../../components/mainStyles.module.scss';
import config from "../../../config";

export default function OrgInfo(props) {
	const [load, setLoad] = useState(false),
		[loadType, setLoadType] = useState(false),
		[view, setView] = useState(false),
		[errPass, setErrPass] = useState(false),
		password = useRef(''),
		{ lang, types, setShow, id, user, orgInfo, setOrgInfo, setChange } = props;

	const updateSubmit = (e) => {
		e.preventDefault();

		setLoadType(true);

		const data = {
			name: {
				uz: e.target[0].value,
				ru: e.target[1].value
			},
			organizationType: e.target[2].value,
			status: e.target[3].value,
		}

		orgApi.update(id, data)
			.then(res => {
				setChange(prev => !prev);

				setOrgInfo({ ...orgInfo, ...res.data?.data });
			})
			.catch(err => {
				console.log(err.message);
			})
			.finally(() => {
				setLoadType(false);
			});
	}

	const changeSubmit = (e) => {
		e.preventDefault();

		if (ValidatePassword(e.target[1].value)) {
			setErrPass(false);
			setLoad(true);

			orgApi.edit(id, { login: e.target[0].value })
				.then(res => {
					if (res.data.success) {
						orgApi.edit(id, { password: e.target[1].value })
							.then((res) => {
								if (res.data.success) {
									setChange(prev => !prev);

									setOrgInfo({ ...orgInfo, login: e.target[0].value });

									e.target[1].value = '';
								}
							});
					}
				})
				.catch(err => {
					console.log(err.message);
				})
				.finally(() => {
					setLoad(false);
				});
		} else {
			setErrPass(true);
		}
	};

	return (
		<CustomModal {...props} size='lg' clBody='pt-0'>
			<div className='row'>
				<div className='col-3'>
					{orgInfo?.image
						? <img src={`${config.api.baseUrl}${orgInfo?.image}`} style={{ width: 120 }} />
						: <div style={{ width: '200px', height: '200px', background: '#F4F4F4', borderRadius: '10px' }} />
					}
				</div>

				<div className='col-9'>
					{user?.role === 'admin'
						? <>
								<Form className={`mb-3 ${ft.form} ${st.form}`} onSubmit={updateSubmit}>
									<div className="row">
										<div className='col-6'>
											<Form.Label>
												<div className={ft.lab}>{Translate(lang, 'global.orgName')} (Uzb)</div>
												<Form.Control
													type='text'
													defaultValue={orgInfo?.name?.uz ? orgInfo.name.uz : '---'}
													required
												/>
											</Form.Label>
										</div>

										<div className='col-6'>
											<Form.Label>
												<div className={ft.lab}>{Translate(lang, 'global.orgName')} (Рус)</div>
												<Form.Control
													type='text'
													defaultValue={orgInfo?.name?.ru ? orgInfo.name.ru : '---'}
													required
												/>
											</Form.Label>
										</div>

										<div className='col-6'>
											<Form.Label>
												<div className={ft.lab}>{Translate(lang, 'orgList.orgType')}</div>
												{types.length && (
														<Form.Control
															as='select'
															defaultValue={orgInfo?.organizationType[0]?._id}
														>
															{types.map(type => (
																<option value={type._id} key={type._id}>{type.name[lang]}</option>
															))}
														</Form.Control>
												)}
											</Form.Label>
										</div>

										<div className='col-6'>
											<Form.Label className='m-0'>
												<div className={ft.lab}>{Translate(lang, 'global.status')}</div>
											</Form.Label>
											<Form.Control
												as='select'
												defaultValue={orgInfo?.status}
											>
												<option value={0}>{Translate(lang, 'global.active')}</option>
												<option value={1}>{Translate(lang, 'global.inactive')}</option>
											</Form.Control>
										</div>

										<div className='col-12'>
											<div className='d-flex justify-content-end'>
												<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={loadType}>
													{Translate(lang, 'global.save')}{' '}
													{loadType ? <Loading size='sm' cl='ml-1' /> : checkIcon}
												</button>
											</div>
										</div>
									</div>
								</Form>

								<Form className={`${ft.form} ${st.form}`} onSubmit={changeSubmit}>
									<div className="row">
										<div className='col-6'>
											<Form.Label>
												<div className={ft.lab}>{Translate(lang, 'global.login')}</div>
												<Form.Control
													type='text'
													defaultValue={orgInfo?.login}
													required
												/>
											</Form.Label>
										</div>

										<div className='col-6'>
											<Form.Label>
												<div className={`${ft.lab} d-flex justify-content-between`}>
													{Translate(lang, 'login.pass')}
													<span style={{ cursor: 'pointer', lineHeight: '24px' }} onClick={() => { password.current.value = GeneratePassword(); }}>
														{Translate(lang, 'global.genPass')}
													</span>
												</div>
												<div className='position-relative'>
													<Form.Control
														ref={password}
														minLength={8}
														type={view ? 'text' : 'password'}
														required
													/>
													<i
														className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
														style={{ top: '50%', right: 0, transform: 'translate(-10px, -8px)', cursor: 'pointer' }}
														onClick={() => setView(!view)}
													>
													</i>
												</div>

												{errPass
													? <span style={{ fontSize: '16px' }} className={ft.err__mes}>
																{Translate(lang, 'global.errPass')}
															</span>
													: ''
												}
											</Form.Label>
										</div>

										<div className='col-12'>
											<div className='d-flex justify-content-end'>
												<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load}>
													{Translate(lang, 'global.save')}{' '}
													{load ? <Loading size='sm' cl='ml-1' /> : checkIcon}
												</button>
											</div>
										</div>
									</div>
								</Form>
							</>
						: null
					}
				</div>

				<div className='col-md-6'>
					<div className={`${ft.table} ${st.table}`}>
						<table className={`${ft.table__main}`} style={{ fontSize: '13px' }}>
							<tbody style={{ fontSize: '13px' }}>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.departmentName')}</td>
									<td className={st.right}>{orgInfo?.divisionName?.uz ? orgInfo?.divisionName[lang] : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.departmentLeader')}</td>
									<td className={st.right}>{orgInfo?.divisionLeader ? orgInfo?.divisionLeader : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.leaderPhone')}</td>
									<td className={st.right}>{orgInfo?.leaderPhone ? `+${orgInfo?.leaderPhone}` : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.leaderMail')}</td>
									<td className={st.right}>{orgInfo?.leaderEmail?.length ? orgInfo?.leaderEmail : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.employeesNum')}</td>
									<td className={st.right}>{orgInfo?.employeesNumber ? orgInfo?.employeesNumber : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.responsiblePerson')}</td>
									<td className={st.right}>{orgInfo?.responsiblePerson ? orgInfo?.responsiblePerson : '---'}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className='col-md-6'>
					<div className={`${ft.table} ${st.table}`}>
						<table className={`${ft.table__main}`} style={{ fontSize: '13px' }}>
							<tbody style={{ fontSize: '13px' }}>
								<tr>
									<td className={st.left}>{Translate(lang, 'global.orgName')}</td>
									<td className={st.right}>{orgInfo?.name?.uz ? orgInfo.name[lang] : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.shortName')}</td>
									<td className={st.right}>{orgInfo?.shortName?.uz ? orgInfo?.shortName[lang] : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.leader')}</td>
									<td className={st.right}>{orgInfo?.leader ? orgInfo?.leader : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.legalAddress')}</td>
									<td className={st.right}>{orgInfo?.legalAddress ? orgInfo?.legalAddress : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'global.phoneNumber')}</td>
									<td className={st.right}>
										{orgInfo?.phoneNumber
											? `${orgInfo?.phoneNumber.toString().slice(0, 4)} ${orgInfo?.phoneNumber
													.toString()
													.slice(4, 6)} ${orgInfo?.phoneNumber
													.toString()
													.slice(6, 9)} ${orgInfo?.phoneNumber
													.toString()
													.slice(9, 11)} ${orgInfo?.phoneNumber.toString().slice(11, 13)}`
											: '---'}
									</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.fax')}</td>
									<td className={st.right}>
										{orgInfo?.fax
											? `${orgInfo?.fax.toString().slice(0, 4)} ${orgInfo?.phoneNumber
													.toString()
													.slice(4, 6)} ${orgInfo?.fax.toString().slice(6, 9)} ${orgInfo?.fax
													.toString()
													.slice(9, 11)} ${orgInfo?.fax.toString().slice(11, 13)}`
											: '---'}
									</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.postIndex')}</td>
									<td className={st.right}>{orgInfo?.postIndex ? orgInfo?.postIndex : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.email')}</td>
									<td className={st.right}>{orgInfo?.email ? orgInfo?.email : '---'}</td>
								</tr>
								<tr>
									<td className={st.left}>{Translate(lang, 'orgList.webSite')}</td>
									<td className={st.right}>{orgInfo?.webSite ? orgInfo?.webSite : '---'}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</CustomModal>
	);
}
