import Back from '../../../../components/backButton'
import Translate from '../../../../components/lang'
import ft from '../../../../components/mainStyles.module.scss'
import React, { useEffect, useState } from 'react'
import Container from '../../../../components/container'
import { Form } from 'react-bootstrap'
import Loading from '../../../../components/loading'
import { BeautyDate } from '../../../../utils'
import Filter from '../../../../components/filter'
import { tasksApi } from '../../../../services/tasksService'
import Pagin from '../../../../components/pagin'
import TaskStatOneModal from './taskStatOneModal'
import { exp } from '../../../../components/icons'
import config from "../../../../config";

export default function TaskStatOne(props) {
	const [data, setData] = useState([]),
		[showFilter, setShowFilter] = useState(false),
		[taskStatOneModal, setTaskStatOneModal] = useState(false),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[loading, setLoading] = useState(true),
		{ lang, filter, setFilter } = props

	useEffect(() => {
		setLoading(true)
		tasksApi
			.all(
				{
					...page,
					...filter,
				},
				{ organizations: [props.location.search.slice(4)] }
			)
			.then((res) => {
				if (res.data.success) {
					setPageNum(Math.ceil(res.data.num))
					setPc((page.page - 1) * page.limit)
					setData(res.data.data)
					setLoading(false)
				}
			})
	}, [page])

	const handleFilter = (e) => {
		e.preventDefault()
		setPage({ ...page, page: 1 })
		const data = {
			start: e.target[2].value,
			end: e.target[3].value,
		}

		if (e.target[1].value != 10) data.status = +e.target[1].value
		if (e.target[0].value) data.docName = e.target[0].value

		setShowFilter(false)
		setLoading(true)
		tasksApi.all({ limit: 10, page: 1 }, { ...data, ...filter, organizations: [props.location.search.slice(4)] }).then((res) => {
			if (res.data.success) {
				setFilter({ ...data, ...filter })
				setPageNum(Math.ceil(res.data.num))
				setPc(0)
				setLoading(false)
				setData(res.data.data)
			}
		})
	}

	return (
		<div>
			<div className='d-flex align-items-center mb-3'>
				<Back {...props} />
			</div>

			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'taskCheck.title')}</h5>
					<div className={ft.btns}>
						<button className={ft.export__btn} onClick={() => setTaskStatOneModal(true)}>
							<span className='mr-1'>{Translate(lang, 'global.export')}</span> {exp}
						</button>
						<button className={`${ft.primary__btn} ml-2`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button>
					</div>
				</div>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'taskSend.taskMeaning')}</td>
								<td>{Translate(lang, 'taskSend.deadline')}</td>
								<td>{Translate(lang, 'global.fileName')}</td>
								<td>{Translate(lang, 'table.file')}</td>
								<td>{Translate(lang, 'table.expert')}</td>
								<td>{Translate(lang, 'taskCheck.executionFile')}</td>
								<td>{Translate(lang, 'taskCheck.sentDate')}</td>
								<td>{Translate(lang, 'orgAll.status')}</td>
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, index) => (
									<tr>
										<td>{pc + index + 1}</td>
										<td>{d.description}</td>
										<td className='text-nowrap text-center'>{BeautyDate(d.deadline)}</td>
										<td>{d.docName}</td>
										<td className='text-nowrap'>
											{d?.files.map((i) => {
												return (
													<div>
														<a target='_blank' href={`${config.api.baseUrl}${i?.path}`}>
															{i?.path?.slice(15)}
														</a>
													</div>
												)
											})}
										</td>
										<td>{d.exp.fullName}</td>
										<td>{d.org.name[lang]}</td>
										<td className='text-center'>{d?.complete ? BeautyDate(d?.complete) : '---'}</td>
										<td className='text-nowrap text-center'>
											{d.status === 0 ? (
												<span className={ft.isNotDone}>{Translate(lang, 'home.uncompleted')}</span>
											) : d.status === 1 ? (
												<span className={ft.isWaiting}>Hujjat yuborilgan</span>
											) : d.status === 2 ? (
												<span className={ft.isDone}>Qabul qilingan</span>
											) : (
												<span className={ft.isNotDone}>Qabul qilinmagan</span>
											)}
										</td>
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			{!!taskStatOneModal && <TaskStatOneModal {...props} show={taskStatOneModal} setShow={setTaskStatOneModal} />}

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label className='my-2'>
						<Form.Control type='text' placeholder={Translate(lang, 'global.fileName')} />
					</Form.Label>

					<select className='my-2'>
						<option value={10}>{Translate(lang, 'global.all')}</option>
						<option value={1}>{Translate(lang, 'homeAll.fileSent')}</option>
						<option value={2}>{Translate(lang, 'homeAll.received')}</option>
						<option value={3}>{Translate(lang, 'homeAll.notReceived')}</option>
					</select>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.from')}</div>
						<Form.Control type='date' defaultValue={filter?.start} required />
					</Form.Label>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.to')}</div>
						<Form.Control type='date' defaultValue={filter?.end} required />
					</Form.Label>
					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'orgList.search')}
					</button>
				</Form>
			</Filter>
		</div>
	)
}
