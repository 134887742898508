import { useEffect, useState } from 'react';

import { checkIcon, closeIcon } from '../../../components/icons';

import { BeautyDate, BeautyTime } from '../../../utils';

import { orgApi } from '../../../services/orgListService';

import Translate from '../../../components/lang';
import Back from '../../../components/backButton';
import Loading from '../../../components/loading';
import Container from '../../../components/container';

import OrgMark10 from './orgMark10';
import OrgMarkModal from './orgMarkModal';

import st from './orgMark.module.scss';
import ft from '../../../components/mainStyles.module.scss';
import config from "../../../config";

const items = [
	{
		uz: 'Huquqiy savodxonlikni oshirishga doir aksiyalar, fleshmoblar va boshqa ommaviy tadbirlar tashkil etilishi',
		oz: 'Ҳуқуқий саводхонликни оширишга доир акциялар, флешмоблар ва бошқа оммавий тадбирлар ташкил этилиши',
		ru: 'Организация акций, флешмобов и других массовых мероприятий по повышению правовой грамотности',
	},
	{
		uz: 'Huquqiy savodxonlik darajasini oshirish bo‘yicha o‘qitish',
		oz: 'Ҳуқуқий саводхонлик даражасини ошириш бўйича ўқитиш',
		ru: 'Обучение юридической грамотности',
	},
	{
		uz: 'Mahallalarda o‘tkazilgan manzilli targ‘ibot tadbirlari',
		oz: 'Маҳаллаларда ўтказилган манзилли тарғибот тадбирлари',
		ru: 'Целенаправленная пропагандистская деятельность в махаллях',
	},
	{
		uz: 'Tadbirkorlik subyektlari o‘rtasida o‘tkazilgan manzilli targ‘ibot tadbirlari',
		oz: 'Тадбиркорлик субъектлари ўртасида ўтказилган манзилли тарғибот тадбирлари',
		ru: 'Целенаправленная информационно-пропагандистская деятельность среди субъектов хозяйственной деятельности',
	},
	{
		uz: 'Huquqiy qo‘llanmalarning yaratilishi va tarqatilishi',
		oz: 'Ҳуқуқий қўлланмаларнинг яратилиши ва тарқатилиши',
		ru: 'Создание и распространение юридической методички (справочника)',
	},
	{
		uz: 'Huquqni targ‘ib qilishga doir ko‘rgazmali targ‘ibot materiallari (flayer, buklet, infografika) tayyorlanishi',
		oz: 'Ҳуқуқни тарғиб қилишга доир кўргазмали тарғибот материаллари (флаер, буклет, инфографика) тайёрланиши',
		ru: 'Подготовка наглядных агитационных материалов (листовки, буклеты, инфографика) по пропаганде правовых актов',
	},
	{
		uz: 'Huquqiy savodxonlikni oshirishga doir multiplikatsion filmlar yaratilishi',
		oz: 'Ҳуқуқий саводхонликни оширишга доир мультипликацион фильмлар яратилиши',
		ru: 'Создание анимационных фильмов для повышения правовой грамотности',
	},
	{
		uz: 'Sohaga oid ijtimoiy huquqiy tusdagi videoroliklar, film va seriallar yaratilishi',
		oz: 'Соҳага оид ижтимоий ҳуқуқий тусдаги видеороликлар, филъм ва сериаллар яратилиши',
		ru: 'Создание социально-правовых видеороликов, фильмов и сериалов на местах',
	},
	{
		uz: 'Korrupsiyaga qarshi kurashishni targ‘ib qilishga doir videoroliklar yaratilishi',
		oz: 'Коррупцияга қарши курашишни тарғиб қилишга доир видеороликликлар яратилиши',
		ru: 'Создание видеороликов для пропаганды борьбы против коррупции',
	},
	{
		uz: 'Davlat organlarining ijtimoiy tarmoqlardagi sahifalari va kanallari foydalanuvchilari',
		oz: 'Давлат органларининг ижтимоий тармоқлардаги саҳифалари ва каналлари фойдаланувчилари',
		ru: 'Пользователи социальных сетей и каналов государственных органов',
	},
	{
		uz: 'Huquqiy targ‘ibotni amalga oshirishda yangi targ‘ibot turlaridan foydalanganlik darajasi',
		oz: 'Ҳуқуқий тарғиботни амалга оширишда янги тарғибот турларидан фойдаланганлик даражаси',
		ru: 'Уровень использования новых видов осведомления при осуществлении правовой пропаганды',
	},
	{
		uz: 'Ommaviy axborot vositalari bilan amalga oshirilgan brifinglar',
		oz: 'Оммавий ахборот воситалари билан амалга оширилган брифинглар',
		ru: 'Средства провдения брифингов для СМИ',
	},
	{
		uz: "Boshqa davlat organlari, ta'lim muassasalari, sudlar, huquqni muhofaza qiluvchi organlar hamkorligidagi tadbirlar",
		oz: 'Бошқа давлат органлари, таълим муассасалари, судлар, ҳуқуқни муҳофаза қилувчи органлар ҳамкорлигидаги тадбирлар',
		ru: 'Сотрудничество с другими государственными органами, образовательными учреждениями, судами, правоохранительными органами',
	},
	{
		uz: '',
		oz: '',
		ru: '',
	},
];

export default function OrgMark(props) {
	const [data, setData] = useState([]),
		[status, setStatus] = useState({}),
		[ball, setBall] = useState(0),
		[code, setCode] = useState(1),
		[defData, setDefData] = useState({}),
		[title, setTitle] = useState(''),
		[isEdited, setIsEdited] = useState(false),
		[showModal10, setShowModal10] = useState(false),
		[tensBall, setTensBall] = useState([]),
		[id, setId] = useState(0),
		[countOne, setCountOne] = useState(0),
		[countTwo, setCountTwo] = useState(0),
		[orgInfo, setOrgInfo] = useState({}),
		[loading, setLoading] = useState(true),
		[show, setShow] = useState(false),
		[checkAll, setCheckAll] = useState(false),
		{ lang, history, markTime, periods, selPer, user } = props;

	useEffect(() => {
		setCode(+history.location.search.slice(history.location.search.indexOf('&code=') + 6, history.location.search.indexOf('&is')));

		orgApi
			.markAll({
				id: history.location.search.slice(4, history.location.search.indexOf('&code')),
				code: +history.location.search.slice(history.location.search.indexOf('&code=') + 6, history.location.search.indexOf('&is')),
				start: periods[selPer]?.start,
				end: periods[selPer]?.end,
				period: periods[selPer]?.id,
			})
			.then(res => {
				if (res.data.success) {
					setTitle(res.data.name);
					setIsEdited(res.data.result?._id);

					const DATA = res.data.data;

					DATA.forEach((d) => {
						d.oldStatus = d.status;
					});

					setData([...DATA]);
					setDefData(res.data?.result);

					if (+history.location.search.slice(history.location.search.indexOf('&code=') + 6, history.location.search.indexOf('&is')) === 2) {
						setCountOne(res?.data?.result?.countOne ? res.data.result.countOne : 0);
						setCountTwo(res?.data?.result?.countTwo ? res.data.result.countTwo : 0);
					}

					if (+history.location.search.slice(history.location.search.indexOf('&code=') + 6, history.location.search.indexOf('&is')) === 10) {
						if (DATA.length) {
							const temp = [];

							DATA.forEach((d) => {
								const countThree = +d.countThree;
								const	countFour = +d.countFour;

								let result;

								if (countThree !== -1 && countFour !== -1) {
									if (history.location.search.slice(history.location.search.indexOf('&isHund=') + 8) === 'true') {
										if (countFour > 0 && countThree >= countFour) {
											result = ((countThree - countFour) / countFour) * 50;
										} else if (countThree < countFour) {
											result = 0;
										} else {
											result = 5;
										}

										if (result >= 5) {
											result = 5;
										}
									} else {
										if (countFour > 0 && countThree >= countFour) {
											result = ((countThree - countFour) / countFour) * 40;
										} else if (countThree < countFour) {
											result = 0;
										} else {
											result = 4;
										}

										if (result >= 4) {
											result = 4;
										}
									}

									temp.push(result);
								} else {
									temp.push(-1);
								}
							});

							setTensBall(temp);
						} else {
							setBall(0);
						}
					}

					setCheckAll(!res.data.data.filter((d) => d.status === 0 || d.status === 1 || d.status === 3).length);
					setLoading(false);
				}
			});
	}, []);

	useEffect(() => {
		const temp = { noChecked: 0, success: 0, notSuccess: 0 };

		data.forEach((d) => {
			if (d.status === 0 || d.status  === 1) temp.noChecked++;
			else if (d.status === 2) temp.success++;
			else if (d.status === 3) temp.notSuccess++;
		});

		setStatus(temp);
	}, [data]);

	useEffect(() => {
		if (code !== 10) {
			calculateItogo();
		}
	}, [status]);

	useEffect(() => {
		const isHund = history.location.search.slice(history.location.search.indexOf('&isHund=') + 8);

		if (countTwo > 0) {
			if (isHund === 'false') {
				setBall(Math.round(((countTwo * 10) / countOne) * 6) / 10);

				if (Math.round(((countTwo * 10) / countOne) * 6) / 10 >= 6) {
					setBall(6);
				}
			} else if (isHund === 'true') {
				setBall(Math.round(((countTwo * 10) / countOne) * 9) / 10);

				if (Math.round(((countTwo * 10) / countOne) * 9) / 10 >= 9) {
					setBall(9);
				}
			}
		} else {
			setBall(0);
		}
	}, [countOne, countTwo]);

	useEffect(() => {
		if (code === 10) {
			let temp = 0;
			let	count = 0;

			for (let i = 0; i < tensBall.length; i++) {
				if (tensBall[i] !== -1) {
					temp += tensBall[i];
					count++;
				}
			}

			setBall(Math.round((temp * 10) / count) / 10);

			if (count === 0) {
				setBall(0);
			}
		}
	}, [tensBall]);

	const calculateItogo = () => {
		const isHund = history.location.search.slice(history.location.search.indexOf('&isHund=') + 8);

		const code = +history.location.search.slice(history.location.search.indexOf('&code=') + 6, history.location.search.indexOf('&is'));

		if (isHund === 'false') {
			if (code === 1) {
				if (status.success >= 0 && status.success <= 3) setBall(status.success);
				else if (status.success >= 4) setBall(4);
			} else if (code === 3 || code === 4) {
				setBall(status.success / 10);
				if (status.success >= 70) setBall(7);
			} else if (code === 5) {
				setBall(Math.round((status.success * 50) / 8) / 10);
				if (status.success >= 8) setBall(5);
			} else if (code === 6) {
				setBall(Math.round((status.success * 40) / 20) / 10);
				if (status.success >= 20) setBall(4);
			} else if (code === 7) {
				setBall(Math.round((status.success * 70) / 3) / 10);
				if (status.success >= 3) setBall(7);
			} else if (code === 8 || code === 9) {
				setBall(Math.round((status.success * 70) / 10) / 10);
				if (status.success >= 10) setBall(7);
			} else if (code === 11) {
				setBall(Math.round((status.success * 40) / 3) / 10);
				if (status.success >= 3) setBall(4);
			} else if (code === 12) {
				setBall(Math.round((status.success * 40) / 12) / 10);
				if (status.success >= 12) setBall(4);
			} else if (code === 13) {
				setBall(Math.round((status.success * 40) / 15) / 10);
				if (status.success >= 15) setBall(4);
			}
		} else if (isHund === 'true') {
			if (code === 1) {
				setBall(Math.round((status.success * 60) / 4) / 10);
				if (status.success >= 4) setBall(6);
			} else if (code === 3 || code === 4) {
				setBall(Math.round((status.success * 100) / 70) / 10);
				if (status.success >= 70) setBall(10);
			} else if (code === 5) {
				setBall(Math.round((status.success * 80) / 8) / 10);
				if (status.success >= 8) setBall(8);
			} else if (code === 6) {
				setBall(Math.round((status.success * 50) / 20) / 10);
				if (status.success >= 20) setBall(5);
			} else if (code === 7) {
				setBall(Math.round((status.success * 90) / 3) / 10);
				if (status.success >= 3) setBall(9);
			} else if (code === 8 || code === 9) {
				setBall(Math.round((status.success * 100) / 10) / 10);
				if (status.success >= 10) setBall(10);
			} else if (code === 11) {
				setBall(Math.round((status.success * 60) / 3) / 10);
				if (status.success >= 3) setBall(6);
			} else if (code === 12) {
				setBall(Math.round((status.success * 60) / 12) / 10);
				if (status.success >= 12) setBall(6);
			} else if (code === 13) {
				setBall(Math.round((status.success * 60) / 15) / 10);
				if (status.success >= 15) setBall(6);
			}
		}
	};

	const handleChangeStatusAll = () => {
		if (!checkAll) {
			setData((prev) => {
				let temp = [...prev];
				temp.forEach((t) => (t.status = 2));
				return temp;
			});

			setCheckAll(true);
		} else {
			setData((prev) => {
				let temp = [...prev];
				temp.forEach((t) => (t.status = 1));
				return temp;
			});

			setCheckAll(false);
		}
	};

	const handleChangeStatus = (index, st) => {
		let temp = [...data];

		if (st) {
			if (temp[index].status === 2) {
				temp[index]['status'] = 1;
			} else {
				temp[index]['status'] = 2;
			}
		} else {
			if (temp[index].status === 3) {
				temp[index]['status'] = 1;
			} else {
				temp[index]['status'] = 3;
			}
		}

		setData([...temp]);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		if (code !== 2 && code !== 10) {
			data.forEach((d) => {
				if (d.status !== d.oldStatus) {
					orgApi.markAllEdit(d._id, { status: d.status }).then((res) => {});
				}
			});
		} else if (code === 10) {
			data.forEach((d) => {
				if (d.status !== d.oldStatus) {
					orgApi.markAllEdit(d._id, { status: d.status, countThree: d.countThree, countFour: d.countFour }).then((res) => {});
				}
			});
		}

		const dataItem = {
			code: code,
			comment: e.target[0].value,
			score: ball,
			organization: history.location.search.slice(4, history.location.search.indexOf('&code')),
		};

		if (+e.target[0].value > 0 && +e.target[1].value > 0) {
			if (code === 2) {
				dataItem.countOne = +e.target[0].value > 0 ? +e.target[0].value : 0;
				dataItem.countTwo = +e.target[1].value > 0 ? +e.target[0].value : 0;
				dataItem.comment = e.target[2].value;
			}
		} else {
			if (code === 2) {
				dataItem.countOne = 0;
				dataItem.countTwo = 0;
				dataItem.comment = e.target[2].value;
			}
		}
		if (!!!isEdited?.length) {
			orgApi.mark(dataItem)
				.then((res) => {
					if (res.data.success) {
						history.goBack();
						setLoading(false);
					}
			});
		} else {
			dataItem.id = isEdited;

			orgApi.markEdit(dataItem).then((res) => {
				if (res.data.success) {
					history.goBack();
					setLoading(false);
				}
			});
		}
	};

	if (!history.location.search.includes('code=2')) {
		return (
			<div className={st.mark}>
				<div className='d-flex align-items-center mb-3'>
					<Back {...props} />
					<h6 className='m-0' style={{ color: '#07A287', fontWeight: 500 }}>
						{!loading && title.name[lang]}
					</h6>
				</div>

				<Container>
					<div className={`${ft.content__head} align-items-baseline`}>
						<h6 className={ft.title}>{items[code - 1][lang]}</h6>

						{code !== 10 && (
							<div className='d-flex align-items-center'>
								<div className={st.check__title}>{Translate(lang, 'orgMark.seeAll')}</div>
								<div
									className={`${st.checkbox} ${st.checkbox__small} ${checkAll ? st.checkbox__small__active : ''}`}
									onClick={handleChangeStatusAll}
								>
									{checkIcon}
								</div>
							</div>
						)}
					</div>

					<div className={ft.table}>
						<table className={` ${ft.table__main} ${st.table}`}>
							<thead>
								<tr>
									<td>#</td>
									{code === 1 && <td>{Translate(lang, 'orgMark.name')}</td>}
									{(code === 3 || code === 4) && <td>{Translate(lang, 'table.code3')}</td>}
									{code === 3 && <td>{Translate(lang, 'table.code4')}</td>}
									{code === 4 && <td>{Translate(lang, 'table.code5')}</td>}
									{code === 5 && <td>{Translate(lang, 'table.code6')}</td>}
									{code === 6 && <td>{Translate(lang, 'table.code7')}</td>}
									{code === 7 && <td>{Translate(lang, 'table.code8')}</td>}
									{code === 8 && <td>{Translate(lang, 'table.code9')}</td>}
									{code === 9 && <td>{Translate(lang, 'table.code10')}</td>}
									{code === 10 && <td>{Translate(lang, 'table.code11')}</td>}
									{code === 11 && <td>{Translate(lang, 'table.code12')}</td>}
									{code === 12 && <td>{Translate(lang, 'table.code13')}</td>}
									{code === 13 && <td>{Translate(lang, 'table.code14')}</td>}
									{(code === 1 || code === 2 || code === 3 || code === 4 || code === 11 || code === 12 || code === 13) && (
										<td className='text-center text-nowrap'>{Translate(lang, 'orgAll.date')}</td>
									)}
									{code === 5 && <td>{Translate(lang, 'table.code15')}</td>}
									{code === 5 && <td>{Translate(lang, 'table.code16')}</td>}
									{code === 6 && <td>{Translate(lang, 'table.code17')}</td>}
									{code === 10 && <td>{Translate(lang, 'table.code18')}</td>}
									{code === 10 && <td>{Translate(lang, 'table.code19')}</td>}
									{(code === 1 || code === 3 || code === 4 || code === 7 || code === 8 || code === 9 || code === 10 || code === 11 || code === 12 || code === 13) && (
										<td>{Translate(lang, 'orgMark.link')}</td>
									)}
									{(code === 1 || code === 3 || code === 4 || code === 5 || code === 6 || code === 11 || code === 13) && (
										<td>{Translate(lang, 'table.file')}</td>
									)}
									{(code === 1 || code === 3 || code === 4 || code === 11 || code === 12 || code === 13) && (
										<td className='text-center'>{Translate(lang, 'global.more')}</td>
									)}
									<td className='text-center text-nowrap'>
										{Translate(lang, 'orgMark.completionDate')}
									</td>
									{code === 10 && <td>{Translate(lang, 'table.code20')}</td>}
									{code === 10 && <td>{Translate(lang, 'table.code21')}</td>}
									<td className='text-center'>{Translate(lang, 'table.action')}</td>
								</tr>
							</thead>

							<tbody>
								{!loading ? (
									data.map((d, i) => (
										<tr key={d._id}>
											<td>{i + 1}</td>
											{code !== 2 && <td>{d.nameOne}</td>}
											{(code === 3 || code === 4) && <td>{d.nameTwo}</td>}
											{code === 5 && <td>{d.countOne}</td>}
											{(code === 1 || code === 2 || code === 3 || code === 4 || code === 5 || code === 11 || code === 12 || code === 13) && (
												<td className='text-center text-nowrap'>{BeautyDate(d.date)}</td>
											)}
											{code === 6 && <td>{d.materialType}</td>}
											{code === 10 && <td>{d.countOne}</td>}
											{code === 10 && <td>{d.countTwo}</td>}
											{(code === 1 || code === 3 || code === 4 || code === 7 || code === 8 || code === 9 || code === 10 || code === 11 || code === 12 || code === 13) && (
												<td>{d.link.length ? d.link : '---'}</td>
											)}
											{(code === 1 || code === 3 || code === 4 || code === 5 || code === 6 || code === 11 || code === 13) && (
												<td className='text-nowrap'>
													{d?.files.map((i) => {
														return (
															<div>
																<a
																	target='_blank'
																	href={`${config.api.baseUrl}${i?.path}`}>
																	{i?.path?.slice(15)}
																</a>
															</div>
														);
													})}
												</td>
											)}
											{(code === 1 || code === 3 || code === 4 || code === 11 || code === 12 || code === 13) && (
												<td className='text-center'>
													<button
														className={ft.info__btn}
														onClick={() => {
															setOrgInfo(d);
															setShow(true);
														}}>
														{Translate(lang, 'global.more')}
													</button>
												</td>
											)}
											<td className='text-center text-nowrap'>
												{BeautyDate(d.updatedAt)} {BeautyTime(d.updatedAt)}
											</td>
											{code === 10 && <td>{d?.countThree >= 0 ? d.countThree : '---'}</td>}
											{code === 10 && (
												<td>
													{periods.length === selPer - 1 ? d?.countFour >= 0 ? d.countFour : '---' : d.countTwo}
												</td>
											)}
											<td className='text-center'>
												<div className='d-flex align-items-center justify-content-center'>
													<div
														className={`${st.checkbox} ${st.checkbox__green} ${d.status === 2 ? st.checkbox__green__active : ''} mr-2`}
														onClick={() => {
															if (periods[selPer].status === 1 || markTime?.status === 1) {
																if (code === 10) {
																	setShowModal10(true);
																	setId(i);
																} else {
																	handleChangeStatus(i, true);
																}
															}
														}}
													>
														{checkIcon}
													</div>
													{code !== 10 && (
														<div
															className={`${st.checkbox} ${st.checkbox__del} ${d.status === 3 ? st.checkbox__del__active : ''}`}
															onClick={() => periods[selPer].status === 1 || markTime?.status === 1 ? handleChangeStatus(i, false) : null}
														>
															{closeIcon}
														</div>
													)}
												</div>
											</td>
										</tr>
									))
								) : (
									<Loading big={true} size='md' cl='mt-3' />
								)}
							</tbody>
						</table>

						{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					</div>
				</Container>

				<div className={st.info}>
					<div className={st.info__card}>
						<h6 className={st.info__card__green}>{Translate(lang, 'orgMark.received')}</h6>
						<h5 className={st.info__card__green}>{status.success}</h5>
					</div>

					<div className={st.info__card}>
						<h6 className={st.info__card__red}>{Translate(lang, 'orgMark.notMarked')}</h6>
						<h5 className={st.info__card__red}>{status.noChecked}</h5>
					</div>

					<div className={st.info__card}>
						<h6 className={st.info__card__yellow}>{Translate(lang, 'orgMark.notReceived')}</h6>
						<h5 className={st.info__card__yellow}>{status.notSuccess}</h5>
					</div>

					<div className={st.info__card}>
						<h6 className={st.info__card__all}>{Translate(lang, 'orgMark.all')}</h6>
						<h5 className={st.info__card__all}>{status.success + status.notSuccess + status.noChecked}</h5>
					</div>
				</div>

				{!!show && <OrgMarkModal {...props} show={show} setShow={setShow} orgInfo={orgInfo} />}

				{!!showModal10 && (
					<OrgMark10
						{...props}
						id={id}
						isHund={history.location.search.slice(history.location.search.indexOf('&isHund=') + 8)}
						ball={tensBall}
						setBall={setTensBall}
						show={showModal10}
						setShow={setShowModal10}
						data={data}
						setData={setData}
					/>
				)}

				<form className='my-3 d-flex' onSubmit={handleSubmit}>
					<div className={st.comment}>
						<label htmlFor='textarea'>
							<h6>{Translate(lang, 'orgMark.comment')}</h6>
						</label>
						<textarea id='textarea' cols='30' rows='10' defaultValue={defData?.comment} required />
					</div>

					<div className={st.mark}>
						<p className={st.mark__item}>
							{ball} {Translate(lang, 'orgMark.point')}
						</p>

						{!!markTime?.status && selPer === 0 && (
							<button
								className={ft.save__btn}
								type='submit'
								disabled={status.noChecked > 0}
								style={status.noChecked > 0 ? { opacity: 0.7 } : {}}>
								{Translate(lang, 'table.mark')}
							</button>
						)}
					</div>
				</form>
			</div>
		);
	}

	return (
		<div className={st.mark}>
			<div className='d-flex align-items-center mb-3'>
				<Back {...props} />
				<h6 className='m-0' style={{ color: '#07A287', fontWeight: 500 }}>
					{!loading && title.name[lang]}
				</h6>
			</div>

			<Container>
				<div className={ft.content__head}>
					<h6 className={ft.title}>{Translate(lang, 'orgMark.secondTitle')}</h6>
				</div>

				<div className={ft.table}>
					<table className={` ${ft.table__main} ${st.table}`}>
						<thead>
							<tr>
								<td>#</td>
								<td className='text-center'>{Translate(lang, 'orgMark.allEmployers')}</td>
								<td className='text-center'>{Translate(lang, 'orgMark.studiedEmployers')}</td>
								<td className='text-center'>{Translate(lang, 'table.file')}</td>
							</tr>
						</thead>

						<tbody>
							{!loading ? (
								data.map((d, i) => (
									<tr>
										<td>{i + 1}</td>
										<td className='text-center'>{d.countOne}</td>
										<td className='text-center'>{d.countTwo}</td>
										<td className='text-nowrap text-center'>
											{d?.files.map((i) => {
												return (
													<div>
														<a target='_blank' href={`${config.api.baseUrl}${i?.path}`}>
															{i?.path?.slice(15)}
														</a>
													</div>
												);
											})}
										</td>
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>

					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
				</div>
			</Container>

			<form onSubmit={handleSubmit}>
				<div className={`${st.info} w-75`}>
					<div className={st.info__card} style={{ width: 'fit-content' }}>
						<h6 className='m-0 text-nowrap'>{Translate(lang, 'orgMark.allEmployers')}</h6>
						<input
							type='number'
							min='0'
							value={countOne}
							onChange={(e) => setCountOne(e.target.value)}
							required
						/>
					</div>

					<div className={st.info__card} style={{ width: 'fit-content' }}>
						<h6 className='m-0 text-nowrap'>{Translate(lang, 'orgMark.studiedEmployers')}</h6>
						<input
							type='number'
							min='0'
							value={countTwo}
							onChange={(e) => setCountTwo(e.target.value)}
							required
						/>
					</div>
				</div>

				<div className='my-3 d-flex'>
					<div className={st.comment}>
						<label htmlFor='textarea'>
							<h6>{Translate(lang, 'orgMark.comment')}</h6>
						</label>
						<textarea id='textarea' cols='30' rows='10' defaultValue={defData?.comment} required />
					</div>

					<div className={st.mark}>
						<p className={st.mark__item}>
							<input type='text' value={ball} required />
							{Translate(lang, 'orgMark.point')}
						</p>

						{!!markTime?.status && selPer === 0 && (
							<button className={`${ft.save__btn} w-100`} type='submit'>
								{Translate(lang, 'table.mark')}
							</button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}
