import axiosInstance from '../api';

const api = '/doc-received-answers';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const docReceivedAnswerApi = {
	getList: (data) => axiosInstance.post(`${api}/all`, data, headers),
	getLastByTask: (taskId) => axiosInstance.get(`${api}/get-last-by-task/${taskId}`, headers),
	updateStatus: (id, data) => axiosInstance.put(`${api}/update-status/${id}`, data, headers)
}
