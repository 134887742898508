import axiosInstance from './api';

const api = '/auth/experts';

const headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const authApi = {
	login: (data) => {
		return axiosInstance.post(`${api}/login`, data)
	},
	userMe: (data) =>
		axiosInstance.get(`${api}/me`, {
			headers: {
				Authorization: `Bearer ${data}`,
			},
		}),
	passEdit: (data) => axiosInstance.put(`${api}/reset-password`, data, headers),
	periods: () => axiosInstance.get('/evaluation-time/get-period', headers, headers),
}
