import CustomModal from '../../../../components/customModal'
import ft from '../../../../components/mainStyles.module.scss'
import Translate from '../../../../components/lang'
import ExportPdf from '../../../../components/exportPdf'
import { useEffect, useState } from 'react'
import Loading from '../../../../components/loading'
import { tasksApi } from '../../../../services/tasksService'
import { BeautyDate } from '../../../../utils'

export default function TaskCheckListModal(props) {
	const [data, setData] = useState([]),
		[load, setLoad] = useState(true),
		{ filter, lang, show } = props

	useEffect(() => {
		setLoad(true)
		tasksApi
			.check({
				page: 1,
				limit: 100000,
				...filter,
			})
			.then((res) => {
				if (res.data.success) {
					setData(res.data.data)
					setLoad(false)
				}
			})
	}, [show])

	return (
		<CustomModal {...props} size='lg'>
			<div className={`${ft.content__head} justify-content-between`}>
				<div className={`${ft.btns} w-100 justify-content-end`}>
					<ExportPdf {...props} title={Translate(lang, 'global.taskCheckStat')} id='taskCheckListModal' />
				</div>
			</div>
			<div id='taskCheckListModal'>
				{!load ? (
					<div className={ft.table}>
						<table id='table' className={ft.table__main}>
							<thead>
								<tr>
									<td>#</td>
									<td>{Translate(lang, 'taskSend.taskMeaning')}</td>
									<td>{Translate(lang, 'taskSend.deadline')}</td>
									<td>{Translate(lang, 'taskSend.fileName')}</td>
									<td>{Translate(lang, 'global.orgName')}</td>
									<td className='text-center'>{Translate(lang, 'taskCheck.sentDate')}</td>
									<td className='text-center'>{Translate(lang, 'orgAll.status')}</td>
								</tr>
							</thead>
							<tbody>
								{data.map((d, index) => (
									<tr>
										<td>{index + 1}</td>
										<td>{d.description}</td>
										<td className='text-nowrap'>{BeautyDate(d.deadline)}</td>
										<td>{d.docName}</td>
										<td>{d.org.name[lang]}</td>
										<td className='text-center'>{d?.complete ? BeautyDate(d?.complete) : '---'}</td>
										<td className='text-center'>
											{d.status === 0 ? (
												<span className={ft.isNotDone}>{Translate(lang, 'home.uncompleted')}</span>
											) : d.status === 1 ? (
												<span className={ft.isWaiting}>{Translate(lang, 'taskCheck.fileSent')}</span>
											) : d.status === 2 ? (
												<span className={ft.isDone}>{Translate(lang, 'homeAll.received')}</span>
											) : (
												<span className={ft.isNotDone}>{Translate(lang, 'homeAll.notReceived')}</span>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className='d-flex justify-content-center'>
						<Loading size='md' cl='text-center' />
					</div>
				)}
			</div>
		</CustomModal>
	)
}
