import { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

import config from "../../../config";

import { expApi } from '../../../services/expertsService';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';

import { GeneratePassword, ValidatePassword } from '../../../utils';

import ft from '../../../components/mainStyles.module.scss';

export default function ExpertEditPass(props) {
	const [err, setErr] = useState(false),
		[errPass, setErrPass] = useState(false),
		[load, setLoad] = useState(false),
		[view, setView] = useState(false),
		password = useRef(''),
		passwordConfirm = useRef(''),
		{ id, setShow, setData, setLoading, lang } = props

	const handleSubmit = (e) => {
		e.preventDefault()
		if (ValidatePassword(e.target[0].value)) {
			setErrPass(false)
			if (e.target[0].value === e.target[1].value) {
				setErr(false)
				setLoad(true)
				axios
					.put(
						`${config.api.baseUrl}/api/auth/admin/reset-exp-password/${id}`,
						{
							password: e.target[0].value,
						},
						{
							headers: {
								Authorization: `Bearer ${sessionStorage.getItem('token')}`,
							},
						}
					)
					.then((res) => {
						if (res.data.success) {
							setLoad(false)
							setLoading(true)
							expApi
								.all(
									{ limit: 10, page: 1 },
									{
										headers: {
											Authorization: `Bearer ${sessionStorage.getItem('token')}`,
										},
									}
								)
								.then((res) => {
									setData(res.data.data)
									setLoading(false)
								})
							setShow(false)
						}
					})
			} else setErr(true)
		} else setErrPass(true)
	}

	return (
		<CustomModal title={Translate(lang, 'experts.editPass')} {...props}>
			<Form className={ft.form} onSubmit={handleSubmit}>
				<Form.Label>
					<div className={`${ft.lab} d-flex justify-content-between`}>
						{Translate(lang, 'experts.newPass')}
						<span
							style={{ cursor: 'pointer', lineHeight: '24px' }}
							onClick={() => {
								const temp = GeneratePassword()
								password.current.value = temp
								passwordConfirm.current.value = temp
							}}>
							{Translate(lang, 'global.genPass')}
						</span>
					</div>
					<div className='position-relative'>
						<Form.Control ref={password} minLength={8} type={view ? 'text' : 'password'} required />
						<i
							className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
							style={{
								top: '50%',
								right: 0,
								transform: 'translate(-10px, -8px)',
								cursor: 'pointer',
							}}
							onClick={() => setView(!view)}></i>
					</div>
				</Form.Label>
				<Form.Label className='w-100 position-relative'>
					<div className={ft.lab}>{Translate(lang, 'experts.confirmPass')}</div>
					<div className='position-relative'>
						<Form.Control ref={passwordConfirm} minLength={8} type={view ? 'text' : 'password'} required />
						<i
							className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
							style={{
								top: '50%',
								right: 0,
								transform: 'translate(-10px, -8px)',
								cursor: 'pointer',
							}}
							onClick={() => setView(!view)}></i>
					</div>
				</Form.Label>
				{errPass ? <span className={ft.err__mes}>{Translate(lang, 'global.errPass')}</span> : ''}
				{err ? <span className={ft.err__mes}>{Translate(lang, 'experts.notSuitablePass')}</span> : ''}
				<div className='d-flex mt-2 justify-content-end'>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setShow(false)
							setLoad(false)
						}}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load}>
						{Translate(lang, 'global.save')} {load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
