import ft from '../../../../components/mainStyles.module.scss'
import Translate from '../../../../components/lang'
import CustomModal from '../../../../components/customModal'
import { Form } from 'react-bootstrap'

export default function OrgMark10(props) {
	const { setShow, lang, setData, data, id, setBall, isHund, periods, selPer } = props

	const handleCalculate = (e) => {
		e.preventDefault()
		const countThree = +e.target[0].value,
			countFour = +e.target[1].value
		setData((prev) => {
			const temp = [...prev]
			temp[id].countThree = countThree
			temp[id].countFour = countFour
			temp[id].status = 2
			return temp
		})

		let result
		if (isHund == 'true') {
			if (countFour > 0 && countThree >= countFour) result = ((countThree - countFour) / countFour) * 50
			else if (countThree < countFour || (countFour === 0 && countThree === 0)) result = 0
			else result = 5

			if (result >= 5) result = 5
		} else {
			if (countFour > 0 && countThree >= countFour) result = ((countThree - countFour) / countFour) * 40
			else if (countThree < countFour || (countFour === 0 && countThree === 0)) result = 0
			else result = 4

			if (result >= 4) result = 4
		}
		setBall((prev) => {
			const temp = [...prev]
			temp[id] = result
			return temp
		})

		setShow(false)
	}

	return (
		<CustomModal {...props}>
			<Form onSubmit={handleCalculate}>
				<Form.Label>
					<div className={ft.lab}>{Translate(lang, 'table.code20')}</div>
					<Form.Control type='number' min='0' defaultValue={data[id].countThree >= 0 ? data[id].countThree : 0} required />
				</Form.Label>
				<Form.Label>
					<div className={ft.lab}>{Translate(lang, 'table.code21')}</div>
					<Form.Control type='number' min='0' defaultValue={data[id].countTwo} disabled={periods.length === selPer - 1 ? false : true} required />
				</Form.Label>
				<div className={ft.modalBtns}>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setShow(false)
						}}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit'>
						{Translate(lang, 'global.save')}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
