import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { orgApi } from '../../../../services/orgListService';

import Translate from '../../../../components/lang';
import CustomModal from '../../../../components/customModal';

import { del } from '../../../../components/icons';

import ft from '../../../../components/mainStyles.module.scss';
import cls from '../Normative.module.scss';

const Organizations = ({
   setAdditionalOrgIds,
   additionalOrgIds,
   setMainOrgIds,
   mainOrgIds,
   setTasks,
   tasks,
   lang
}) => {
   const [items, setItems] = useState([]);
   const [show, setShow] = useState(false);
   const [orgId, setOrgId] = useState(null);

   const addTask = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const elements = e.target.elements;

      const newTask = {
         orgId,
         deadline: elements.deadline.value,
         description: elements.description.value
      };

      setTasks([...tasks, newTask]);

      setOrgId(null);
      setShow(false);
   }

   const removeTask = (task) => {
      const filteredTasks = tasks.filter(item => !(item.orgId === task.orgId && item.description === task.description && item.deadline === task.deadline));
      setTasks([...filteredTasks]);
   }

   const removeOrgTasks = (orgId) => {
      const filteredTasks = tasks.filter(item => item.orgId !== orgId);
      setTasks([...filteredTasks]);
   }

   useEffect(() => {
      orgApi.getAll()
         .then(res => {
            if (res.data.success) {
               setItems(res.data.data);
            }
         })
         .catch(err => {
            console.log(err);
         });
   }, []);

   return (
      <div className={cls.organization}>
         <div className={cls.organizationItem}>
            <div className={cls.organizationContent}>
               <input
                  onChange={() => {
                     if (additionalOrgIds.length !== items.length) {
                        setAdditionalOrgIds([...items.map(item => item._id)]);
                     } else {
                        setAdditionalOrgIds([]);
                     }
                     setMainOrgIds([]);
                     setTasks([]);
                  }}
                  checked={additionalOrgIds.length === items.length}
                  type='checkbox'
               />

               <div className={cls.organizationName}>{Translate(lang, 'global.all')}</div>

               <input
                  onChange={() => {
                     if (mainOrgIds.length !== items.length) {
                        setMainOrgIds([...items.map(item => item._id)]);
                     } else {
                        setMainOrgIds([]);
                        setTasks([]);
                     }
                     setAdditionalOrgIds([]);
                  }}
                  checked={mainOrgIds.length === items.length}
                  type='checkbox'
               />
            </div>
         </div>

         {items.map(item => (
            <div className={cls.organizationItem} key={item._id}>
               <div className={cls.organizationContent}>
                  <input
                     onChange={() => {
                        if (!additionalOrgIds.includes(item._id)) {
                           if (mainOrgIds.includes(item._id)) {
                              removeOrgTasks(item._id);
                              setMainOrgIds([...mainOrgIds.filter(orgId => orgId !== item._id)]);
                           }
                           setAdditionalOrgIds([...additionalOrgIds, item._id]);
                        } else {
                           setAdditionalOrgIds([...additionalOrgIds.filter(orgId => orgId !== item._id)]);
                        }
                     }}
                     checked={additionalOrgIds.includes(item._id)}
                     type='checkbox'
                  />

                  <div className={cls.organizationName}>{item.name[lang]}</div>

                  <input
                     onChange={() => {
                        if (!mainOrgIds.includes(item._id)) {
                           if (additionalOrgIds.includes(item._id)) {
                              setAdditionalOrgIds([...additionalOrgIds.filter(orgId => orgId !== item._id)]);
                           }
                           setMainOrgIds([...mainOrgIds, item._id]);
                        } else {
                           removeOrgTasks(item._id);
                           setMainOrgIds([...mainOrgIds.filter(orgId => orgId !== item._id)]);
                        }
                     }}
                     checked={mainOrgIds.includes(item._id)}
                     type='checkbox'
                  />

                  {mainOrgIds.includes(item._id) && (
                     <button
                        className={cls.organizationBtn}
                        onClick={() => {
                           setOrgId(item._id);
                           setShow(true);
                        }}
                        type='button'
                     >
                        <span>+</span> {Translate(lang, 'global.add')}
                     </button>
                  )}
               </div>

               {mainOrgIds.includes(item._id) && (
                  <div className={cls.organizationTasks}>
                     {tasks
                        .filter(task => task.orgId === item._id)
                        .map((task, index) => (
                           <div className={cls.organizationTask} key={index}>
                              <div className={cls.organizationTaskIndex}>{index + 1}.</div>
                              <div className={cls.organizationTaskDescription}>{task.description}</div>
                              <div className={cls.organizationTaskDeadline}>{task.deadline}</div>
                              <div
                                 className={cls.organizationTaskDelete}
                                 onClick={() => removeTask(task)}
                              >
                                 {del}
                              </div>
                           </div>
                        ))
                     }
                  </div>
               )}
            </div>
         ))}

         <CustomModal
            modalTitle={Translate(lang, 'modal.assignment')}
            {...{ show, setShow, lang }}
         >
            <Form className={ft.form} onSubmit={addTask}>
               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'docTask.deadline')}</div>
                  <Form.Control name='deadline' type='date' required />
               </Form.Label>

               <Form.Label className='my-2'>
                  <div className={ft.lab}>{Translate(lang, 'docTask.description')}</div>
                  <Form.Control name='description' as='textarea' type='text' required />
               </Form.Label>

               <button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
                  {Translate(lang, 'global.add')}
               </button>
            </Form>
         </CustomModal>
      </div>
   );
}

export default Organizations;