import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { docApi } from '../../services/doc/docService';

import Translate from '../../components/lang';
import Loading from '../../components/loading';
import Container from '../../components/container';
import UploadFiles from '../../components/uploadFiles';

import ft from '../../components/mainStyles.module.scss';

const Update = ({ lang, history }) => {
   const { docId } = useParams();
   const [load, setLoad] = useState(false);
   const [loading, setLoading] = useState(false);
   const [errFiles, setErrFiles] = useState(false);
   const [doc, setDoc] = useState([]);
   const [prots, setProts] = useState([]);
   const [selFiles, setSelFiles] = useState([]);
   const [newFiles, setNewFiles] = useState([]);
   const [uploadedFiles, setUploadedFiles] = useState([]);

   const handleSubmit = (e) => {
      e.preventDefault();

      const files = [];

      selFiles.map(file => {
         if (file.success) {
            files.push({ file: file._id, path: file.path, size: file.size });
         }
      })
      uploadedFiles.map(file => {
         files.push({ file: file._id, path: file.path, size: file.size });
      });

      const elements = e.target.elements;

      const data = {
         number: elements.number.value,
         description: elements.description.value,
         type: elements.type.value,
         link: elements.link.value,
         files: files,
         tasks: [],
         mainOrgIds: [],
         additionalOrgIds: [],
         moduleType: 'NEIGHBORHOOD'
      };

      setLoad(true);

      docApi.update(docId, data)
         .then(res => {
            if (res.data.success) {
               history.goBack();
            }
         })
         .catch(err => {
            console.log(err);
         })
         .finally(() => {
            setLoad(false);
         });
   }

   useEffect(() => {
      setLoading(true);

      docApi.getFullById(docId)
         .then(res => {
            if (res.data.success) {
               const data = res.data.data;

               const FILES = [];

               data.doc?.files?.forEach(file => {
                  FILES.push({
                     _id: file._id,
                     name: file.path.slice(15),
                     file: file.file,
                     path: file.path,
                     success: true
                  });
               });

               setDoc(data.doc);
               setSelFiles(FILES);
            }
         })
         .catch(err => {
            console.log(err);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);

   if (loading) {
      return (
         <Container>
            <div style={{ display: "grid", placeItems: "center", height: 300 }}>
               <Loading big={true} size='md' />
            </div>
         </Container>
      )
   }

   return (
      <Container>
         <Form className={ft.form} onSubmit={handleSubmit}>
            <div className={ft.modalBtns}>
               <button
                  className={ft.close__btn}
                  onClick={() => history.goBack()}
                  type='button'
               >
                  {Translate(lang, 'global.cancel')}
               </button>

               <button
                  className={`${ft.save__btn} d-flex align-items-center ml-3`}
                  disabled={load}
                  type='submit'
               >
                  {Translate(lang, 'global.save')}
                  {load ? <Loading cl='ml-1' size='sm' /> : ''}
               </button>
            </div>

            <div className='row'>
               <div className='col-md-12'>
                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.number')}</div>
                     <Form.Control name='number' defaultValue={doc.number} type='text' required />
                  </Form.Label>

                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.description')}</div>
                     <Form.Control name='description' defaultValue={doc.description} as='textarea' required />
                  </Form.Label>

                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.type')}</div>
                     <select name='type' defaultValue={doc.type}>
                        <option value='DECISION'>{Translate(lang, 'doc.decision')}</option>
                        <option value='DECREE'>{Translate(lang, 'doc.decree')}</option>
                        <option value='ORDER'>{Translate(lang, 'doc.order')}</option>
                        <option value='LAW'>{Translate(lang, 'doc.law')}</option>
                     </select>
                  </Form.Label>

                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.link')}</div>
                     <Form.Control name='link' defaultValue={doc.link} type='text' />
                  </Form.Label>

                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.files')}</div>
                     <UploadFiles
                        setUploadedFiles={setUploadedFiles}
                        setNewFiles={setNewFiles}
                        setErrFiles={setErrFiles}
                        selFiles={selFiles}
                        setProts={setProts}
                        newFiles={newFiles}
                        setLoad={setLoad}
                        prots={prots}
                        load={load}
                        lang={lang}
                     />
                     {errFiles && (
                        <span className={ft.err__mes}>
                           {Translate(lang, 'global.notUploaded')}
                        </span>
                     )}
                  </Form.Label>
               </div>
            </div>
         </Form>
      </Container>
   )
}

export default Update;
