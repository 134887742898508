import CustomModal from '../../../../components/customModal'
import ft from '../../../../components/mainStyles.module.scss'
import Translate from '../../../../components/lang'
import ExportPdf from '../../../../components/exportPdf'
import { useEffect, useState } from 'react'
import Loading from '../../../../components/loading'
import { tasksApi } from '../../../../services/tasksService'
import { BeautyDate } from '../../../../utils'
import config from "../../../../config";

export default function TaskSendListModal(props) {
	const [data, setData] = useState([]),
		[load, setLoad] = useState(true),
		{ filter, lang, show } = props

	useEffect(() => {
		setLoad(true)
		tasksApi
			.all(
				{
					page: 1,
					limit: 100000,
				},
				filter
			)
			.then((res) => {
				if (res.data.success) {
					setData(res.data.data)
					setLoad(false)
				}
			})
	}, [show])

	return (
		<CustomModal {...props} size='lg'>
			<div className={`${ft.content__head} justify-content-between`}>
				<div className={`${ft.btns} w-100 justify-content-end`}>
					<ExportPdf {...props} title={Translate(lang, 'global.taskSendStat')} id='taskSendListModal' />
				</div>
			</div>
			<div id='taskSendListModal'>
				{!load ? (
					<div className={ft.table}>
						<table id='table' className={ft.table__main}>
							<thead>
								<tr>
									<td>#</td>
									<td>{Translate(lang, 'taskSend.taskMeaning')}</td>
									<td className='text-center'>{Translate(lang, 'taskSend.deadline')}</td>
									<td>{Translate(lang, 'global.fileName')}</td>
									<td>{Translate(lang, 'table.file')}</td>
									<td>{Translate(lang, 'global.orgName')}</td>
									<td>{Translate(lang, 'table.expert')}</td>
									<td className='text-center'>{Translate(lang, 'taskCheck.sentDate')}</td>
								</tr>
							</thead>
							<tbody>
								{data.map((d, index) => (
									<tr>
										<td>{index + 1}</td>
										<td>{d.description}</td>
										<td className='text-nowrap text-center'>{BeautyDate(d.deadline)}</td>
										<td>{d.docName}</td>
										<td className='text-nowrap'>
											{d?.files.map((i) => {
												return (
													<div>
														<a target='_blank' href={`${config.api.baseUrl}${i?.path}`}>
															{i?.path?.slice(15)}
														</a>
													</div>
												)
											})}
										</td>
										<td>{d?.org?.name[lang]}</td>
										<td>{d?.exp?.fullName}</td>
										<td className='text-center'>{d?.complete ? BeautyDate(d?.complete) : '---'}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className='d-flex justify-content-center'>
						<Loading size='md' cl='text-center' />
					</div>
				)}
			</div>
		</CustomModal>
	)
}
