import CustomModal from '../../../../components/customModal'
import st from './taskCheckModal.module.scss'
import { exp } from '../../../../components/icons'
import { tasksApi } from '../../../../services/tasksService'
import Loading from '../../../../components/loading'
import { useState } from 'react'
import { BeautyDate } from '../../../../utils'
import Translate from '../../../../components/lang'
import config from "../../../../config";

export default function TaskCheckModal(props) {
	const { checkData, handleChangeStatus, load, lang } = props,
		[sel, setSel] = useState()

	return (
		<CustomModal {...props} size='lg' modalTitle={Translate(lang, 'taskSend.taskMeaning')}>
			<div className={st.taskCheckModal}>
				<div className='my-2'>
					<p className={st.title}>{Translate(lang, 'taskCheck.more')}</p>
					<p className={st.desc}>{!!checkData.fullDescription ? checkData.fullDescription : '---'}</p>
				</div>
				<div className='my-2 row'>
					<div className='col-6'>
						<p className={st.title}>{Translate(lang, 'taskCheck.attachedFile')}</p>
						<p className={st.links}>
							{checkData?.files?.map((f) => (
								<p className='my-1'>
									<a target='_blank' href={`${config.api.baseUrl}/${f.path}`}>
										{' '}
										{f.path.slice(15)} {exp}
									</a>{' '}
								</p>
							))}
						</p>
					</div>
					<div className='col-6'>
						<p className={st.title}>{Translate(lang, 'taskCheck.executionFile')}</p>
						<p className={st.links}>
							{!!checkData?.respondFiles?.length
								? checkData?.respondFiles?.map((f) => (
										<p className='my-1'>
											<a target='_blank' href={`${config.api.baseUrl}/${f.path}`}>
												{' '}
												{f.path.slice(15)} {exp}
											</a>
										</p>
								  ))
								: `${Translate(lang, 'taskCheck.notUploaded')}`}
						</p>
					</div>
				</div>
				<div className='my-2 d-flex justify-content-between align-items-center'>
					<p className={`${st.title} m-0`} style={{ width: '43%' }}>
						{Translate(lang, 'taskSend.deadline')}: {BeautyDate(checkData?.deadline)}
					</p>
					<div className='d-flex justify-content-around align-items-center' style={{ width: '57%' }}>
						<button
							className={`${st.btns} ${st.btns__red}`}
							onClick={() => {
								handleChangeStatus(false)
								setSel(1)
							}}>
							<div className={`${st.btns__circle} ${st.btns__circle__red}`}>
								<div className={checkData.status === 3 ? st.btns__circle__red__active : 'd-none'}></div>
							</div>
							{Translate(lang, 'taskCheck.notAccepted')} {sel === 1 && load ? <Loading size='sm' cl='ml-1' /> : ''}
						</button>
						<button
							className={`${st.btns} ${st.btns__green}`}
							onClick={() => {
								handleChangeStatus(true)
								setSel(2)
							}}>
							<div className={`${st.btns__circle} ${st.btns__circle__green}`}>
								<div className={checkData.status === 2 ? st.btns__circle__green__active : 'd-none'} />
							</div>
							{Translate(lang, 'taskCheck.accepted')} {sel === 2 && load ? <Loading size='sm' cl='ml-1' /> : ''}
						</button>
					</div>
				</div>
			</div>
		</CustomModal>
	)
}
