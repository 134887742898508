import React, { useEffect, useState } from 'react'
import ft from '../../components/mainStyles.module.scss'
import Container from '../../components/container'
import AddAdviceModal from './addAdviceModal'
import EditAdviceModal from './editAdviceModal'
import Loading from '../../components/loading'
import Del from '../../components/del'
import Translate from '../../components/lang'
import { advicesApi } from '../../services/advicesService'
import { orgApi } from '../../services/orgListService'
import { BeautyDate } from '../../utils'
import { edit, del } from '../../components/icons'
import config from "../../config";

export default function Advices(props) {
	const [data, setData] = useState([]),
		[options, setOptions] = useState([]),
		[editData, setEditData] = useState([]),
		[show, setShow] = useState(false),
		[showEdit, setShowEdit] = useState(false),
		[showDel, setShowDel] = useState(false),
		[loading, setLoading] = useState(true),
		[load, setLoad] = useState(false),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[id, setId] = useState(null),
		{ lang, token } = props

	useEffect(() => {
		advicesApi.all({ limit: 1000, page: 1 }, token).then((res) => {
			if (res.data.success) {
				orgApi.getAll().then((res) => {
					const DATA = [];

					res.data.data.map((d) => {
						if (d.status === "0") {
							DATA.push({
								value: d._id,
								label: d.name.uz,
							});
						}
					});

					setOptions(DATA);
				});

				setData(res.data.data);
				setLoading(false);
			}
		})
	}, []);

	const handleDelete = (id) => {
		setLoad(true)
		advicesApi.delete(id).then((res) => {
			if (res.data.success) {
				setLoad(false)
				setShowDel(false)
				setLoading(true)
				advicesApi.all({ limit: 10, page: 1 }).then((res) => {
					if (res.data.success) {
						setData(res.data.data)
						setLoading(false)
					}
				})
			}
		})
	}

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'advices.title')}</h5>
					<div className={ft.btns}>
						<button className={ft.add__btn} onClick={() => setShow(true)}>
							<span className={ft.icon}>+</span>
							{Translate(lang, 'global.add')}
						</button>
					</div>
				</div>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'advices.theme')}</td>
								<td>{Translate(lang, 'table.file')}</td>
								<td>{Translate(lang, 'advices.orgLists')}</td>
								<td>{Translate(lang, 'table.expert')}</td>
								<td>{Translate(lang, 'advices.sentDate')}</td>
								<td className={`text-center  ${ft.removeOnPrint}`}>{Translate(lang, 'table.action')}</td>
							</tr>
						</thead>
						{!loading ? (
							<tbody>
								{data.map((d, i) => (
									<tr key={i}>
										<td>{i + 1}</td>
										<td>{d.description}</td>
										<td className='text-nowrap'>
											{d?.files.map((i) => {
												return (
													<div>
														<a target='_blank' href={`${config.api.baseUrl}${i?.path}`}>
															{i?.path?.slice(15)}
														</a>
													</div>
												)
											})}
										</td>
										<td>{d.organizations.name[lang]}</td>
										<td>{d?.expert ? d?.expert?.fullName : '---'}</td>
										<td>{BeautyDate(d.updatedAt)}</td>
										<td className={`  ${ft.removeOnPrint}`}>
											<div className='d-flex'>
												<button
													className={ft.edit__btn}
													onClick={() => {
														setId(d._id)
														setEditData(d)
														setShowEdit(true)
													}}>
													{edit}
												</button>
												<button
													className={ft.del__btn}
													onClick={() => {
														setId(d._id)
														setShowDel(true)
													}}>
													{del}
												</button>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						) : (
							<Loading big={true} size='md' cl='mt-3' />
						)}
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
				</div>
			</Container>

			{!!show && <AddAdviceModal {...props} show={show} setShow={setShow} setLoading={setLoading} options={options} setData={setData} />}

			{!!showEdit && <EditAdviceModal {...props} id={id} show={showEdit} setShow={setShowEdit} setLoading={setLoading} data={editData} setData={setData} />}

			{!!showDel && <Del {...props} id={id} show={showDel} setShow={setShowDel} load={load} handleDelete={handleDelete} />}
		</div>
	)
}
