import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import config from '../../config';

import { orgApi } from '../../services/orgListService';
import { propagandaDayApi } from '../../services/propagandaDayService';

import Pagin from '../../components/pagin';
import Filter from '../../components/filter';
import Translate from '../../components/lang';
import Loading from '../../components/loading';
import Container from '../../components/container';
import CustomSelect from '../../components/customSelect';

import { edit } from '../../components/icons';

import { BeautyFullTime } from '../../utils';

import ft from '../../components/mainStyles.module.scss';

const PropagandaDay = ({ lang }) => {
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({});
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showFilter, setShowFilter] = useState(false);
	const [pc, setPc] = useState(0);
	const [pageNum, setPageNum] = useState(1);
	const [page, setPage] = useState({ limit: 10, page: 1 });

	const handleFilter = (e) => {
		e.preventDefault();

		const { topic, start, end } = e.target.elements;

		const newFilter = {};

		if (selected.length) newFilter.orgIds = selected.map(item => item.value);
		if (topic.value) newFilter.topic = topic.value;
		if (start.value) newFilter.start = start.value;
		if (end.value) newFilter.end = end.value;

		setFilter(newFilter);

		setShowFilter(false);

		setPage({ ...page, page: 1 });
	}

	useEffect(() => {
		setLoading(true);

		propagandaDayApi
			.getList({ ...page, ...filter })
			.then(res => {
				if (res.data.success) {
					setPageNum(Math.ceil(res.data.num));
					setPc((page.page - 1) * page.limit);
					setData(res.data.data);
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [page]);

	useEffect(() => {
		orgApi.getAll()
			.then(res => {
				if (res.data.success) {
					const DATA = [];

					res.data.data.map((d) => {
						DATA.push({
							value: d._id,
							label: d.name.uz
						});
					});

					setOptions(DATA);
				}
			})
			.catch(err => {
				console.log(err);
			});
	}, []);

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>
						{Translate(lang, 'navbar.propagandaDay')}
					</h5>

					<div className={ft.btns}>
						<button
							className={`${ft.primary__btn} ml-2`}
							onClick={() => setShowFilter(true)}
						>
							{Translate(lang, 'global.filter')}
						</button>
					</div>
				</div>

				<div className={ft.table}>
					<table className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'global.orgName')}</td>
								<td>{Translate(lang, 'global.topic')}</td>
								<td>{Translate(lang, 'global.shape')}</td>
								<td>{Translate(lang, 'table.file')}</td>
								<td>{Translate(lang, 'table.createdAt')}</td>
								<td className='text-center'>{Translate(lang, 'table.action')}</td>
							</tr>
						</thead>

						<tbody>
							{loading ? (
								<tr>
									<td colSpan={7}>
										<Loading cl='mt-2' size='md' big />
									</td>
								</tr>
							) : data?.length ? (
								data.map((item, index) => (
									<tr key={item._id}>
										<td>{pc + index + 1}</td>
										<td>{item?.organization?.name[lang]}</td>
										<td>{item?.topic}</td>
										<td>{item?.shape}</td>
										<td className='text-nowrap'>
											{item?.files?.map((file, index) => (
												<div key={index}>
													<a href={`${config.api.baseUrl}${file?.path}`} target='_blank'>
														{file?.path?.slice(15)}
													</a>
												</div>
											))}
										</td>
										<td>
											<div style={{ width: 180 }}>{BeautyFullTime(item?.createdAt)}</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<button
													className={ft.edit__btn}
													onClick={() => {

													}}
												>
													{edit}
												</button>
											</div>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={7}>
										<h6 className={ft.noData}>
											{Translate(lang, 'global.noInfo')}
										</h6>
									</td>
								</tr>
							)}
						</tbody>
					</table>

					{!loading && (
						<Pagin pageNum={pageNum} page={page} setPage={setPage}/>
					)}
				</div>
			</Container>

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label>
					<div className={ft.lab}>{Translate(lang, 'orgList.orgName')}</div>
						<CustomSelect lang={lang} selected={selected} handleChange={v => setSelected(v)} options={options} />
					</Form.Label>

					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.topic')}</div>
						<Form.Control name='topic' defaultValue={filter.topic} type='text' />
					</Form.Label>

					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.from')}</div>
						<Form.Control name='start' defaultValue={filter.start} type='date' />
					</Form.Label>

					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.to')}</div>
						<Form.Control name='end' defaultValue={filter.end} type='date' />
					</Form.Label>

					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'global.search')}
					</button>
				</Form>
			</Filter>
		</div>
	);
};

export default PropagandaDay;
