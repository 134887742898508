import axiosInstance from '../api';

const api = '/doc-statistics';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const docStatisticApi = {
	getTaskStatistics: (data) => axiosInstance.post(`${api}/tasks`, data, headers),
	getReceivedStatistics: (data) => axiosInstance.post(`${api}/receiveds`, data, headers),
	exportToExcelTasks: (periodId, data) => axiosInstance.post(`${api}/export-excel-tasks/${periodId}`, data, headers),
	exportToExcelReceiveds: (periodId, data) => axiosInstance.post(`${api}/export-excel-receiveds/${periodId}`, data, headers),
}
