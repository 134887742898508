import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { docApi } from '../../../services/doc/docService';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import Container from '../../../components/container';
import UploadFiles from '../../../components/uploadFiles';

import Organizations from './components/Organizations';

import ft from '../../../components/mainStyles.module.scss';

const Create = ({ lang, history }) => {
   const [load, setLoad] = useState(false);
   const [errFiles, setErrFiles] = useState(false);
   const [tasks, setTasks] = useState([]);
   const [prots, setProts] = useState([]);
   const [newFiles, setNewFiles] = useState([]);
   const [uploadedFiles, setUploadedFiles] = useState([]);
   const [mainOrgIds, setMainOrgIds] = useState([]);
   const [additionalOrgIds, setAdditionalOrgIds] = useState([]);

   const handleSubmit = (e) => {
      e.preventDefault();

      const files = uploadedFiles.map(file => ({
         file: file._id,
         path: file.path,
         size: file.size
      }));

      const elements = e.target.elements;

      const data = {
         number: elements.number.value,
         description: elements.description.value,
         type: elements.type.value,
         link: elements.link.value,
         files: files,
         tasks: tasks,
         mainOrgIds: mainOrgIds,
         additionalOrgIds: additionalOrgIds,
         moduleType: 'NORMATIVE',
         status: 'NEW'
      };

      setLoad(true);

      docApi.create(data)
        .then(res => {
           if (res.data.success) {
              history.goBack();
           }
        })
        .catch(err => {
           console.log(err);
        })
        .finally(() => {
           setLoad(false);
        });
   }

   return (
      <Container>
         <Form className={ft.form} onSubmit={handleSubmit}>
            <div className={ft.modalBtns}>
               <button
                  className={ft.close__btn}
                  onClick={() => history.goBack()}
                  type='button'
               >
                  {Translate(lang, 'global.cancel')}
               </button>

               <button
                  className={`${ft.save__btn} d-flex align-items-center ml-3`}
                  disabled={load}
                  type='submit'
               >
                  {Translate(lang, 'global.save')}
                  {load ? <Loading cl='ml-1' size='sm' /> : ''}
               </button>
            </div>

            <div className='row'>
               <div className='col-md-6'>
                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.number')}</div>
                     <Form.Control name='number' type='text' required />
                  </Form.Label>

                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.description')}</div>
                     <Form.Control name='description' as='textarea' required />
                  </Form.Label>

                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.type')}</div>
                     <select name='type'>
                        <option value='DECISION'>{Translate(lang, 'doc.decision')}</option>
                        <option value='DECREE'>{Translate(lang, 'doc.decree')}</option>
                        <option value='ORDER'>{Translate(lang, 'doc.order')}</option>
                        <option value='LAW'>{Translate(lang, 'doc.law')}</option>
                     </select>
                  </Form.Label>

                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.link')}</div>
                     <Form.Control name='link' type='text' />
                  </Form.Label>

                  <Form.Label>
                     <div className={ft.lab}>{Translate(lang, 'doc.files')}</div>
                     <UploadFiles
                        setUploadedFiles={setUploadedFiles}
                        setNewFiles={setNewFiles}
                        setErrFiles={setErrFiles}
                        setProts={setProts}
                        newFiles={newFiles}
                        setLoad={setLoad}
                        prots={prots}
                        load={load}
                        lang={lang}
                     />
                     {errFiles && (
                        <span className={ft.err__mes}>
                           {Translate(lang, 'global.notUploaded')}
                        </span>
                     )}
                  </Form.Label>
               </div>

               <div className='col-md-6'>
                  <div className={ft.lab}>{Translate(lang, 'global.org')}</div>
                  <Organizations
                     setAdditionalOrgIds={setAdditionalOrgIds}
                     additionalOrgIds={additionalOrgIds}
                     setMainOrgIds={setMainOrgIds}
                     mainOrgIds={mainOrgIds}
                     setTasks={setTasks}
                     tasks={tasks}
                     lang={lang}
                  />
               </div>
            </div>
         </Form>
      </Container>
   )
}

export default Create;
