import { useEffect, useState } from 'react';
import Container from '../../../../components/container';
import ft from '../../../../components/mainStyles.module.scss';
import st from '../../orgMark/orgMark.module.scss';
import Loading from '../../../../components/loading';
import { BeautyDate } from '../../../../utils';
import Translate from '../../../../components/lang';
import { orgApi } from '../../../../services/orgListService.js';
import { tasksApi } from '../../../../services/tasksService';
import TaskCheckModal from '../../../tasks/tasksCheck/taskCheckModal';
import config from "../../../../config";

export default function RightSide(props) {
	const [status, setStatus] = useState({}),
		[ball, setBall] = useState(0),
		[load, setLoad] = useState(false),
		[id, setId] = useState(''),
		[checkData, setCheckData] = useState({}),
		[show, setShow] = useState(false),
		[comment, setComment] = useState(false),
		{ lang, loading, data, setData, result, history, leftData, markTime, periods, selPer } = props;

	useEffect(() => {
		if (data?.length) {
			console.log(data, result);
			const temp = { noChecked: 0, success: 0, notSuccess: 0 };
			data.forEach((d) => {
				if (d.status === 0 || d.status === 1) temp.noChecked++;
				else if (d.status === 2) temp.success++;
				else if (d.status === 3) temp.notSuccess++;
			});
			setStatus(temp);

			setBall((temp.success * 30) / data.length);
		}
	}, [data]);

	const handleChangeStatus = (e) => {
		setLoad(true);
		tasksApi.edit({ status: e ? 2 : 3 }, id).then((res) => {
			if (res.data.success) {
				setShow(false);
				tasksApi
					.checkRight(
						{ limit: 1000, page: 1 },
						{
							organizations: [history.location.search.slice(4)],
							start: periods[selPer]?.start,
							end: periods[selPer]?.end,
							period: periods[selPer]?.id,
						}
					)
					.then((res) => {
						if (res.data.success) {
							setData(res.data.data);
							setLoad(false);
						}
					});
			}
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoad(true);

		const dataItem = {
			code: 14,
			comment: e.target[0].value,
			score: ball,
			organization: history.location.search.slice(4),
		};
		if (!result?.comment && !comment) {
			setComment(true);
			orgApi.mark(dataItem).then((res) => {
				if (res.data.success) {
					setLoad(false);
				}
			});
		} else {
			orgApi.markEdit({ id: result._id, score: ball, comment: e.target[0].value }).then((res) => {
				if (res.data.success) {
					setLoad(false);
				}
			});
		}
	};

	return (
		<div className={st.mark}>
			<Container>
				<div className={ft.table}>
					<table className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'taskSend.taskMeaning')}</td>
								<td className='text-center'>{Translate(lang, 'taskSend.deadline')}</td>
								<td>{Translate(lang, 'global.fileName')}</td>
								<td className='text-center'>{Translate(lang, 'table.file')}</td>
								<td className='text-center'>{Translate(lang, 'taskCheck.sentDate')}</td>
								<td className='text-center'>{Translate(lang, 'taskCheck.executionFile')}</td>
								<td className='text-center'>{Translate(lang, 'orgAll.status')}</td>
								{selPer === 0 && <td className='text-center'>{Translate(lang, 'table.action')}</td>}
							</tr>
						</thead>
						<tbody>
							{!loading && !load ? (
								data.map((d, index) => (
									<tr>
										<td>{index + 1}</td>
										<td>{d.description}</td>
										<td className='text-nowrap text-center'>{BeautyDate(d.deadline)}</td>
										<td>{d.docName}</td>
										<td className='text-nowrap text-center'>
											{d?.files.map((i) => {
												return (
													<div>
														<a
															target='_blank'
															href={`${config.api.baseUrl}${i?.path}`}>
															{i?.path?.slice(15)}
														</a>
													</div>
												);
											})}
										</td>
										<td className='text-nowrap text-center'>
											{d?.complete ? BeautyDate(d?.complete) : '---'}
										</td>
										<td className='text-nowrap text-center'>
											{d.respondFiles.length
												? d?.respondFiles.map((i) => {
														return (
															<div>
																<a
																	target='_blank'
																	href={`${config.api.baseUrl}${i?.path}`}>
																	{i?.path?.slice(15)}
																</a>
															</div>
														);
												  })
												: '---'}
										</td>
										<td className='text-center'>
											{d.status === 0 ? (
												<span className={ft.isNotDone}>
													{Translate(lang, 'home.uncompleted')}
												</span>
											) : d.status === 1 ? (
												<span className={ft.isWaiting}>
													{Translate(lang, 'taskCheck.fileSent')}
												</span>
											) : d.status === 2 ? (
												<span className={ft.isDone}>{Translate(lang, 'homeAll.received')}</span>
											) : (
												<span className={ft.isNotDone}>
													{Translate(lang, 'homeAll.notReceived')}
												</span>
											)}
										</td>
										{/* {selPer === 0 && !!!markTime?.status && ( */}
										{selPer === 0 && (
											<td className='text-center'>
												<button
													className={ft.info__btn}
													disabled={d.status === 0}
													onClick={() => {
														setId(d._id);
														setCheckData(d);
														setShow(true);
													}}>
													{Translate(lang, 'taskCheck.check')}
												</button>
											</td>
										)}
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>
				</div>
			</Container>
			<div className={st.info}>
				<div className={st.info__card}>
					<h6 className={st.info__card__green}>{Translate(lang, 'orgMark.received')}</h6>
					<h5 className={st.info__card__green}>{status.success ? status.success : 0}</h5>
				</div>
				<div className={st.info__card}>
					<h6 className={st.info__card__red}>{Translate(lang, 'orgMark.notMarked')}</h6>
					<h5 className={st.info__card__red}>{status?.noChecked ? status.noChecked : 0}</h5>
				</div>
				<div className={st.info__card}>
					<h6 className={st.info__card__yellow}>{Translate(lang, 'orgMark.notReceived')}</h6>
					<h5 className={st.info__card__yellow}>{status?.notSuccess ? status.notSuccess : 0}</h5>
				</div>

				<div className={st.info__card}>
					<h6 className={st.info__card__all}>{Translate(lang, 'orgMark.all')}</h6>
					<h5 className={st.info__card__all}>
						{status?.success
							? status.success
							: 0 + status?.notSuccess
							? status?.notSuccess
							: 0 + status?.noChecked
							? status?.noChecked
							: 0}
					</h5>
				</div>
			</div>

			{!!show && (
				<TaskCheckModal
					lang={lang}
					show={show}
					setShow={setShow}
					id={id}
					checkData={checkData}
					load={load}
					handleChangeStatus={handleChangeStatus}
				/>
			)}

			<form className='my-3 d-flex' onSubmit={handleSubmit}>
				<div className={st.comment}>
					<label htmlFor='textarea'>
						<h6>{Translate(lang, 'orgMark.comment')}</h6>
					</label>
					<textarea id='textarea' cols='30' rows='10' defaultValue={result?.comment} required />
				</div>

				<div className={st.mark}>
					<p className={st.mark__item}>
						{Math.round(ball * 10) / 10} {Translate(lang, 'orgMark.point')}
					</p>

					{!!data?.length && !!markTime?.status && selPer === 0 && (
						<button
							className={`${ft.save__btn} d-flex align-items-center`}
							type='submit'
							disabled={load ? true : false}>
							{Translate(lang, 'table.mark')} {load ? <Loading size='sm' cl='ml-1' /> : ''}
						</button>
					)}
				</div>
			</form>
		</div>
	);
}
