import Translate from '../lang';

import st from './backButton.module.scss';

const svg = (
	<svg
		width='24'
		height='25'
		viewBox='0 0 24 25'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M19 12.5H5'
			stroke='white'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12 19.5L5 12.5L12 5.5'
			stroke='white'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default function Back(props) {
	const { lang, history } = props;

	return (
		<button className={st.button} onClick={() => history.goBack()}>
			{svg}
			{Translate(lang, 'global.back')}
		</button>
	)
}
