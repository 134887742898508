import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';

import config from './config';

import { authApi } from './services/authService';

import Home from './pages/home';
import Logs from './pages/logs';
import Infos from './pages/infos';
import Tasks from './pages/tasks';
import Login from './pages/login';
import Advices from './pages/advices';
import UserSet from './pages/userSet';
import Experts from './pages/experts';
import OrgList from './pages/orgList';
import OrgTypes from './pages/orgTypes';
import Sessions from './pages/sessions';
import MarkTimes from './pages/markTimes';
import HomeAll from './pages/home/homeAll';
import Statistics from './pages/statistics';
import Suggestion from './pages/suggestion';
import PropagandaDay from './pages/propagandaDay';

import * as Neighborhood from './pages/neighborhood';
import * as DocNormative from './pages/doc/normative';

import Navbar from './components/navbar';
import Header from './components/header';
import Loading from './components/loading';

import CheckRole from './requires/CheckRole';

function App() {
	const history = useHistory();
	const [lang, setLang] = useState('uz');
	const	[isOpen, setIsOpen] = useState(true);
	const	[user, setUser] = useState(null);
	const	[markTime, setMarkTime] = useState(null);
	const	[token, setToken] = useState(null);
	const	[loading, setLoading] = useState(true);
	const	[auth, setAuth] = useState(false);
	const	[markLoading, setMarkLoading] = useState(false);
	const	[title, setTitle] = useState('Huquqiy ');
	const	[periods, setPeriods] = useState([]);
	const	[selPer, setSelPer] = useState(0);
	const	[selPerReyt, setSelPerReyt] = useState(1);
	const	[onlineExp, setOnlineExp] = useState(0);
	const	[onlineUser, setOnlineUser] = useState(0);

	const allProps = {
		api: config.api.baseUrl,
		lang: lang,
		setLang: setLang,
		isOpen: isOpen,
		setIsOpen: setIsOpen,
		token: token,
		setToken: setToken,
		user: user,
		setUser: setUser,
		setAuth: setAuth,
		title: title,
		setTitle: setTitle,
		history: history,
		markTime: markTime,
		setMarkTime: setMarkTime,
		setGlobalLoading: setLoading,
		markLoading: markLoading,
		setMarkLoading: setMarkLoading,
		periods: periods,
		selPer: selPer,
		setSelPer: setSelPer,
		selPerReyt: selPerReyt,
		setSelPerReyt: setSelPerReyt,
		onlineExp,
		onlineUser,
	};

	let socket;

	useEffect(() => {
		socket = io(config.api.baseUrl);
		socket?.emit('login', { userId: 2 });

		socket?.on('usercnt', (data) => {
			setOnlineUser(data);
		});

		socket?.on('expcnt', (data) => {
			setOnlineExp(data);
		});
	}, []);

	useEffect(() => {
		setAuth();
		setLoading(true);

		let temp = sessionStorage.getItem('token');

		if (!!temp || token) {
			authApi
				.userMe(token ? token : temp)
				.then(res => {
					if (res.data.success) {
						setUser(res.data.data);
						setMarkTime(res.data.time);
						setAuth(true);
						if (history.location.pathname === '/login') history.push('/');
					} else {
						setAuth(false);
						setToken({});
						sessionStorage.clear();
						setLoading(false);
					}

					authApi.periods().then(res => {
						if (res.data.success) {
							const DATA = res.data.data,
										temp = [];

							DATA.forEach((d) => {
								let year = d.end.slice(0, 4);
								let month = Number(d.start.slice(5, 7));
								let start = 2;

								if (Number(d.end.slice(5, 7)) === 1) {
									year -= 1;
								}

								if (month === 12 || month === 1) {
									start = 1;
								}

								temp.push({
									start: d.start.slice(0, 10),
									end: d.end.slice(0, 10),
									year,
									half: start,
									id: d._id,
									status: d.status,
								});
							});

							if (DATA.lenght === 1) {
								setSelPerReyt(0);
							}

							setPeriods(temp);

							setLoading(false);
							setMarkLoading(false);
						}
					});
				})
				.catch(() => {
					setAuth(false);
					setToken({});
					sessionStorage.clear();
				});
		} else {
			setLoading(false);
			history.push('/login');
			sessionStorage.clear();
			setAuth(false);
		}
	}, [token, markLoading, selPer]);

	if (loading || markLoading) {
		return (
			<div className='w-100 d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
				<Loading size='lg' big={true} />
			</div>
		);
	}

	return (
		<div className='position-relative'>
			{!auth && !sessionStorage.getItem('token') ? (
				<Switch>
					<Route path='/login'>
						<Login {...allProps} />
					</Route>

					<Route render={() => <Redirect to='/login' />}></Route>
				</Switch>
			) : (
				<div style={{ minHeight: '100vh' }}>
					<Navbar {...allProps} />

					<div
						className={`bg-light position-relative float-right isOpen ${isOpen ? 'isOpen__active' : ''}`}
						style={{ minHeight: '100vh' }}
					>
						<div style={{ padding: '90px 20px 0 30px' }}>
							<Header {...allProps} />

							<Switch>
								<Route
									path='/home/all'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<HomeAll {...allProps} />}
										/>
									}
								/>

								<Route
									path='/suggestions'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Suggestion {...allProps} />}
										/>
									}
								/>

								<Route
									path='/propaganda-days'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<PropagandaDay {...allProps} />}
										/>
									}
								/>

								<Route
									path='/neighborhoods'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Neighborhood.List {...allProps} />}
										/>
									}
									exact
								/>

								<Route
									path='/neighborhoods/create'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Neighborhood.Create {...allProps} />}
										/>
									}
									exact
								/>

								<Route
									path='/neighborhoods/update/:docId'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Neighborhood.Update {...allProps} />}
										/>
									}
									exact
								/>

								<Route
									path='/neighborhoods/monitoring/:docId'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Neighborhood.Monitoring {...allProps} />}
										/>
									}
									exact
								/>

								<Route
									path='/normative-docs/additional-received-monitoring'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<DocNormative.ReceivedMonitoring {...allProps} />}
										/>
									}
								/>

								<Route
									path='/normative-docs/main-received-task-monitoring'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<DocNormative.TaskMonitoring {...allProps} />}
										/>
									}
								/>

								<Route
									path='/normative-docs/docs'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<DocNormative.Docs {...allProps} />}
										/>
									}
								/>

								<Route
									path='/normative-docs/doc-receiveds'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<DocNormative.Receiveds {...allProps} />}
										/>
									}
								/>

								<Route
									path='/normative-docs/doc-tasks'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<DocNormative.Tasks {...allProps} />}
										/>
									}
								/>

								<Route
									path='/normative-docs/create'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<DocNormative.Create {...allProps} />}
										/>
									}
								/>

								<Route
									path='/normative-docs/update/:docId'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<DocNormative.Update {...allProps} />}
										/>
									}
								/>

								<Route
									path='/normative-docs/:docReceivedId/tasks'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<DocNormative.Tasks {...allProps} />}
										/>
									}
								/>

								<Route
									path='/orgList'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<OrgList {...allProps} />}
										/>
									}
								/>

								<Route
									path='/orgTypes'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin']}
											page={<OrgTypes {...allProps} />}
										/>
									}
								/>

								<Route
									path='/statistics'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Statistics {...allProps} />}
										/>
									}
								/>

								<Route
									path='/experts'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin']}
											page={<Experts {...allProps} />}
										/>
									}
								/>

								<Route
									path='/sessions'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin']}
											page={<Sessions {...allProps} />}
										/>
									}
								/>

								<Route
									path='/logs'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin']}
											page={<Logs {...allProps} />}
										/>
									}
								/>

								<Route
									path='/tasks'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Tasks {...allProps} />}
										/>
									}
								/>

								<Route
									path='/advices'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Advices {...allProps} />}
										/>
									}
								/>

								<Route
									path='/markTimes'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin']}
											page={<MarkTimes {...allProps} />}
										/>
									}
								/>

								<Route
									path='/infos'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Infos {...allProps} />}
										/>
									}
								/>

								<Route
									path='/userSet'
									exact
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<UserSet {...allProps} />}
										/>
									}
								/>

								<Route
									path='/'
									render={() =>
										<CheckRole
											role={user?.role}
											accessRoles={['admin', 'expert']}
											page={<Home {...allProps} />}
										/>
									}
								/>
							</Switch>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default App;
