import { useEffect, useState } from 'react'
import CustomModal from '../../../components/customModal'
import ft from '../../../components/mainStyles.module.scss'
import { Form } from 'react-bootstrap'
import Loading from '../../../components/loading'
import UploadFiles from '../../../components/uploadFiles'
import { advicesApi } from '../../../services/advicesService'
import Translate from '../../../components/lang'

export default function EditAdviceModal(props) {
	const [selFiles, setSelFiles] = useState([]),
		[newFiles, setNewFiles] = useState([]),
		[uploadedFiles, setUploadedFiles] = useState([]),
		[prots, setProts] = useState([]),
		[load, setLoad] = useState(false),
		[errFiles, setErrFiles] = useState(false),
		{ lang, id, data, setLoading, setData, show, setShow } = props

	useEffect(() => {
		const DATA = []
		data?.files?.forEach((d) => {
			DATA.push({
				name: d.path.slice(15),
				path: d.path,
				file: d.file,
				_id: d._id,
				success: true,
			})
		})
		setSelFiles(DATA)
		setErrFiles(false)
	}, [show, id])

	const handleSubmit = (e) => {
		e.preventDefault()

		if (uploadedFiles.length || selFiles.length) {
			setErrFiles(false)
			const dataFiles = []
			selFiles.map((f) => {
				if (f.success) dataFiles.push({ file: f._id, path: f.path })
			})
			uploadedFiles.map((f) => dataFiles.push({ file: f._id, path: f.path, size: f.size }))
			const dataSend = {
				organizations: data.organizations._id,
				description: e.target[1].value,
				files: dataFiles,
			}
			setLoad(true)
			advicesApi.edit(dataSend, id).then((res) => {
				if (res.data.success) {
					setLoad(false)
					setLoading(true)
					advicesApi.all({ limit: 10, page: 1 }).then((res) => {
						if (res.data.success) {
							setLoading(false)
							setData(res.data.data)
						}
					})
					setShow(false)
				}
			})
		} else setErrFiles(true)
	}

	const handleDeleteFile = (e) => {
		const temp = window.confirm(`${Translate(lang, 'global.delFile')}`)
		if (temp) {
			setLoad(true)
			advicesApi.delFile(e, data._id).then((res) => {
				if (res.data.success) {
					setSelFiles((prev) => {
						let temp = [...prev]
						return temp.filter((t) => t?._id !== e)
					})
					setLoad(false)
				}
			})
		}
	}

	return (
		<CustomModal {...props} modalTitle={Translate(lang, 'advices.editAdvice')} size='lg'>
			<Form className={`${ft.form} row px-2`} onSubmit={handleSubmit}>
				<div className='col-md-8'>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.orgName')}</div>
						<Form.Control type='text' value={data && data?.organizations?.name[lang]} required />
					</Form.Label>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'taskSend.taskMeaning')}</div>
						<Form.Control as='textarea' defaultValue={data && data?.description} required />
					</Form.Label>
				</div>
				<div className='col-md-4'>
					<UploadFiles {...props} selFiles={selFiles} setSelFiles={setSelFiles} newFiles={newFiles} setNewFiles={setNewFiles} setUploadedFiles={setUploadedFiles} setErrFiles={setErrFiles} load={load} setLoad={setLoad} prots={prots} setProts={setProts} handleDeleteFile={handleDeleteFile} />
					{errFiles && <span className={ft.err__mes}>{Translate(lang, 'global.notUploaded')}</span>}
				</div>
				<div className={ft.modalBtns}>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setShow(false)
							setLoad(false)
						}}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load}>
						{Translate(lang, 'global.save')}
						{load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
