import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import config from '../../../config';

import { docApi } from '../../../services/doc/docService';

import Del from '../../../components/del';
import Pagin from '../../../components/pagin';
import Filter from '../../../components/filter';
import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import Container from '../../../components/container';

import { del, edit } from '../../../components/icons';

import { BeautyFullTime } from '../../../utils';

import ft from '../../../components/mainStyles.module.scss';
import cls from './Normative.module.scss';

const Docs = ({ lang, history }) => {
   const [id, setId] = useState(null);
   const [data, setData] = useState([]);
   const [filter, setFilter] = useState({});
   const [load, setLoad] = useState(false);
   const [loading, setLoading] = useState(true);
   const [showDel, setShowDel] = useState(false);
   const [showFilter, setShowFilter] = useState(false);
   const [pc, setPc] = useState(0);
   const [pageNum, setPageNum] = useState(1);
   const [page, setPage] = useState({ limit: 10, page: 1 });

   const handleDelete = (id) => {
      setLoad(true);

      docApi.delete(id)
         .then(res => {
            if (res.data.success) {
               setShowDel(false);
               getList();
            }
         })
         .catch(err => {
            console.log(err);
         })
         .finally(() => {
            setLoad(false);
         });
   };

   const handleFilter = (e) => {
      e.preventDefault();

      const { type, number, start, end } = e.target.elements;

      const newFilter = {};

      if (type.value !== 'all') newFilter.type = type.value;
      if (number.value) newFilter.number = number.value;
      if (start.value) newFilter.start = start.value;
      if (end.value) newFilter.end = end.value;

      setFilter(newFilter);

      setShowFilter(false);

      setPage({ ...page, page: 1 });
   }

   const getList = () => {
      setLoading(true);

      docApi
         .getList({
            ...page,
            ...filter,
            moduleType: 'NORMATIVE'
         })
         .then(res => {
            if (res.data.success) {
               setPageNum(Math.ceil(res.data.num));
               setPc((page.page - 1) * page.limit);
               setData(res.data.data);
            }
         })
         .catch(err => {
            console.log(err);
         })
         .finally(() => {
            setLoading(false);
         });
   }

   useEffect(() => {
      getList();
   }, [page]);

   return (
      <div>
         <Container>
            <div className={ft.content__head}>
               <h5 className={ft.title}>
                  {Translate(lang, 'navbar.normativeDocs')}
               </h5>

               <div className={ft.btns}>
                  <button
                     className={`${ft.primary__btn} ml-2`}
                     onClick={() => setShowFilter(true)}
                  >
                     {Translate(lang, 'global.filter')}
                  </button>

                  <button
                     className={ft.add__btn}
                     onClick={() => history.push('/normative-docs/create')}
                  >
                     <span className={ft.icon}>+</span>
                     {Translate(lang, 'global.add')}
                  </button>
               </div>
            </div>

            <div className={ft.table}>
               <table id='table' className={ft.table__main}>
                  <thead>
                     <tr>
                        <td>#</td>
                        <td>{Translate(lang, 'doc.number')}</td>
                        <td>{Translate(lang, 'doc.description')}</td>
                        <td>{Translate(lang, 'doc.type')}</td>
                        <td>{Translate(lang, 'table.link')}</td>
                        <td>{Translate(lang, 'table.file')}</td>
                        <td>{Translate(lang, 'table.createdAt')}</td>
                        <td className='text-center'>{Translate(lang, 'table.action')}</td>
                     </tr>
                  </thead>

                  <tbody>
                     {!loading ? (
                        data.map((item, index) => (
                           <tr key={item._id}>
                              <td>{pc + index + 1}</td>
                              <td>{item.number}</td>
                              <td>{item.description}</td>
                              <td>
                                 <div className={cls.type}>
                                    {Translate(lang, `doc.${item.type?.toLowerCase()}`)}
                                 </div>
                              </td>
                              <td>{item.link}</td>
                              <td className='text-nowrap'>
                                 {item?.files.map((file, index) => (
                                    <div key={index}>
                                       <a href={`${config.api.baseUrl}${file?.path}`} target='_blank'>
                                          {file?.path?.slice(15)}
                                       </a>
                                    </div>
                                 ))}
                              </td>
                              <td>
                                 <div style={{ width: 180 }}>{BeautyFullTime(item.createdAt)}</div>
                              </td>
                              <td>
                              <div className='d-flex justify-content-center'>
                                    <button
                                       className={ft.edit__btn}
                                       onClick={() => history.push(`/normative-docs/update/${item._id}`)}
                                    >
                                       {edit}
                                    </button>
                                    <button
                                       className={ft.del__btn}
                                       onClick={() => {
                                          setId(item._id);
                                          setShowDel(true);
                                       }}
                                    >
                                       {del}
                                    </button>
                                 </div>
                              </td>
                           </tr>
                        ))
                     ) : (
                        <tr>
                           <td colSpan={8}>
                              <Loading cl='mt-3' big={true} size='md'/>
                           </td>
                        </tr>
                     )}
                  </tbody>
               </table>

               {!loading && !data.length && (
                  <h6 className={ft.noData}>
                     {Translate(lang, 'table.notFound')}
                  </h6>
               )}

               {!loading && (
                  <Pagin pageNum={pageNum} page={page} setPage={setPage}/>
               )}
            </div>
         </Container>

         <Filter show={showFilter} setShow={setShowFilter}>
            <Form className={ft.form} onSubmit={handleFilter}>
               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'doc.type')}</div>
                  <select name='type' defaultValue={filter.type}>
                     <option value='all'>{Translate(lang, 'global.all')}</option>
                     <option value='DECISION'>{Translate(lang, 'doc.decision')}</option>
                     <option value='DECREE'>{Translate(lang, 'doc.decree')}</option>
                     <option value='ORDER'>{Translate(lang, 'doc.order')}</option>
                  </select>
               </Form.Label>

               <Form.Label>
               <div className={ft.lab}>{Translate(lang, 'doc.number')}</div>
                  <Form.Control name='number' defaultValue={filter.name} type='text' />
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.from')}</div>
                  <Form.Control name='start' defaultValue={filter.start} type='date' />
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.to')}</div>
                  <Form.Control name='end' defaultValue={filter.end} type='date' />
               </Form.Label>

               <button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
                  {Translate(lang, 'global.search')}
               </button>
            </Form>
         </Filter>

         <Del
            id={id}
            lang={lang}
            load={load}
            show={showDel}
            setShow={setShowDel}
            handleDelete={handleDelete}
         />
      </div>
   )
}

export default Docs;