import CustomModal from '../../../../components/customModal'
import ft from '../../../../components/mainStyles.module.scss'
import Translate from '../../../../components/lang'
import ExportPdf from '../../../../components/exportPdf'
import { useEffect, useState } from 'react'
import { tasksApi } from '../../../../services/tasksService'
import { homeApi } from '../../../../services/homeService'
import { BeautyDate, SortReyt } from '../../../../utils'
import { Pie, Cell, PieChart } from 'recharts'
import st from '../../../orgList/orgStat/orgStat.module.scss'
import Loading from '../../../../components/loading'

export default function TaskStatExp(props) {
	const [data, setData] = useState([]),
		[load, setLoad] = useState(true),
		{ filter, selPer, selId, periods, lang, show } = props

	useEffect(() => {
		setLoad(true)
		const DATA = {
			start: filter.start,
			end: filter.end,
			period: periods[selPer].id,
			limit: 10000,
			page: 1,
		}
		if (selId.length) DATA.organization = selId
		tasksApi.statExp(DATA).then((res) => {
			if (res.data.success) {
				const temp = [...res.data.data]
				homeApi
					.all({
						limit: 10000,
						page: 1,
						start: periods[selPer]?.start,
						end: periods[selPer]?.end,
						period: periods[selPer]?.id,
					})
					.then((res) => {
						if (res.data.success) {
							const DATA = SortReyt(res.data.data, res.data.oldData)
							const newData = []

							temp.forEach((t) => {
								const filtData = DATA.filter((d) => d._id === t._id)[0]
								const reyt = DATA.findIndex((f) => f._id === t._id)
								const total = filtData.res?.total ? filtData.res.total : 0
								const right = filtData.res?.right?.length ? filtData.res?.right[0]._id : 0
								const notDone =
									(+filtData.stat.filter((s) => s._id === 0)[0]?.count ? +filtData.stat.filter((s) => s._id === 0)[0]?.count : 0) +
									(+filtData.stat.filter((s) => s._id === 1)[0]?.count ? +filtData.stat.filter((s) => s._id === 1)[0]?.count : 0) +
									(+filtData.stat.filter((s) => s._id === 3)[0]?.count ? +filtData.stat.filter((s) => s._id === 3)[0]?.count : 0)
								const totalTasks =
									(!!filtData.stat.filter((s) => s._id === 0)[0] ? filtData.stat.filter((s) => s._id === 0)[0].count : 0) +
									(!!filtData.stat.filter((s) => s._id === 1)[0] ? filtData.stat.filter((s) => s._id === 1)[0].count : 0) +
									(!!filtData.stat.filter((s) => s._id === 2)[0] ? filtData.stat.filter((s) => s._id === 2)[0].count : 0) +
									(!!filtData.stat.filter((s) => s._id === 3)[0] ? filtData.stat.filter((s) => s._id === 3)[0].count : 0)
								newData.push({
									...t,
									reyt: reyt + 1,
									totalBall: total,
									right: right,
									notDone: notDone,
									totalTasks: totalTasks,
									place: filtData.place,
								})
							})
							setData(newData)
							setLoad(false)
						}
					})
			}
		})
	}, [show])

	return (
		<CustomModal {...props} size='lg'>
			<div className={`${ft.content__head} justify-content-between`}>
				<div className={`${ft.btns} w-100 justify-content-end`}>
					<ExportPdf {...props} title={Translate(lang, 'navbar.statTask')} id='taskStatExp' />
				</div>
			</div>
			<div id='taskStatExp'>
				{!load ? (
					data.map((d) => <TaskStatExpOne {...props} DATA={d} />)
				) : (
					<div className='d-flex justify-content-center'>
						<Loading size='md' cl='text-center' />
					</div>
				)}
			</div>
		</CustomModal>
	)
}

function TaskStatExpOne(props) {
	const [data, setData] = useState([]),
		[dataItem, setDataItem] = useState(),
		[load, setLoad] = useState(true),
		[prots, setProts] = useState(),
		COLORS = ['rgba(239, 93, 168, 1)', 'rgba(239, 93, 168, 0.1)'],
		RADIAN = Math.PI / 180,
		{ lang, DATA } = props

	useEffect(() => {
		const temp = { ...DATA }
		temp.done = DATA.count2.length ? DATA.count2[0].count : 0
		temp.notDone = (DATA.total?.length ? DATA.total[0].count : 0) - temp.done
		temp.bandSuccess = 0
		temp.code = []
		DATA.band.forEach((d) => {
			if ((d.files.length || d?.code > 0) && !temp?.code?.filter((t) => t === d?.code).length) {
				temp.bandSuccess += 1
				temp.code.push(d.code)
			}
		})
		temp.bandNotSuccess = 13 - temp.bandSuccess
		temp.files = [...DATA.stat]

		setData(temp)
		setLoad(false)

		setProts(() => {
			let temp1 = []
			const stat0 = temp.done
			const stat1 = temp.notDone
			const sum = stat0 + stat1
			if (stat0) temp1.push((stat0 * 100) / sum)
			else temp1.push(0)
			if (stat1) temp1.push((stat1 * 100) / sum)
			else temp1.push(0)

			return temp1
		})
		setDataItem([{ value: temp.done }, { value: temp.notDone }])
	}, [])

	// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
	// 	const radius = innerRadius + (outerRadius - innerRadius) * 0.4
	// 	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	// 	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	// 	return (
	// 		<text x={x} y={y} fill='dark' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
	// 			{`${Math.round(percent * 100) === Math.round(prots[0]) ? dataItem[0].value : dataItem[1].value} ta`}
	// 		</text>
	// 	)
	// }

	return !load ? (
		<div className='mb-4' style={{ padding: '15px 10px', border: '1px solid #aaa', borderRadius: '10px' }}>
			<div className={`${ft.content__head} justify-content-between`}>
				<h5 className='m-0'>{data.name[lang]}</h5>
			</div>

			<div>
				<div className={st.items}>
					<div className={st.item}>
						<p className={st.item__title}>{Translate(lang, 'taskCheck.taskNum')}</p>
						{!(dataItem[0].value === 0 && dataItem[1].value === 0) ? (
							<PieChart width={200} height={200}>
								<Pie
									data={dataItem}
									cx='50%'
									cy='50%'
									labelLine={false}
									// label={renderCustomizedLabel}
									outerRadius={70}
									fill='rgba(239, 93, 168, 0.1)'
									dataKey='value'>
									{dataItem.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
									))}
								</Pie>
							</PieChart>
						) : (
							<p className='my-5 text-center'>{Translate(lang, 'taskCheck.noTasks')}</p>
						)}
						<div className={st.chart}>
							<div className={st.chart__info}>
								<div className='d-flex align-items-center'>
									<div className={`${st.circle} ${st.circle__done}`}></div>
									<p>{Translate(lang, 'home.completed')}</p>
								</div>
								<p>{dataItem[0].value}</p>
							</div>
							<div className={st.chart__info}>
								<div className='d-flex align-items-center'>
									<div className={`${st.circle} ${st.circle__cancel}`}></div>
									<p>{Translate(lang, 'home.uncompleted')}</p>
								</div>
								<p>{dataItem[1].value}</p>
							</div>
						</div>
					</div>
					<div className={st.item}>
						<p className={st.item__title}>{Translate(lang, 'homeAll.tableTitle')}</p>
						<div className='mt-3'>
							<h4 className='mb-2' style={{ color: '#5D5FEF' }}>
								#{data?.place} - {Translate(lang, 'table.place')}
							</h4>
							<h6 className='d-flex align-items-center justify-content-between my-3'>
								<span>{Translate(lang, 'homeAll.byTasks')}</span> <span>{Math.round(data?.right * 10) / 10}</span>
							</h6>
							<h6 className='d-flex align-items-center justify-content-between my-3'>
								<span>{Translate(lang, 'homeAll.byEvents')}</span> <span>{Math.round((data?.totalBall - data?.right) * 10) / 10}</span>
							</h6>
							<h6 className='d-flex align-items-center justify-content-between my-3'>
								<span>{Translate(lang, 'table.allBall')}</span>
								<span>{Math.round(data.totalBall * 10) / 10}</span>
							</h6>
						</div>
					</div>
					<div className={st.item}>
						<p className={st.item__title}>{Translate(lang, 'taskCheck.bands')}</p>
						<div className='d-flex flex-column justify-content-between'>
							<div className={`row ${st.task} my-3`}>
								<div className={`col-8 ${st.task__item} ${ft.success__btn}`}>{Translate(lang, 'taskCheck.completed')}</div>
								<div className={`col-3 ${st.task__num} ${ft.success__btn}`}>{data.bandSuccess}</div>
							</div>
							<div className={`row ${st.task} my-3`}>
								<div className={`col-8 ${st.task__item} ${ft.notSuccess__btn}`}>{Translate(lang, 'taskCheck.uncompleted')}</div>
								<div className={`col-3 ${st.task__num} ${ft.notSuccess__btn}`}>{data.bandNotSuccess}</div>
							</div>
							<div className={`row ${st.task} my-3`}>
								<div className={`col-8 ${st.task__item} ${ft.info__btn}`}>{Translate(lang, 'orgMark.all')}</div>
								<div className={`col-3 ${st.task__num} ${ft.info__btn}`}>13</div>
							</div>
						</div>
					</div>
				</div>
				<div className='mt-2'>
					<h5>{Translate(lang, 'navbar.tasks')}</h5>

					<div className={st.table}>
						<div className={st.stat__table}>
							<div className='font-weight-bold' style={{ width: '20%' }}>
								{Translate(lang, 'taskSend.taskMeaning')}
							</div>
							<div className='text-center font-weight-bold' style={{ width: '20%' }}>
								{Translate(lang, 'taskSend.deadline')}
							</div>
							<div className='text-center font-weight-bold' style={{ width: '20%' }}>
								{Translate(lang, 'taskSend.fileName')}
							</div>
							<div className='text-center font-weight-bold' style={{ width: '20%' }}>
								{Translate(lang, 'taskCheck.sentDate')}
							</div>
							<div className='text-center font-weight-bold' style={{ width: '20%' }}>
								{Translate(lang, 'orgAll.status')}
							</div>
						</div>

						{data.files.map((d) => (
							<div className={st.stat__table}>
								<div style={{ width: '20%' }}>{d.description}</div>
								<div className='text-center' style={{ width: '20%' }}>
									{BeautyDate(d.deadline)}
								</div>
								<div className='text-center' style={{ width: '20%' }}>
									{d.docName}
								</div>
								<div className='text-center' style={{ width: '20%' }}>
									{BeautyDate(d.updatedAt)}
								</div>
								<div className='text-center' style={{ width: '20%' }}>
									{d.status === 0 ? (
										<span className={ft.isNotDone}>{Translate(lang, 'home.uncompleted')}</span>
									) : d.status === 1 ? (
										<span className={ft.isWaiting}>{Translate(lang, 'taskCheck.fileSent')}</span>
									) : d.status === 2 ? (
										<span className={ft.isDone}>{Translate(lang, 'homeAll.received')}</span>
									) : (
										<span className={ft.isNotDone}>{Translate(lang, 'homeAll.notReceived')}</span>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	) : null
}
