import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Col, Form, Row } from 'react-bootstrap';

import config from '../../../config';

import { GeneratePassword, ValidatePassword } from '../../../utils';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';
import { UploadImage } from '../../../components/uploadImage';

import ft from '../../../components/mainStyles.module.scss';


export default function OrgAdd(props) {
	const { show, setShow, types, setChange, lang } = props,
		[err, setErr] = useState(false),
		[errPh, setErrPh] = useState(false),
		[load, setLoad] = useState(false),
		[errImg, setErrImg] = useState(false),
		[img, setImg] = useState([]),
		[view, setView] = useState(false),
		[errPass, setErrPass] = useState(false),
		password = useRef('')

	useEffect(() => {
		setImg([]);
	}, [show]);

	const handleSubmit = (e) => {
		e.preventDefault();

		const val = e.target[3].value.match(/^(\+)([998]{3})([\d]{2})(\d+)/);

		if ((val && val[4].length === 7) || e.target[3].value.length === 0) {
			setErrPh(false);
			setErrImg(false);

			if (ValidatePassword(e.target[7].value)) {
				setErrPass(false);
				setLoad(true);

				let fd = new FormData();

				fd.append('name[uz]', e.target[0].value);
				fd.append('name[ru]', e.target[1].value);
				fd.append('organizationType', e.target[2].value);

				if (e.target[2].value.length) fd.append('phoneNumber', e.target[3].value);
				if (e.target[3].value.length) fd.append('legalAddress', e.target[4].value);
				if (e.target[4].value.length) fd.append('email', e.target[5].value);

				fd.append('login', e.target[6].value);
				fd.append('password', e.target[7].value);
				fd.append('status', e.target[8].value);

				if (img.length) fd.append('image', img[0].file);

				axios({
					method: 'POST',
					url: `${config.api.baseUrl}/api/organizations/create`,
					data: fd,
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				})
					.then(res => {
						if (res.data.success) {
							setImg([]);

							setChange(prev => !prev);

							setShow(false);
						}
					})
					.catch(err => {
						console.log(err.message);
						setErr(true);
					})
					.finally(() => {
						setLoad(false);
					});
			} else {
				setErrPass(true);
			}
		} else {
			setErrPh(true);
		}
	}

	return (
		<CustomModal {...props} modalTitle={Translate(lang, 'orgAdd.title')} size='lg'>
			<div>
				<Form className={ft.form} onSubmit={handleSubmit}>
					<Row>
						<Col md={8}>
							<Form.Label>
								<div className={ft.lab}>{Translate(lang, 'global.orgName')} (Uzb)</div>
								<Form.Control type='text' required />
							</Form.Label>

							<Form.Label>
								<div className={ft.lab}>{Translate(lang, 'global.orgName')} (Рус)</div>
								<Form.Control type='text' required />
							</Form.Label>

							<Form.Label className='m-0'>
								<div className={ft.lab}>{Translate(lang, 'orgList.orgType')}</div>
								<select className='mb-2'>
									{types.map(type => (
										<option value={type._id} key={type._id}>{type.name[lang]}</option>
									))}
								</select>
							</Form.Label>

							<Form.Label>
								<div className={ft.lab}>{Translate(lang, 'global.phoneNumber')}</div>
								<Form.Control
									type='phone'
									style={errPh ? { backgroundColor: 'rgba(255, 82, 82, 0.1)' } : {}}
								/>
								{errPh ? <span className={ft.err__mes}>{Translate(lang, 'orgAdd.incorrectPhoneNumber')}</span> : ''}
							</Form.Label>

							<Form.Label>
								<div className={ft.lab}>{Translate(lang, 'orgList.address')}</div>
								<Form.Control type='text' />
							</Form.Label>

							<Form.Label>
								<div className={ft.lab}>{Translate(lang, 'orgList.email')}</div>
								<Form.Control type='email' />
							</Form.Label>

							<Row>
								<Col md={6}>
									<Form.Label>
										<div className={ft.lab}>{Translate(lang, 'global.login')}</div>
										<Form.Control
											type='text'
											required
											style={err ? { backgroundColor: 'rgba(255, 82, 82, 0.1)' } : {}}
										/>
									</Form.Label>
								</Col>

								<Col md={6}>
									<Form.Label>
										<div className={`${ft.lab} d-flex justify-content-between`}>
											{Translate(lang, 'login.pass')}
											<span
												style={{ cursor: 'pointer' }}
												onClick={() => {
													password.current.value = GeneratePassword()
												}}>
												{Translate(lang, 'global.genPass')}
											</span>
										</div>
										<div className='position-relative'>
											<Form.Control ref={password} minLength={8} type={view ? 'text' : 'password'} required />
											<i
												className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
												style={{
													top: '50%',
													right: 0,
													transform: 'translate(-10px, -8px)',
													cursor: 'pointer',
												}}
												onClick={() => setView(!view)}></i>
										</div>
									</Form.Label>
								</Col>
							</Row>

							<Form.Label className='m-0'>
								<div className={ft.lab}>{Translate(lang, 'global.status')}</div>
								<select className='mb-2'>
									<option value={0}>{Translate(lang, 'global.active')}</option>
									<option value={1}>{Translate(lang, 'global.inactive')}</option>
								</select>
							</Form.Label>

							{err ? <span className={ft.err__mes}>{Translate(lang, 'orgList.errMs')}</span> : ''}
							{errPass ? <span className={ft.err__mes}>{Translate(lang, 'global.errPass')}</span> : ''}
						</Col>

						<Col md={4}>
							<UploadImage img={img} setImg={setImg} lang={lang} />
							{errImg ? <span className={ft.err__mes}>{Translate(lang, 'orgAdd.uploadI')}</span> : null}
						</Col>
					</Row>

					<Row className='mt-3 justify-content-end'>
						<button
							className={ft.close__btn}
							type='button'
							onClick={() => {
								setShow(false)
								setLoad(false)
								setErrPh(false)
							}}>
							{Translate(lang, 'global.cancel')}
						</button>

						<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load}>
							{Translate(lang, 'global.save')} {load ? <Loading size='sm' cl='ml-1' /> : ''}
						</button>
					</Row>
				</Form>
			</div>
		</CustomModal>
	)
}
