import ReactToPrint from 'react-to-print';

import { exp } from '../icons';

import Translate from '../lang';

import ft from '../mainStyles.module.scss';

export default function ExportPdf({ id, title, lang }) {
	return (
		<ReactToPrint
			documentTitle={title}
			trigger={() => (
				<button className={ft.export__btn}>
					<span className='mr-1'>{Translate(lang, 'global.export')}</span> {exp}
				</button>
			)}
			content={() => document.querySelector(`#${id}`)}
		/>
	)
}
