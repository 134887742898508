import CustomModal from '../../../../components/customModal'

export default function OrgMarkModal(props) {
	const { orgInfo } = props
	return (
		<CustomModal {...props}>
			<p style={{ color: '#232323', wordBreak: 'break-word' }}>{orgInfo?.description?.length ? orgInfo?.description : '---'}</p>
		</CustomModal>
	)
}
