import { useEffect, useState } from 'react'
import ft from '../../../components/mainStyles.module.scss'
import CustomModal from '../../../components/customModal'
import { Form } from 'react-bootstrap'
import Loading from '../../../components/loading'
import CustomSelect from '../../../components/customSelect'
import UploadFiles from '../../../components/uploadFiles'
import { advicesApi } from '../../../services/advicesService'
import Translate from '../../../components/lang'

export default function AddAdviceModal(props) {
	const [newFiles, setNewFiles] = useState([]),
		[uploadedFiles, setUploadedFiles] = useState([]),
		[prots, setProts] = useState([]),
		[selected, setSelected] = useState(null),
		[load, setLoad] = useState(false),
		[errSel, setErrSel] = useState(false),
		[errFiles, setErrFiles] = useState(false),
		{ lang, setLoading, options, setData, show, setShow } = props

	useEffect(() => {
		setSelected(null)
		setProts([])
		setNewFiles([])
		setUploadedFiles([])
	}, [show])

	function handleChange(selected) {
		if (selected.filter((s) => s.value == 'all').length) setSelected(options)
		else setSelected(selected)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (selected?.length) {
			setErrSel(false)

			if (uploadedFiles.length) {
				setErrFiles(false)
				const selOrgs = [],
					file = []
				if (!!selected.filter((s) => s.value === 'all').length) {
					options.forEach((op) => selOrgs.push(op.value))
				} else {
					selected.map((s) => {
						selOrgs.push(s.value)
					})
				}
				uploadedFiles.map((f) => {
					file.push({ file: f._id, path: f.path, size: f.size })
				})
				const data = {
					organizations: selOrgs,
					description: e.target[1].value,
					files: file,
				}
				setLoad(true)
				advicesApi.add(data).then((res) => {
					if (res.data.success) {
						setLoad(false)
						setLoading(true)
						advicesApi.all({ limit: 10, page: 1 }).then((res) => {
							if (res.data.success) {
								setLoading(false)
								setData(res.data.data)
							}
						})
						setSelected([])
						setShow(false)
					}
				})
			} else setErrFiles(true)
		} else setErrSel(true)
	}
	return (
		<CustomModal {...props} modalTitle={Translate(lang, 'advices.addAdvice')} size='lg'>
			<Form className={`${ft.form} row px-2`} onSubmit={handleSubmit}>
				<div className='col-md-8'>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.orgName')}</div>
						<CustomSelect lang={lang} selected={selected} isAdd={true} handleChange={handleChange} options={options} setErrSel={setErrSel} />
						{errSel && <span className={ft.err__mes}>{Translate(lang, 'global.chooseOrg')}</span>}
					</Form.Label>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'advices.theme')}</div>
						<Form.Control as='textarea' required />
					</Form.Label>
				</div>
				<div className='col-md-4'>
					<UploadFiles {...props} newFiles={newFiles} setNewFiles={setNewFiles} setErrFiles={setErrFiles} setUploadedFiles={setUploadedFiles} load={load} setLoad={setLoad} prots={prots} setProts={setProts} />
					{errFiles && <span className={ft.err__mes}>{Translate(lang, 'global.notUploaded')}</span>}
				</div>
				<div className={ft.modalBtns}>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setSelected([])
							setShow(false)
							setLoad(false)
						}}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load ? true : false}>
						{Translate(lang, 'global.save')}
						{load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
