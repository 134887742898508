import React from 'react';
import { Route } from 'react-router-dom';

import TasksSend from './tasksSend';
import TasksCheck from './tasksCheck';
import TasksStat from './tasksStat';

export default function Tasks(props) {
	return (
		<div>
			<Route path='/tasks/check' exact render={() => <TasksCheck {...props} />} />
			<Route path='/tasks/send' exact render={() => <TasksSend {...props} />} />
			<Route path='/tasks/stat' render={() => <TasksStat {...props} />} />
		</div>
	)
}
