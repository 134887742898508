import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Button } from 'react-bootstrap';

import { chevron } from '../icons';

import Translate from '../lang';

import st from './header.module.scss';
import ft from '../navbar/navbar.module.scss';

export default function Header(props) {
	const { lang, setLang, userIcon, user, setAuth, setToken, isOpen, periods, selPer, setSelPer } = props;
	const	[langOpen, setLangOpen] = useState(false);
	const	[userOpen, setUserOpen] = useState(false);
	const	[isCollapsed, setIsCollapsed] = useState(false);

	const uz = (
			<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 15.9984H24V24H0V15.9984Z' fill='#1EB53A' />
				<path d='M0 0H24V8.00156H0V0Z' fill='#0099B5' />
				<path d='M0 7.6781H24V16.3219H0V7.6781Z' fill='#CE1126' />
				<path d='M0 8.15625H24V15.8438H0V8.15625Z' fill='white' />
				<path
					d='M6.72187 6.71719C8.31142 6.71719 9.6 5.42861 9.6 3.83906C9.6 2.24952 8.31142 0.960938 6.72187 0.960938C5.13233 0.960938 3.84375 2.24952 3.84375 3.83906C3.84375 5.42861 5.13233 6.71719 6.72187 6.71719Z'
					fill='white'
				/>
				<path
					d='M7.67817 6.71719C9.26772 6.71719 10.5563 5.42861 10.5563 3.83906C10.5563 2.24952 9.26772 0.960938 7.67817 0.960938C6.08863 0.960938 4.80005 2.24952 4.80005 3.83906C4.80005 5.42861 6.08863 6.71719 7.67817 6.71719Z'
					fill='#0099B5'
				/>
				<path d='M13.0547 5.56464L12.8723 6.11184L13.1507 6.20784' fill='white' />
			</svg>
		);

	const	ru = (
			<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path fillRule='evenodd' clipRule='evenodd' d='M0 0H24V24H0V0Z' fill='white' />
				<path fillRule='evenodd' clipRule='evenodd' d='M0 8.00146H24V23.9999H0V8.00146Z' fill='#0039A6' />
				<path fillRule='evenodd' clipRule='evenodd' d='M0 15.9985H24V24.0001H0V15.9985Z' fill='#D52B1E' />
			</svg>
		);

	const	profile = (
			<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21'
					stroke='#07A287'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z'
					stroke='#07A287'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		);

	return (
		<div className={`${st.header} ${isOpen ? st.header__active : ''}`}>
			<h5 className='m-0' style={{ fontWeight: 500 }}>
				{Translate(lang, 'header.title')}
			</h5>

			<div className='d-flex align-items-center'>
				<div className={st.phone}>
					<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M20.9999 15.9201V18.9201C21.0011 19.1986 20.944 19.4743 20.8324 19.7294C20.7209 19.9846 20.5572 20.2137 20.352 20.402C20.1468 20.5902 19.9045 20.7336 19.6407 20.8228C19.3769 20.912 19.0973 20.9452 18.8199 20.9201C15.7428 20.5857 12.7869 19.5342 10.1899 17.8501C7.77376 16.3148 5.72527 14.2663 4.18993 11.8501C2.49991 9.2413 1.44818 6.27109 1.11993 3.1801C1.09494 2.90356 1.12781 2.62486 1.21643 2.36172C1.30506 2.09859 1.4475 1.85679 1.6347 1.65172C1.82189 1.44665 2.04974 1.28281 2.30372 1.17062C2.55771 1.05843 2.83227 1.00036 3.10993 1.0001H6.10993C6.59524 0.995321 7.06572 1.16718 7.43369 1.48363C7.80166 1.80008 8.04201 2.23954 8.10993 2.7201C8.23656 3.68016 8.47138 4.62282 8.80993 5.5301C8.94448 5.88802 8.9736 6.27701 8.89384 6.65098C8.81408 7.02494 8.6288 7.36821 8.35993 7.6401L7.08993 8.9101C8.51349 11.4136 10.5864 13.4865 13.0899 14.9101L14.3599 13.6401C14.6318 13.3712 14.9751 13.1859 15.3491 13.1062C15.723 13.0264 16.112 13.0556 16.4699 13.1901C17.3772 13.5286 18.3199 13.7635 19.2799 13.8901C19.7657 13.9586 20.2093 14.2033 20.5265 14.5776C20.8436 14.9519 21.0121 15.4297 20.9999 15.9201Z'
							stroke='#232323'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>

					<p className='text-nowrap'>+998 71 207 04 38 </p>
				</div>

				<div className={st.select}>
					<div
						className={`${st.selected} ${langOpen ? st.selected__active : ''}`}
						onClick={() => {
							setLangOpen(!langOpen);
							setUserOpen(false);
						}}
					>
						<div className={st.lang__checked}>{lang === 'ru' ? ru : uz}</div>
						<span className='ml-2' style={langOpen ? { fontWeight: 600 } : {}}>
							{lang === 'ru' ? 'Рус' : lang === 'uz' ? 'Uzb' : 'Узб'}
						</span>
						{chevron(null, st.chevron, st.chevron__active, langOpen, '000')}
					</div>

					<div className={`${st.select__list} ${langOpen ? st.select__list__active : ''}`} style={{ width: '100%' }}>
						{lang === 'ru' ? (
							<div>
								<div
									className={st.select__list__item}
									onClick={() => {
										setLang('uz');
										setLangOpen(false);
									}}
									style={{
										borderBottom: '1px solid #DDEBFF',
									}}
								>
									<div className={st.lang__checked}>{uz}</div>
									<span className='ml-2'>Uzb</span>
								</div>
								<div
									className={st.select__list__item}
									onClick={() => {
										setLang('oz');
										setLangOpen(false);
									}}
								>
									<div className={st.lang__checked}>{uz}</div>
									<span className='ml-2'>Узб</span>
								</div>
							</div>
						) : lang === 'uz' ? (
							<div>
								<div
									className={st.select__list__item}
									onClick={() => {
										setLang('ru');
										setLangOpen(false);
									}}
									style={{
										borderBottom: '1px solid #DDEBFF',
									}}
								>
									<div className={st.lang__checked}>{ru}</div>
									<span className='ml-2'>Рус</span>
								</div>

								<div
									className={st.select__list__item}
									onClick={() => {
										setLang('oz');
										setLangOpen(false);
									}}
								>
									<div className={st.lang__checked}>{uz}</div>
									<span className='ml-2'>Узб</span>
								</div>
							</div>
						) : (
							<div>
								<div
									className={st.select__list__item}
									onClick={() => {
										setLang('ru');
										setLangOpen(false);
									}}
									style={{
										borderBottom: '1px solid #DDEBFF',
									}}
								>
									<div className={st.lang__checked}>{ru}</div>
									<span className='ml-2'>Рус</span>
								</div>

								<div
									className={st.select__list__item}
									onClick={() => {
										setLang('uz');
										setLangOpen(false);
									}}
								>
									<div className={st.lang__checked}>{uz}</div>
									<span className='ml-2'>Uzb</span>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className={st.select}>
					<div
						className={`${st.selected} ${userOpen ? st.selected__active : ''}`}
						onClick={() => {
							setUserOpen(!userOpen);
							setLangOpen(false);
						}}
					>
						<div className={st.lang__checked}>{userIcon ? userIcon : profile}</div>
						<span className='ml-2' style={userOpen ? { fontWeight: 600 } : {}}>
							{user ? (user.fullName.length > 30 ? `${user.fullName.slice(0, 30)}...` : user.fullName) : ''}
						</span>
						{chevron(null, st.chevron, st.chevron__active, userOpen, '000')}
					</div>

					<div className={`${st.select__list} ${userOpen ? st.select__list__active : ''}`} style={{ width: '100%' }}>
						<div>
							<div
								className={st.select__list__item}
								onClick={() => {
									setUserOpen(false);
								}}
								style={{
									borderBottom: '1px solid #DDEBFF',
								}}
							>
								<Link to='/userSet' className='w-100'>
									<span className='ml-2' style={{ fontSize: '18px' }}>
										{Translate(lang, 'header.profile')}
									</span>
								</Link>
							</div>

							<div>
								<Accordion>
									<Accordion.Toggle
										as={Button}
										variant='link'
										eventKey='0'
										className={`${st.select__list__item} w-100 px-2 d-flex justify-content-between`}
										onClick={() => setIsCollapsed(!isCollapsed)}
									>
										<div>
											<span style={{ lineHeight: '14px', fontSize: '18px' }}>{Translate(lang, 'header.period')}</span>
										</div>
										<div className={st.acc__icon}>{chevron(null, ft.accordion__icon, ft.accordion__icon__active, isCollapsed, 'fff')}</div>
									</Accordion.Toggle>

									<Accordion.Collapse eventKey='0'>
										<>
											{periods.map((p, i) => (
												<div
													className={`${st.acc__items} ${i === +selPer ? st.acc__items__active : ''}`}
													onClick={() => {
														sessionStorage.setItem('period', i);
														setSelPer(i);
													}}
													key={i}
												>
													{
														`
															${p.year}-${Translate(lang, 'time.year')} 
															${p.half}-${Translate(lang, 'time.half')}
														`
													}
												</div>
											))}
										</>
									</Accordion.Collapse>
								</Accordion>
							</div>

							<div
								className={st.select__list__item}
								onClick={() => {
									setUserOpen(false);
									setToken(null);
									setAuth(null);
									sessionStorage.removeItem('token');
								}}>
								<span className='ml-2' style={{ color: '#FF5252', fontSize: '18px' }}>
									{Translate(lang, 'header.logout')}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
