import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useUrlSearchParams } from "use-url-search-params";

import { SortReyt } from '../../../utils';

import { exp, chevronDown, chevronUp, equal } from '../../../components/icons';

import Pagin from '../../../components/pagin';
import Limit from '../../../components/limit';
import Circle from '../../../components/circle';
import Filter from '../../../components/filter';
import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import Back from '../../../components/backButton';
import Container from '../../../components/container';
import CustomSelect from '../../../components/customSelect';

import HomeAllModal from './homeAllModal';

import { homeApi } from '../../../services/homeService';
import { orgApi } from '../../../services/orgListService';
import { orgTypesApi } from "../../../services/orgTypesService";

import ft from '../../../components/mainStyles.module.scss';

export default function HomeAll(props) {
	const [data, setData] = useState([]),
		[allData, setAllData] = useState([]),
		[options, setOptions] = useState([]),
		[selected, setSelected] = useState([]),
		[orgTypes, setOrgTypes] = useState([]),
		[showFilter, setShowFilter] = useState(false),
		[showHomeAllModal, setShowHomeAllModal] = useState(false),
		[filter, setFilter] = useState(),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[loading, setLoading] = useState(true),
		{ lang, periods, selPer } = props;

	const [params] = useUrlSearchParams();

	const organizationType = params.organizationType ? String(params.organizationType) : '';

	function getList(payload) {
		homeApi
			.ratings(payload)
			.then(res => {
				if (res.data.success) {
					let DATA = [];
					const temp = [];

					if (res.data.data && res.data.data.length) {
						DATA = SortReyt(res.data.data, res.data.oldData);
					}

					DATA.forEach(d => {
						temp.push({
							id: d._id,
							org: d?.organization[0]?.name,
							type: d?.organizationType[0]?.name,
							total: d?.ball?.all ? d?.ball?.all : 0,
							left: d?.ball?.occasion ? d?.ball?.occasion : 0,
							right: d?.ball?.assignment ? d?.ball?.assignment : 0,
							res: d.res,
							placeIcon: d.placeIcon
						});
					});

					setAllData(temp);
					setData(temp.slice(page.page * page.limit - page.limit, page.page * page.limit));
					setPageNum(Math.ceil(temp.length / page.limit));
					setPc((page.page - 1) * page.limit);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const handleFilter = (e) => {
		e.preventDefault();

		setLoading(true);

		const selId = [];

		selected.map(s => {
			selId.push(s.value);
		});

		const payload = {
			page: 1,
			limit: 10000,
			start: periods[e.target[2].value].start,
			end: periods[e.target[2].value].end,
			period: periods[e.target[2].value]?.id
		};

		if (selId.length) {
			payload.organization = selId;
		}

		if (e.target[1].value !== 'all') {
			payload.type = e.target[1].value;
		}

		setFilter(payload);
		setShowFilter(false);

		getList(payload);
	};

	useEffect(() => {
		setFilter({
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?.id
		});

		orgApi.getAll()
			.then(res => {
				if (res.data.success) {
					const DATA = [];

					res.data.data.map((d) => {
						DATA.push({
							value: d._id,
							label: d.name.uz
						});
					});

					setOptions(DATA);
				}
			})
			.catch(e => {
				console.log(e);
			});

		orgTypesApi.allActive()
			.then(res => {
				setOrgTypes(res.data.data);
			})
			.catch(e => {
				console.log(e);
			});
	}, []);

	useEffect(() => {
		const payload = {
			page: 1,
			limit: 10000,
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?.id,
			...filter
		};

		if (organizationType !== 'all') {
			payload.type = organizationType;
		}

		getList(payload);
	}, [page]);

	return (
		<div>
			<div className='d-flex align-items-center mb-3'>
				<Back {...props} />
				<h5 className='m-0' style={{ color: '#07A287', fontWeight: 500 }}>
					{Translate(lang, 'homeAll.tableTitle')}
				</h5>
			</div>

			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'homeAll.tableTitle')}</h5>
					<div className={ft.btns}>
						<button className={ft.export__btn} onClick={() => setShowHomeAllModal(true)}>
							<span className='mr-1'>{Translate(lang, 'global.export')}</span> {exp}
						</button>
						<Limit {...props} setPage={setPage} />
						<button className={`${ft.primary__btn} ml-2`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button>
					</div>
				</div>

				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'orgList.orgName')}</td>
								<td>{Translate(lang, 'orgList.orgType')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.byEvents')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.byTasks')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.allPoints')}</td>
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, index) => (
									<tr key={index}>
										<td>{pc + index + 1}</td>
										<td>
											{d.placeIcon === 'up' ? chevronUp : d.placeIcon === 'down' ? chevronDown : equal}
											{d.org ? d.org[lang] : null}
										</td>
										<td>
											{d.type ? d.type[lang] : null}
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle
													num={Math.round(d.left * 10) / 10}
													col='88, 85, 214'
													cl='table'
												/>
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle
													num={Math.round(d.right * 10) / 10}
													col='239, 93, 168'
													cl='table'
												/>
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle
													num={Math.round(d.total * 10) / 10}
													col='7, 162, 135'
													cl='table'
												/>
											</div>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={6}>
										<Loading big={true} size='md' cl='mt-3' />
									</td>
								</tr>
							)}
						</tbody>
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			{!!showHomeAllModal && (
				<HomeAllModal {...props} show={showHomeAllModal} setShow={setShowHomeAllModal} data={allData} />
			)}

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'orgList.orgName')}</div>
						<CustomSelect lang={lang} selected={selected} handleChange={v => setSelected(v)} options={options} />
					</Form.Label>

					<select className='my-2'>
						<option value='all'>{Translate(lang, 'global.all')}</option>
						{orgTypes.map(orgType => (
							<option value={orgType._id} key={orgType._id}>
								{orgType.name[lang]}
							</option>
						))}
					</select>

					<select className='my-2'>
						{periods.map((p, i) => (
							<option value={i} key={i}>
								{`${p.year}-${Translate(lang, 'time.year')} ${p.half}-${Translate(lang, 'time.half')}`}
							</option>
						))}
					</select>

					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'orgList.search')}
					</button>
				</Form>
			</Filter>
		</div>
	);
}
