import st from './cardItem.module.scss'
import Circle from '../../../../components/circle'
export default function CardItem({ title, num, col, data }) {
	return (
		<div className={`${st.cardItem} ${data.ard ? 'w-50' : ''}`}>
			<Circle cl='home' num={num} col={col} bgCol='255, 255, 255' />
			<p>{title}</p>
		</div>
	)
}
