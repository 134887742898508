import React, { useEffect, useState } from 'react';
import { Link, Route } from 'react-router-dom';

import { chevronDown, chevronUp, equal } from '../../components/icons';

import { BeautyDate, SortReyt } from '../../utils';

import { homeApi } from '../../services/homeService';
import { orgTypesApi } from '../../services/orgTypesService';
import { markTimesApi } from '../../services/markTimesService';

import Circle from '../../components/circle';
import Translate from '../../components/lang';
import Loading from '../../components/loading';

import HomeAll from './homeAll';
import HomeStat from './homeStat';
import CardItem from './homeCard/cardItem';

import st from './home.module.scss';
import ht from './homeCard/homeCard.module.scss';
import ft from '../../components/mainStyles.module.scss';

export default function Home(props) {
	const [loading, setLoading] = useState(true),
		[data, setData] = useState([]),
		[home, setHome] = useState(),
		[homeData, setHomeData] = useState(),
		[selectedType, setSelectedType] = useState('all'),
		{ periods, selPer, token } = props;

	useEffect(() => {
		const filterData = {
			page: 1,
			limit: 10000,
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?.id
		};

		if (selectedType !== 'all') {
			filterData.type = selectedType;
		}

		homeApi
			.ratings(filterData, token)
			.then(res => {
				if (res.data.success) {
					if (res.data.data && res.data.data.length) {
						const DATA = SortReyt(res.data.data, res.data.oldData);
						setData(DATA.slice(0, 10));
					} else {
						setData([]);
					}
				}
			})
			.catch(e => {
				console.log(e);
			});

		homeApi
			.all(filterData, token)
			.then(res => {
				if (res.data.success) {
					const temp = [];
					const DATAS = res.data.data;

					DATAS.forEach((d) => {
						temp.push({
							org: d.name,
							notDone:
								(+d.stat.filter((s) => s._id === 0)[0]?.count ? +d.stat.filter((s) => s._id === 0)[0]?.count : 0) +
								(+d.stat.filter((s) => s._id === 1)[0]?.count ? +d.stat.filter((s) => s._id === 1)[0]?.count : 0) +
								(+d.stat.filter((s) => s._id === 3)[0]?.count ? +d.stat.filter((s) => s._id === 3)[0]?.count : 0),
							total:
								(!!d.stat.filter((s) => s._id === 0)[0] ? d.stat.filter((s) => s._id === 0)[0].count : 0) +
								(!!d.stat.filter((s) => s._id === 1)[0] ? d.stat.filter((s) => s._id === 1)[0].count : 0) +
								(!!d.stat.filter((s) => s._id === 2)[0] ? d.stat.filter((s) => s._id === 2)[0].count : 0) +
								(!!d.stat.filter((s) => s._id === 3)[0] ? d.stat.filter((s) => s._id === 3)[0].count : 0),
						});
					});

					setHome(temp.sort((a, b) => b.notDone - a.notDone).slice(0, 10));

					homeApi
						.home({
							start: periods[selPer]?.start,
							end: periods[selPer]?.end,
							period: periods[selPer]?.id
						})
						.then(res => {
							if (res.data.success) {
								const DATA = res.data;
								setHomeData({ org: DATA.org, exp: DATA.exp, count: DATA.count, count1: DATA.count - DATA.count1 });
								setLoading(false);
							}
						});
				}
			});
	}, [selectedType]);

	return !loading ? (
		<>
			<Route
				path='/home/all'
				exact render={() => <HomeAll {...props} />}
			/>
			<Route
				path='/home/stat'
				exact render={() => <HomeStat {...props} />}
			/>
			<Route
				path='/'
				exact render={() =>
					<HomeMain
						{...props}
						home={home}
						data={data}
						homeData={homeData}
						selectedType={selectedType}
						setSelectedType={setSelectedType}
					/>
				}
			/>
		</>
	) : (
		<div className='w-100 d-flex align-items-center justify-content-center' style={{ height: 'calc(100vh - 90px)' }}>
			<Loading size='lg' big={true} />
		</div>
	)
}

function HomeMain(props) {
	const { lang, data, user, homeData, home, selectedType, setSelectedType,
		markTime, setMarkLoading, onlineUser, onlineExp, selPer } = props;
	const [orgTypes, setOrgTypes] = useState([]);

	const Progress = (props) => {
		const { finish, color } = props;
		const [prog, setProg] = useState(0);

		function progres() {
			let temp = 0;

			const g = setInterval(() => {
				setProg(temp);
				temp++;

				if (temp > finish) {
					setProg(Math.round(finish * 10) / 10);
					clearInterval(g);
				}
			}, 10);
		}

		useEffect(() => {
			progres();
		}, []);

		return (
			<div className={st.progress} style={{ backgroundColor: '#eee' }}>
				<div className={st.progress__bar} style={{ width: `${prog}%`, backgroundColor: `${color}` }}>
					<div className={st.progress__value} style={{ backgroundColor: `${color}` }}>
						<span>{prog}</span>
					</div>
				</div>
			</div>
		)
	}

	useEffect(() => {
		orgTypesApi.allActive()
			.then(res => {
				setOrgTypes(res.data.data);
			})
			.catch(e => {
				console.log(e);
			});
	}, []);

	const handleStopMark = (id) => {
		let temp = window.confirm(Translate(lang, 'global.stopMark'))
		if (temp) {
			markTimesApi.stopMark(id).then((res) => {
				if (res.data.success) {
					setMarkLoading((prev) => !prev)
				}
			})
		}
	}

	return (
		<div className={st.home}>
			<div className={st.home__info}>
				<div className={ht.homeCard} style={{ backgroundColor: `rgb(106, 105, 199)` }}>
					<h6 className={ht.homeCard__title}>{Translate(lang, 'home.orgCount')}</h6>
					<div className={`d-flex ${markTime?.status === 1 ? 'pt-3' : 'pt-2'} px-1 justify-content-around`}>
						<CardItem {...props} title={Translate(lang, 'home.online')} num={onlineUser} col='106, 105, 199' />
						<CardItem {...props} title={Translate(lang, 'home.all')} num={homeData.org} col='106, 105, 199' />
					</div>
				</div>
				<div className={ht.homeCard} style={{ backgroundColor: `rgb(255, 175, 111)` }}>
					<h6 className={ht.homeCard__title}>{Translate(lang, 'home.expertCount')}</h6>
					<div className={`d-flex ${markTime?.status === 1 ? 'pt-3' : 'pt-2'} px-1 justify-content-around`}>
						<CardItem {...props} title={Translate(lang, 'home.online')} num={onlineExp} col='255, 175, 111' />
						<CardItem {...props} title={Translate(lang, 'home.all')} num={homeData.exp} col='255, 175, 111' />
					</div>
				</div>
				<div className={ht.homeCard} style={{ backgroundColor: `rgb(255, 128, 139)` }}>
					<h6 className={ht.homeCard__title}>{Translate(lang, 'navbar.tasks')}</h6>
					<div className={`d-flex ${markTime?.status === 1 ? 'pt-3' : 'pt-2'} px-1 justify-content-between`}>
						<CardItem {...props} title={Translate(lang, 'home.completed')} num={homeData.count - homeData.count1} col='255, 128, 139' />
						<CardItem {...props} title={Translate(lang, 'home.uncompleted')} num={homeData.count1} col='255, 128, 139' />
						<CardItem {...props} title={Translate(lang, 'home.all')} num={homeData.count} col='255, 128, 139' />
					</div>
				</div>
				<div className={ht.homeCard} style={{ backgroundColor: 'rgb(69, 123, 157)' }}>
					<h6 className={ht.homeCard__title}>{Translate(lang, 'navbar.tdate')}</h6>
					{selPer === 0 && markTime?.status === 1 ? (
						<div className='text-center'>
							<div className={ht.homeCard__time}>
								<span>{Translate(lang, 'markTimes.initialDate')}:</span> <span>{BeautyDate(markTime.startTime)}</span>
							</div>
							<div className={ht.homeCard__time}>
								<span>{Translate(lang, 'markTimes.endDate')}:</span> <span>{BeautyDate(markTime.endTime)}</span>
							</div>
							{user?.role === 'admin' && (
								<button className={`${ht.homeCard__btn} ${ht.homeCard__stop__btn}`} onClick={() => handleStopMark(markTime._id)}>
									{Translate(lang, 'markTimes.toHomeEnd')}
								</button>
							)}
						</div>
					) : (
						<div className='text-center px-3 mt-3'>
							{user?.role === 'admin' ? (
								<Link to='/markTimes'>
									<button className={ht.homeCard__btn}>{Translate(lang, 'home.startMark')}</button>
								</Link>
							) : (
								<button className={ht.homeCard__btn}>{Translate(lang, 'home.notMarkTime')}</button>
							)}
						</div>
					)}
				</div>
			</div>

			<div className={st.home__body}>
				<div className={st.body__card}>
					<div className='d-flex justify-content-between align-items-center'>
						<h6 className={st.card__title}>{Translate(lang, 'home.highRankedOrgs')}</h6>

						<select 
							className='mx-3 form-control'
							onChange={e => setSelectedType(e.target.value)}
						>
							<option value='all'>{Translate(lang, 'global.all')}</option>
							{orgTypes.map(orgType => (
								<option value={orgType._id} key={orgType._id}>{orgType.name[lang]}</option>
							))}
						</select>

						<button className={ft.primary__btn}>
							<Link to={selectedType !== 'all' ? `/home/all?organizationType=${selectedType}` : '/home/all'}>
								{Translate(lang, 'home.seeAll')}
							</Link>
						</button>
					</div>
					<div className={`${ft.table} overflow-hidden`}>
						<table className={`${ft.table__main} ${st.table} ${st.table__left}`}>
							<thead>
								<tr>
									<td style={{ width: '50px' }}>#</td>
									<td className='w-50'>{Translate(lang, 'home.orgName')}</td>
									<td className='px-0'>{Translate(lang, 'home.earnedBall')}</td>
								</tr>
							</thead>
							<tbody>
								{data.map((d, i) => (
									<tr key={i}>
										<td>{i + 1}</td>
										<td className='w-50'>
											{d.placeIcon === 'up' ? chevronUp : d.placeIcon === 'down' ? chevronDown : equal}
											{d?.organization[0]?.name?.uz}
										</td>
										<td className='px-0'>
											<Progress finish={d.totalBall} color='#07a287' />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className={st.body__card}>
					<div className='d-flex justify-content-between align-items-center'>
						<h6 className={st.card__title}>{Translate(lang, 'home.lowRankedOrgs')}</h6>
						<button className={st.more__btn}>
							<Link to='/home/stat'>{Translate(lang, 'home.seeAll')}</Link>
						</button>
					</div>
					<div className={`${ft.table} overflow-hidden`}>
						<table className={`${ft.table__main} ${st.table} ${st.table__right}`}>
							<thead>
								<tr>
									<td>#</td>
									<td>{Translate(lang, 'home.orgName')}</td>
									<td className='text-center'>{Translate(lang, 'homeAll.notReceived')}</td>
									<td className='text-center'>{Translate(lang, 'home.allTasks')}</td>
								</tr>
							</thead>
							<tbody>
								{home?.length && home.length < 10
									? home.map((d, i) => (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>{d.org[lang]}</td>
												<td>
													<div className='d-flex justify-content-center'>
														<Circle cl='table' col='239, 93, 168' num={d.notDone} />
													</div>
												</td>

												<td>
													<div className='d-flex justify-content-center'>
														<Circle cl='table' col='51, 51, 51' num={d.total} />
													</div>
												</td>
											</tr>
									  ))
									: home.slice(data.length - 10).map((d, i) => (
											<tr key={i}>
												<td>{data.length - 9 + i}</td>
												<td>{d.org[lang]}</td>
												<td>
													<div className='d-flex justify-content-center'>
														<Circle cl='table' col='239, 93, 168' num={d.notDone} />
													</div>
												</td>

												<td>
													<div className='d-flex justify-content-center'>
														<Circle cl='table' col='51, 51, 51' num={d.total} />
													</div>
												</td>
											</tr>
									  ))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}
