import axiosInstance from './api';

const api = '/evaluation-time';

const headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const markTimesApi = {
	all: () => {
		return axiosInstance.get(`${api}/all`, headers, headers)
	},
	markAdd: (data) => axiosInstance.post(`${api}/create`, data, headers),
	stopMark: (id) => axiosInstance.put(`${api}/update/${id}`, headers, headers),
}
