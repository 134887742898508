import CustomModal from '../../../../components/customModal'
import ft from '../../../../components/mainStyles.module.scss'
import Translate from '../../../../components/lang'
import ExportPdf from '../../../../components/exportPdf'
import { chevronDown, chevronUp, equal } from '../../../../components/icons'
import Circle from '../../../../components/circle'

export default function HomeAllModal(props) {
	const { data, lang } = props

	return (
		<CustomModal {...props} size='lg'>
			<div className={`${ft.content__head} justify-content-between`}>
				<div className={`${ft.btns} w-100 justify-content-end`}>
					<ExportPdf {...props} title={Translate(lang, 'orgList.title')} id='homeAllModal' />
				</div>
			</div>
			<div className={ft.table}>
				<table id='homeAllModal' className={ft.table__main}>
					<thead>
						<tr>
							<td>#</td>
							<td>{Translate(lang, 'orgList.orgName')}</td>
							<td>{Translate(lang, 'orgList.orgType')}</td>
							<td className='text-center'>{Translate(lang, 'homeAll.byEvents')}</td>
							<td className='text-center'>{Translate(lang, 'homeAll.byTasks')}</td>
							<td className='text-center'>{Translate(lang, 'homeAll.allPoints')}</td>
						</tr>
					</thead>
					<tbody>
						{data.map((d, index) => (
							<tr>
								<td>{index + 1}</td>
								<td>
									{d.placeIcon === 'up' ? chevronUp : d.placeIcon === 'down' ? chevronDown : equal}
									{d.org[lang]}
								</td>
								<td>{d.type === 0 ? Translate(lang, 'orgList.orgType0') : Translate(lang, 'orgList.orgType1')}</td>
								<td>
									<div className='d-flex justify-content-center'>
										<Circle num={Math.round(d.left * 10) / 10} col='88, 85, 214' cl='table' />
									</div>
								</td>
								<td>
									<div className='d-flex justify-content-center'>
										<Circle num={Math.round(d.right * 10) / 10} col='239, 93, 168' cl='table' />
									</div>
								</td>
								<td>
									<div className='d-flex justify-content-center'>
										<Circle num={Math.round(d.total * 10) / 10} col='7, 162, 135' cl='table' />
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</CustomModal>
	)
}
