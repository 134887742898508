import axiosInstance from './api';

const api = '/handbooks';

const headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const infosApi = {
	all: (d) => {
		return axiosInstance.get(`${api}/all?limit=${d.limit}&page=${d.page}`, headers, headers)
	},
	add: (data) => axiosInstance.post(`${api}/create`, data, headers),
	edit: (data, id) => axiosInstance.put(`${api}/update/${id}`, data, headers),
	delete: (id) => axiosInstance.delete(`${api}/delete/${id}`, headers),
	delFile: (file, id) => axiosInstance.put(`${api}/edit/${file}/${id}`, headers),
}
