import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';

import { orgTypesApi } from '../../../services/orgTypesService';

import ft from '../../../components/mainStyles.module.scss';

export default function EditModal(props) {
	const [load, setLoad] = useState(false),
		{ lang, id, data, setLoading, setData, setShow } = props

	const handleSubmit = (e) => {
		e.preventDefault();

		const name = {
			uz: e.target[0].value,
			ru: e.target[1].value
		};
		const status = e.target[2].value;

		setLoad(true);

		orgTypesApi.edit({ name, status }, id).then((res) => {
			if (res.data.success) {
				setLoad(false);
				setLoading(true);

				orgTypesApi.all({ limit: 10, page: 1 }).then((res) => {
					setLoading(false);
					setData(res.data.data);
				});

				setShow(false);
			}
		});
	}

	return (
		<CustomModal {...props} modalTitle={Translate(lang, 'orgType.editTitle')}>
			<Form className={`${ft.form} row px-2`} onSubmit={handleSubmit}>
				<div className='col-12'>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.name')} (Uzb)</div>
						<Form.Control type='text' defaultValue={data.name.uz} required />
					</Form.Label>
				</div>

				<div className='col-12'>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.name')} (Рус)</div>
						<Form.Control type='text' defaultValue={data.name.ru} required />
					</Form.Label>
				</div>

				<div className='col-12'>
					<Form.Label className='m-0'>
						<div className={ft.lab}>{Translate(lang, 'global.status')}</div>
						<select className='mb-2' defaultValue={data.status}>
							<option value={0}>{Translate(lang, 'global.active')}</option>
							<option value={1}>{Translate(lang, 'global.inactive')}</option>
						</select>
					</Form.Label>
				</div>

				<div className={ft.modalBtns}>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setShow(false);
							setLoad(false);
						}}
					>
						{Translate(lang, 'global.cancel')}
					</button>

					<button
						className={`${ft.save__btn} d-flex align-items-center ml-3`}
						type='submit'
						disabled={load}
					>
						{Translate(lang, 'global.save')}
						{load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
