import axiosInstance from './api';

const api = '/experts';

const headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const expApi = {
	all: (page) => {
		return axiosInstance.get(`${api}/all?limit=${page.limit}&page=${page.page}`, headers, headers)
	},
	add: (data) => axiosInstance.post(`${api}/create`, data, headers),
	edit: (data, id) => axiosInstance.put(`${api}/update/${id}`, data, headers),
	delete: (id) => axiosInstance.delete(`${api}/delete/${id}`, headers),
}
