import axiosInstance from './api';

const api = '/visits';

const headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const sessionsApi = {
	all: (page) =>
		axiosInstance.get(
			`${api}/${page?.search?.length ? `get?search=${page.search}&` : 'all?'}limit=${page.limit}&page=${page.page}`,
			headers,
			headers
		),
	delete: () => axiosInstance.delete(`${api}/rm`, headers, headers),
};
