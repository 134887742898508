import React, { useState } from 'react';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';

import { statisticsApi } from '../../../services/statistic';

import { ExcelCells, formatDate } from '../../../utils';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';

import ft from './style.module.scss';

const ExportExcel = ({ fileName, columns, period, lang }) => {
  const [loading, setLoading] = useState(false);

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fTableData = (data) => {
    let arr = [];

    data.forEach(element => {
      const temp = [];

      columns.forEach(column => {
        let value;

        if (column?.nestedKey) {
          value = element[column.key][column.nestedKey];
        } else {
          value = element[column.key];
        }

        temp.push(value);
      });

      arr.push(temp);
    });

    return [...arr];
  }

  const fHeading = () => {
    const temp = [];
    const temp2 = [];

    columns.forEach(column => {
      temp.push(column.header);

      if (column.code && !temp2.includes(column.code)) {
        temp2.push(column.code);
      } else {
        temp2.push('');
      }
    });

    return [temp2, temp];
  }

  const formExcel = (data) => {
    let workbook = new ExcelJs.Workbook();
    let worksheet = workbook.addWorksheet('Книга 1');

    const datas = [...fHeading(), ...fTableData(data)];

    worksheet.mergeCells('A1:O1');
    worksheet.getCell('A1').value = 'Jamiyatda huquqiy madaniyatni yuksaltirish bo‘yicha tadbirlar haqida';
    worksheet.getCell('A1').font = {
      bold: true,
      size: 14,
      name: 'Times New Roman',
    };
    worksheet.getCell('A1').alignment = { horizontal: 'center' };
    worksheet.mergeCells('A2:O2');
    worksheet.getCell('A2').value = `Ma'lumot (${formatDate(period.start)}-${formatDate(period.end)})`;
    worksheet.getCell('A2').font = {
      bold: true,
      size: 14,
      name: 'Times New Roman'
    };
    worksheet.getCell('A2').alignment = { horizontal: 'center' };

    worksheet.addRow([]);
    worksheet.addRows(datas);
    worksheet.mergeCells('A4:A5');
    worksheet.getCell('A4').value = 'Tashkilot nomi';
    worksheet.getRow(4).alignment = { wrapText: true };

    columns.forEach((col, i) => {
      worksheet.getColumn(i + 1).width = i === 0 ? 40 : 20;
      worksheet.getColumn(i + 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
    });

    datas.forEach((d, i) => {
      d.forEach((el, j) => {
        const text = `${ExcelCells(j)}${i + 4}`;

        if (i === 0 || i === 1) {
          worksheet.getCell(text).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'D9D9D9'
            }
          };
          worksheet.getCell(text).font = {
            bold: true,
            size: 14,
            name: 'Times New Roman'
          };
        } else if (i === datas.length - 1) {
          worksheet.getCell(text).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FFFF00'
            }
          };
          worksheet.getCell(text).font = {
            bold: true,
            size: 14,
            name: 'Times New Roman'
          };
        } else {
          worksheet.getCell(text).font = {
            size: 14,
            name: 'Times New Roman'
          };
        }

        worksheet.getCell(text).border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } }
        };
      })
    })

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: fileType });
      FileSaver.saveAs(blob, fileName);
    });
  }

  const exportToExcel = () => {
    setLoading(true);

    statisticsApi.all(period.id)
      .then(res => {
        const DATA = [];

        res.data.data.forEach(d => {
          const temp = {
            name: d?.organization.name?.uz ?? '',
            indicator1: {
              all: d?.indicator1?.all,
              accepted: d?.indicator1?.accepted,
              rejected: d?.indicator1?.rejected,
              ball: d?.indicator1?.ball
            },
            indicator2: {
              employeeCount: d?.indicator2?.employeeCount,
              trainedEmployeeCount: d?.indicator2?.trainedEmployeeCount,
              ball: d?.indicator2?.ball
            },
            indicator3: {
              all: d?.indicator3?.all,
              accepted: d?.indicator3?.accepted,
              rejected: d?.indicator3?.rejected,
              ball: d?.indicator3?.ball
            },
            indicator4: {
              all: d?.indicator4?.all,
              accepted: d?.indicator4?.accepted,
              rejected: d?.indicator4?.rejected,
              ball: d?.indicator4?.ball
            },
            indicator5: {
              all: d?.indicator5?.all,
              accepted: d?.indicator5?.accepted,
              rejected: d?.indicator5?.rejected,
              ball: d?.indicator5?.ball
            },
            indicator6: {
              all: d?.indicator6?.all,
              accepted: d?.indicator6?.accepted,
              rejected: d?.indicator6?.rejected,
              ball: d?.indicator6?.ball
            },
            indicator7: {
              all: d?.indicator7?.all,
              accepted: d?.indicator7?.accepted,
              rejected: d?.indicator7?.rejected,
              ball: d?.indicator7?.ball
            },
            indicator8: {
              all: d?.indicator8?.all,
              accepted: d?.indicator8?.accepted,
              rejected: d?.indicator8?.rejected,
              ball: d?.indicator8?.ball
            },
            indicator9: {
              all: d?.indicator9?.all,
              accepted: d?.indicator9?.accepted,
              rejected: d?.indicator9?.rejected,
              ball: d?.indicator9?.ball
            },
            indicator10: {
              currentUser: d?.indicator10?.currentUser,
              previousUser: d?.indicator10?.previousUser,
              ball: d?.indicator10?.ball
            },
            indicator11: {
              all: d?.indicator11?.all,
              accepted: d?.indicator11?.accepted,
              rejected: d?.indicator11?.rejected,
              ball: d?.indicator11?.ball
            },
            indicator12: {
              all: d?.indicator12?.all,
              accepted: d?.indicator12?.accepted,
              rejected: d?.indicator12?.rejected,
              ball: d?.indicator12?.ball
            },
            indicator13: {
              all: d?.indicator13?.all,
              accepted: d?.indicator13?.accepted,
              rejected: d?.indicator13?.rejected,
              ball: d?.indicator13?.ball
            }
          };

          DATA.push(temp);
        });

        let temp = {
          name: 'JAMI',
          indicator1: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator2: {
            employeeCount: 0,
            trainedEmployeeCount: 0,
            ball: 0
          },
          indicator3: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator4: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator5: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator6: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator7: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator8: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator9: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator10: {
            currentUser: 0,
            previousUser: 0,
            ball: 0
          },
          indicator11: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator12: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          },
          indicator13: {
            all: 0,
            accepted: 0,
            rejected: 0,
            ball: 0
          }
        };

        DATA.forEach(d => {
          for (let i = 1; i < 14; i++) {
            if (i !== 2 && i !== 10) {
              temp[`indicator${i}`].all += d[`indicator${i}`].all;
              temp[`indicator${i}`].accepted += d[`indicator${i}`].accepted;
              temp[`indicator${i}`].rejected += d[`indicator${i}`].rejected;
              temp[`indicator${i}`].ball += d[`indicator${i}`].ball;
            } else if (i === 2) {
              temp.indicator2.employeeCount += d.indicator2.employeeCount;
              temp.indicator2.trainedEmployeeCount += d.indicator2.trainedEmployeeCount;
              temp.indicator2.ball += d.indicator2.ball;
            } else if (i === 10) {
              temp.indicator10.currentUser += d.indicator10.currentUser;
              temp.indicator10.previousUser += d.indicator10.previousUser;
              temp.indicator10.ball += d.indicator10.ball;
            }
          }
        });

        DATA.push(temp);

        formExcel(DATA);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <button className={ft.export__btn} onClick={() => exportToExcel()}>
      <i className='far fa-file-excel mr-1' />
      {Translate(lang, 'global.exportExcel')}
      {loading && <Loading cl='ml-2' />}
    </button>
  )
}

export default ExportExcel;