import { useEffect } from 'react';
import Select from 'react-select';

import Translate from '../lang';

const CustomSelect = ({ selected, handleChange, options, setErrSel, lang, isAdd }) => {
	useEffect(() => {
		if (selected?.length && setErrSel) setErrSel(false)
	}, [selected])

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			borderBottom: '1px solid rgba(7, 162, 135, .2)',
			color: '#000',
			padding: 8,
		}),
	}

	return (
		<Select
			isMulti={true}
			value={selected}
			onChange={handleChange}
			options={isAdd && selected?.length !== options?.length ? [{ value: 'all', label: Translate(lang, 'global.all') }, ...options] : options}
			styles={customStyles}
			placeholder={Translate(lang, 'global.chooseOrg')}
		/>
	)
}

export default CustomSelect;