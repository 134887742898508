import { useState, useRef } from 'react'
import CustomModal from '../../../components/customModal'
import { Row, Col, Form } from 'react-bootstrap'
import ft from '../../../components/mainStyles.module.scss'
import { expApi } from '../../../services/expertsService'
import Loading from '../../../components/loading'
import Translate from '../../../components/lang'
import { GeneratePassword, ValidatePassword } from '../../../utils'

export default function ExpertAdd(props) {
	const { setShow, token, setData, setLoading, size, lang, setPageNum, setPc } = props,
		[err, setErr] = useState(false),
		[errPh, setErrPh] = useState(false),
		[errNum, setErrNum] = useState(false),
		[errPass, setErrPass] = useState(false),
		[load, setLoad] = useState(false),
		[view, setView] = useState(false),
		password = useRef('')

	const handleSubmit = (e) => {
		e.preventDefault()
		const val = e.target[3].value.match(/^(\+)([998]{3})([\d]{2})(\d+)/)
		if (val && val[4].length === 7) {
			setErrPh(false)
			if (Number(e.target[4].value)) {
				setErrNum(false)
				if (ValidatePassword(e.target[6].value)) {
					setErrPass(false)
					setLoad(true)
					const data = {
						fullName: e.target[0].value,
						position: {
							uz: e.target[1].value,
							ru: e.target[2].value,
						},
						phone: +e.target[3].value,
						additionalPhone: +e.target[4].value,
						login: e.target[5].value,
						password: e.target[6].value,
					}
					expApi
						.add(data)
						.then((res) => {
							if (res.data.success) {
								setLoad(false)
								setLoading(true)
								expApi.all({ limit: 10, page: 1 }).then((res) => {
									if (res.data.success) {
										setPageNum(Math.ceil(res.data.num))
										setPc(0)
										setData(res.data.data)
										setLoading(false)
									}
								})
								setShow(false)
							}
						})
						.catch((err) => {
							setErr(true)
							setLoad(false)
						})
				} else setErrPass(true)
			} else {
				setErrNum(true)
			}
		} else {
			setErrPh(true)
		}
	}

	return (
		<CustomModal {...props} modalTitle={Translate(lang, 'experts.addExpert')} size='xs'>
			<Form className={ft.form} onSubmit={handleSubmit}>
				<Form.Label className='w-100'>
					<div className={ft.lab}>{Translate(lang, 'table.expert')}</div>
					<Form.Control type='text' placeholder='F.I.O' required />
				</Form.Label>
				<Form.Label className='w-100'>
					<div className={ft.lab}>{Translate(lang, 'experts.position')} (Uzb)</div>
					<Form.Control type='text' placeholder='' required />
				</Form.Label>
				<Form.Label className='w-100'>
					<div className={ft.lab}>{Translate(lang, 'experts.position')} (Рус)</div>
					<Form.Control type='text' placeholder='' required />
				</Form.Label>
				<Row>
					<Col md={8} className='mx-0'>
						<Form.Label>
							<div className={ft.lab}>{Translate(lang, 'experts.phoneNumber')}</div>
							<Form.Control
								type='phone'
								required
								style={
									errPh
										? {
												backgroundColor: 'rgba(255, 82, 82, 0.1)',
										  }
										: {}
								}
							/>
						</Form.Label>
					</Col>
					<Col md={4} className='mx-0'>
						<Form.Label>
							<div className={ft.lab}>{Translate(lang, 'experts.internalNumber')}</div>
							<Form.Control
								type='phone'
								maxLength={4}
								required
								style={
									errNum
										? {
												backgroundColor: 'rgba(255, 82, 82, 0.1)',
										  }
										: {}
								}
							/>
						</Form.Label>
					</Col>
				</Row>
				{errPh ? <span className={ft.err__mes}>{Translate(lang, 'experts.internalNumber')}</span> : ''}
				{errNum ? <span className={ft.err__mes}>{Translate(lang, 'experts.wrongInternalN')}</span> : ''}
				<Row>
					<Col md={6}>
						<Form.Label>
							<div className={ft.lab}>{Translate(lang, 'global.login')}</div>
							<Form.Control
								type='text'
								required
								style={
									err
										? {
												backgroundColor: 'rgba(255, 82, 82, 0.1)',
										  }
										: {}
								}
							/>
						</Form.Label>
					</Col>
					<Col md={6}>
						<Form.Label>
							<div className={`${ft.lab} d-flex justify-content-between`}>
								{Translate(lang, 'login.pass')}
								<span
									style={{ cursor: 'pointer', lineHeight: '24px' }}
									onClick={() => {
										password.current.value = GeneratePassword()
									}}>
									{Translate(lang, 'global.genPass')}
								</span>
							</div>
							<div className='position-relative'>
								<Form.Control ref={password} minLength={8} type={view ? 'text' : 'password'} required />
								<i
									className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
									style={{
										top: '50%',
										right: 0,
										transform: 'translate(-10px, -8px)',
										cursor: 'pointer',
									}}
									onClick={() => setView(!view)}></i>
							</div>
						</Form.Label>
					</Col>
				</Row>
				{err ? <span className={ft.err__mes}>{Translate(lang, 'login.err')}</span> : ''}
				{errPass ? <span className={ft.err__mes}>{Translate(lang, 'global.errPass')}</span> : ''}
				<div className={ft.modalBtns}>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setShow(false)
							setLoad(false)
						}}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load}>
						{Translate(lang, 'global.save')} {load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
