import React, { useMemo, useState } from 'react';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';

import { docStatisticApi } from '../../../../services/doc/docStatisticService';

import { ExcelCells, formatDate } from '../../../../utils';

import Translate from '../../../../components/lang';
import Loading from '../../../../components/loading';

import ft from '../../../../components/mainStyles.module.scss';

const ExportExcel = ({ lang, filter, period }) => {
	const [loading, setLoading] = useState(false);

	const fileName = "Ma'lumot uchun yuborilgan tashkilotlar statistikasi";
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

	const columns = useMemo(() => [
		{ header: 'Tashkilot', key: 'orgName' },
		{ header: 'Hujjat raqami', key: 'docNumber' },
		{ header: 'Holati', key: 'status' }
	], []);

	const fTableHead = () => {
		const temp = [];

		columns.forEach(column => {
			temp.push(column.header);
		})

		return [temp];
	}

	const fTableBody = (data) => {
		let arr = [];

		data.forEach(element => {
			const temp = [];

			columns.forEach(column => {
				const value = element[column.key];
				temp.push(value);
			});

			arr.push(temp);
		});

		return [...arr];
	}

	const formExcel = (data) => {
		let workbook = new ExcelJs.Workbook();
		let worksheet = workbook.addWorksheet('Книга 1');

		const datas = [...fTableHead(), ...fTableBody(data)];

		worksheet.mergeCells('A1:O1');
		worksheet.getCell('A1').value = fileName;
		worksheet.getCell('A1').font = {
			bold: true,
			size: 14,
			name: 'Times New Roman'
		};
		worksheet.getCell('A1').alignment = { horizontal: 'center' };

		worksheet.mergeCells('A2:O2');
		worksheet.getCell('A2').value = `${formatDate(period.start)}-${formatDate(period.end)}`;
		worksheet.getCell('A2').font = {
			bold: true,
			size: 14,
			name: 'Times New Roman'
		};
		worksheet.getCell('A2').alignment = { horizontal: 'center' };

		worksheet.addRow([]);
		worksheet.addRows(datas);

		columns.forEach((col, i) => {
			worksheet.getColumn(i + 1).width = (3 - i) * 20;
			worksheet.getColumn(i + 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
		});

		datas.forEach((d, i) => {
			d.forEach((el, j) => {
				const text = `${ExcelCells(j)}${i + 4}`;

				if (i === 0) {
					worksheet.getCell(text).fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: {
							argb: 'D9D9D9'
						}
					};
					worksheet.getCell(text).font = {
						bold: true,
						size: 14,
						name: 'Times New Roman'
					};
				}  else {
					worksheet.getCell(text).font = {
						size: 14,
						name: 'Times New Roman'
					};
				}
				worksheet.getCell(text).border = {
					top: { style: 'thin', color: { argb: '00000000' } },
					left: { style: 'thin', color: { argb: '00000000' } },
					bottom: { style: 'thin', color: { argb: '00000000' } },
					right: { style: 'thin', color: { argb: '00000000' } }
				};
			});
		});

		workbook.xlsx.writeBuffer()
			.then(data => {
				const blob = new Blob([data], { type: fileType });
				FileSaver.saveAs(blob, fileName);
			});
	}

	const exportToExcel = () => {
		setLoading(true);

		docStatisticApi
			.exportToExcelReceiveds(period.id, filter)
			.then(res => {
				const DATA = [];

				res.data.data.forEach(item => {
					const temp = {
						orgName: item?.organization?.name[lang] || '',
						docNumber: item?.doc?.number || '',
						status: Translate(lang, `status.${item.status}`) || ''
					};

					DATA.push(temp);
				})

				formExcel(DATA);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<button className={ft.export__btn} onClick={exportToExcel}>
			<i className='far fa-file-excel mr-1' />
			{Translate(lang, 'global.exportExcel')}
			{loading && <Loading cl='ml-2' />}
		</button>
	)
}

export default ExportExcel
