import React, { useEffect, useState } from 'react'
import ft from '../../../components/mainStyles.module.scss'
import Container from '../../../components/container'
import Loading from '../../../components/loading'
import { orgApi } from '../../../services/orgListService'
import CustomSelect from '../../../components/customSelect'
import { Form } from 'react-bootstrap'
import Circle from '../../../components/circle'
import TaskStatOne from './taskStatOne'
import { Link, Route } from 'react-router-dom'
import Filter from '../../../components/filter'
import Translate from '../../../components/lang'
import TaskStatModal from './taskStatModal'
import { GetStat } from '../../../utils'
import Pagin from '../../../components/pagin'

import { exp } from '../../../components/icons'
import TaskStatExp from './taskStatExp'
import TaskExpAll from './taskExpAll'
import ExportExcel from '../../../components/exportExcel'

export default function TasksStat(props) {
	const { selPer, periods } = props,
		[filter, setFilter] = useState({
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
		})

	return (
		<>
			<Route path='/tasks/stat' exact render={() => <TasksStatMain filter={filter} setFilter={setFilter} {...props} />} />
			<Route path='/tasks/stat/one' render={(temp) => <TaskStatOne filter={filter} setFilter={setFilter} {...temp} {...props} />} />
		</>
	)
}

function TasksStatMain(props) {
	const [data, setData] = useState([]),
		[options, setOptions] = useState([]),
		[selected, setSelected] = useState([]),
		[show, setShow] = useState(false),
		[showFilter, setShowFilter] = useState(false),
		[showExpAll, setShowExpAll] = useState(false),
		[statTitle, setStatTitle] = useState(''),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[id, setId] = useState(false),
		[loading, setLoading] = useState(true),
		[load, setLoad] = useState(false),
		[selId, setSelId] = useState([]),
		{ lang, periods, selPer, filter, setFilter } = props

	useEffect(() => {
		orgApi.getAll().then((res) => {
			if (res.data.success) {
				const DATA = []
				res.data.data.map((d) => {
					DATA.push({
						value: d._id,
						label: d.name.uz,
					})
				})
				setOptions(DATA)
			}
		})
		setFilter({
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?.id,
		})
	}, [selPer])

	useEffect(() => {
		setLoading(true)

		orgApi
			.getStat({
				...page,
				start: periods[selPer]?.start,
				end: periods[selPer]?.end,
				period: periods[selPer]?.id,
				...filter,
			})
			.then((res) => {
				if (res.data.success) {
					setPageNum(Math.ceil(res.data.num))
					setPc((page.page - 1) * page.limit)
					setData(GetStat(res.data.data))
					setLoading(false)
				}
			})
	}, [page])

	function handleChange(selected) {
		setSelected(selected)
	}

	const handleModal = () => {
		setShow(!show)
	}

	const handleFilter = (e) => {
		e.preventDefault()
		setLoading(true)
		setPage({ ...page, page: 1 })
		const selId = []
		selected.map((s) => {
			selId.push(s.value)
		})
		const data = {
			start: e.target[1].value,
			end: e.target[2].value,
		}
		if (selId.length) {
			data.organizations = selId
		}
		setSelId([...selId])
		setFilter(data)
		setShowFilter(false)
		orgApi.getStat({ limit: 10, page: 1, ...data }).then((res) => {
			setPageNum(Math.ceil(res.data.num))
			setPc(0)
			setData(GetStat(res.data.data))
			setLoading(false)
		})
	}

	const handleExpAllPdf = () => {
		setLoad(true)
	}

	const columns = [
		{ header: 'Ташкилот номи', key: 'name' },
		{ header: 'Акция, флешмоблар', code: 1, key: 'count1' },
		{ header: 'Ходимлар ўқитиш', code: 2, key: 'count2' },
		{ header: 'Маҳалларда ўтказилган тарғибот', code: 3, key: 'count3' },
		{ header: 'Тадбиркорлар ўртасида ўтказилган тарғибот', code: 4, key: 'count4' },
		{ header: 'Ҳуқуқий қўлланмалар', code: 5, key: 'count5' },
		{ header: 'Кўргазмали тарғибот материаллари', code: 6, key: 'count6' },
		{ header: 'Ҳуқуқий мултфилмлар', code: 7, key: 'count7' },
		{ header: 'Видеролик', code: 8, key: 'count8' },
		{ header: 'Коррупция бўйича видеоролик', code: 9, key: 'count9' },
		{ header: 'Ижтимоий тармоқлар сони', code: 10, key: 'count10' },
		{ header: 'Ижтимоий тармоқлардаги жами фойдаланувчи сони', code: '10.1', key: 'count101' },
		{ header: 'Янги тарғибот турлари', code: 11, key: 'count11' },
		{ header: 'Брифинглар', code: 12, key: 'count12' },
		{ header: 'Ҳамкорликдаги тадбирлар', code: 13, key: 'count13' },
	]

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'navbar.statTask')}</h5>
					<div className={ft.btns}>
						<button className={ft.export__btn} onClick={() => setShowExpAll(true)}>
							<span className='mr-1'>{Translate(lang, 'global.statExp')}</span> {exp}
						</button>
						<button className={ft.export__btn} onClick={handleExpAllPdf}>
							<span className='mr-1'>{Translate(lang, 'global.grafExp')}</span> {exp}
						</button>
						<ExportExcel {...props} columns={columns} fileName='Жамиятда ҳуқуқий маданиятни юксалтириш бўйича тадбирлар ҳақида' />
						<button className={`${ft.primary__btn} ml-2`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button>
					</div>
				</div>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr onDoubleClick={handleModal}>
								<td>#</td>
								<td>{Translate(lang, 'global.orgName')}</td>
								<td className='text-center'>{Translate(lang, 'home.uncompleted')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.fileSent')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.received')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.notReceived')}</td>
								<td className='text-center'>{Translate(lang, 'home.allTasks')}</td>
								<td className={`text-center ${ft.removeOnPrint}`}>{Translate(lang, 'table.action')}</td>
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data?.map((d, index) => (
									<tr
										onDoubleClick={() => {
											setStatTitle(d?.name[lang])
											setId(d._id)
											setShow(true)
										}}>
										<td>{pc + index + 1}</td>
										<td>{d?.name[lang]}</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat0 ? d?.stat0 : 0} col='255, 0, 0' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat1 ? d?.stat1 : 0} col='46, 143, 233' />
											</div>
										</td>

										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat2 ? d?.stat2 : 0} col='7, 162, 136' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat3 ? d?.stat3 : 0} col='239, 93, 168' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d.sum} col='51, 51, 51' />
											</div>
										</td>
										<td className={`text-center  ${ft.removeOnPrint}`}>
											<Link to={`/tasks/stat/one?id=${d._id}`}>
												<button className={ft.info__btn}>{Translate(lang, 'global.more')}</button>
											</Link>
										</td>
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>
					<iframe name='print_frame' width='0' height='0' frameborder='0' src='about:blank'></iframe>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			{!!show && <TaskStatModal {...props} show={show} setShow={setShow} statTitle={statTitle} id={id} filter={filter} />}

			{!!load && <TaskStatExp {...props} show={load} setShow={setLoad} pageNum={pageNum} page={page} selId={selId} />}

			{!!showExpAll && <TaskExpAll {...props} filter={filter} loading={loading} show={showExpAll} setShow={setShowExpAll} />}

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.orgName')}</div>
						<CustomSelect lang={lang} selected={selected} handleChange={handleChange} options={options} />
					</Form.Label>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.from')}</div>
						<Form.Control type='date' min={periods[selPer].start} defaultValue={filter?.start} required />
					</Form.Label>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.to')}</div>
						<Form.Control type='date' max={periods[selPer].end} defaultValue={filter?.end} required />
					</Form.Label>
					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'orgList.search')}
					</button>
				</Form>
			</Filter>
		</div>
	)
}
