import axiosInstance from './api';

const api = '/statistics';

const headers = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
}

export const statisticsApi = {
  all: (periodId) => axiosInstance.post(`${api}/all`, { period: periodId }, headers)
}
