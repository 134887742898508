import Translate from '../components/lang';

export function BeautyDate(num) {
	return num.toString().split('').slice(0, 10).join('').split('-').reverse().join('.');
}

export function BeautyTime(num) {
	return num.toString().split('').slice(11, 19).join('');
}

export function BeautyFullTime(num) {
	return BeautyDate(num) + ' ' + BeautyTime(num);
}

export function GetStat(data) {
	const temp = [...data];
	data.forEach((d, i) => {
		let sum = 0;
		d.stat.forEach((s) => {
			if (s._id === 0) {
				temp[i].stat0 = s.count;
				sum += s.count;
			} else if (s._id === 1) {
				temp[i].stat1 = s.count;
				sum += s.count;
			} else if (s._id === 2) {
				temp[i].stat2 = s.count;
				sum += s.count;
			} else if (s._id === 3) {
				temp[i].stat3 = s.count;
				sum += s.count;
			}
		});
		temp[i].sum = sum;
	});
	return temp;
}

export function PeriodNow(lang) {
	const date = new Date();
	const nowMonth = date.getMonth();
	const nowYear = date.getFullYear();
	const temp = [];
	for (let i = 2021, j = 0; i <= nowYear; i++, j++) {
		if (nowMonth > 6 || i <= nowYear) {
			temp.push(
				{
					start: `${i}-01-01`,
					end: `${i}-08-30`,
					value: `${i}-${Translate(lang, 'time.year')} 1-${Translate(lang, 'time.half')}`,
				},
				{
					start: `${i}-09-01`,
					end: `${i}-12-31`,
					value: `${i}-${Translate(lang, 'time.year')} 2-${Translate(lang, 'time.half')}`,
				}
			);
			j++;
		} else
			temp.push({
				start: `${i}-01-01`,
				end: `${i}-08-30`,
				value: `${i}-${Translate(lang, 'time.year')} 1-${Translate(lang, 'time.half')}`,
			});
	}
	return temp.reverse().slice(0, 6);
}

export function SortReyt(newData, oldData) {
	const temp = [];
	const	newDATA = JamesSort(newData);
	const	oldDATA = JamesSort(oldData);

	newDATA.forEach((d) => {
		const f = oldDATA.filter((o) => o.organization[0]?._id === d.organization[0]?._id);

		if (f[0]?.place > d.place) {
			temp.push({ ...d, placeIcon: 'up' });
		} else if (f[0]?.place < d.place) {
			temp.push({ ...d, placeIcon: 'down' });
		} else {
			temp.push({ ...d, placeIcon: 'equal' });
		}
	});

	return temp;
}

function JamesSort(data) {
	const temp = JamesFormatter(data);

	const mas = temp.sort((a, b) => {
		if (a.totalBall > b.totalBall) return 1;
		if (a.totalBall < b.totalBall) return -1;
		if (a?.organization[0]?.name?.uz < b?.organization[0]?.name?.uz) return 1;
		if (a?.organization[0]?.name?.uz > b?.organization[0]?.name?.uz) return -1;
		return 0;
	});

	let count = 1;
	let	lastTotal = mas[0].totalBall;

	return mas.map((item) => {
		if (lastTotal === item.totalBall) {
			return { ...item, place: count };
		} else {
			count++;
			lastTotal = item.totalBall;
			return { ...item, place: count };
		}
	}).reverse();
}

function JamesFormatter(data) {
	const temp = [];

	data.forEach((item) => {
		temp.push({
			...item,
			totalBall: item?.ball?.all || 0
		});
	});

	return temp;
}

function GeneralSort(data) {
	const temp = [];

	for (let i = 0; i < data.length; i++) {
		const arr = [{ ...data[i], place: i + 1, totalBall: data[i].res?.total ? data[i].res.total : data[i].res }];
		let j = i + 1;
		for (; j < data.length; j++) {
			if (
				(data[i].res?.total ? data[i].res.total : data[i].res) >
				(data[j].res?.total ? data[j].res.total : data[j].res)
			) {
				break;
			}
			arr.push({ ...data[j], place: i + 1, totalBall: data[j].res?.total ? data[j].res.total : data[j].res });
		}
		i = j - 1;
		temp.push(
			...arr.sort(function (a, b) {
				let nameA = a.name.uz.toLowerCase(),
					nameB = b.name.uz.toLowerCase();
				if (nameA < nameB) return -1;
				if (nameA > nameB) return 1;
				return 0;
			})
		);
	}
	return temp;
}

export function ExcelCells(number) {
	if (number >= 26) {
		return String.fromCharCode(65 + Math.floor(number / 26) - 1) + String.fromCharCode(65 + (number % 26));
	} else {
		return String.fromCharCode(65 + number);
	}
}

export function formatDate(str) {
	const date = new Date(str);
	const year = date.getFullYear();
	const month = getDate(date.getMonth() + 1);
	const day = getDate(date.getDate());
	return `${day}.${month}.${year}`;
}

function getDate(str) {
	if (+str < 10) return `0${str}`;
	return str;
}

export function ValidatePassword(text) {
	if (!text.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)?.length) return false;
	return true;
}

export function GeneratePassword() {
	var length = 8,
		charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%,&*();',
		retVal = '';
	for (var i = 0, n = charset.length; i < length; ++i) {
		retVal += charset.charAt(Math.floor(Math.random() * n));
	}
	if (!retVal.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)?.length)
		return GeneratePassword();
	return retVal;
}
