import st from './pagin.module.scss';

const Pagin = ({ pageNum, setPage, page }) => {

	const handleSelect = (e, i) => {
		e.preventDefault();

		const items = document.querySelectorAll(`.${st.pagin__item}`);

		items.forEach((index) => {
			if (index.classList.contains(st.pagin__item__active)) {
				index.classList.remove(st.pagin__item__active);
			} else {
				index.classList.contains(st.pagin__item__active);
				e.target.classList.add(st.pagin__item__active);
			}
		})

		setPage({ ...page, page: i });
	}

	return (
		!!pageNum && (
			<div className={st.pagin}>
				<div
					className={st.pagin__item}
					onClick={() => {
						if (page.page !== 1) {
							setPage(prev => ({ ...prev, page: prev.page - 1 }));
						}
					}}
				>
					{'<'}
				</div>

				{[...Array(pageNum)].map((b, i) => (
					<div
						className={`${st.pagin__item} ${page.page === i + 1 ? st.pagin__item__active : ''}`}
						onClick={e => handleSelect(e, i + 1)}
						key={i}
					>
						{i + 1}
					</div>
				))}

				<div
					className={st.pagin__item}
					style={{ marginRight: '4vw' }}
					onClick={() => {
						if (page.page !== pageNum) {
							setPage(prev => ({ ...prev, page: prev.page + 1 }));
						}
					}}
				>
					{'>'}
				</div>
			</div>
		)
	)
}

export default Pagin;
