import axios from 'axios';

import config from '../config';

let axiosInstance = axios.create({
	baseURL: `${config.api.baseUrl}/api`,
});

axiosInstance.interceptors.request.use(
	(request) => {
		request.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`
		return request
	},
	(error) => {
		return error
	}
)

export default axiosInstance;
