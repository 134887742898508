import CustomModal from '../../../components/customModal'
import ft from '../../../components/mainStyles.module.scss'
import Translate from '../../../components/lang'
import ExportPdf from '../../../components/exportPdf'
import { useEffect, useState } from 'react'
import Loading from '../../../components/loading'
import { orgApi } from '../../../services/orgListService'

export default function OrgListModal(props) {
	const [data, setData] = useState([]),
		[load, setLoad] = useState(true),
		{ token, selPer, name, periods, lang, show } = props

	useEffect(() => {
		setLoad(true)
		const DATA = {
			page: 1,
			limit: 10000,
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?.id,
		}
		if (name.length) DATA.name = name
		orgApi.all(DATA, token).then((res) => {
			setData(res.data.data)
			setLoad(false)
		})
	}, [show])

	return (
		<CustomModal {...props} size='lg'>
			<div className={`${ft.content__head} justify-content-between`}>
				<div className={`${ft.btns} w-100 justify-content-end`}>
					<ExportPdf {...props} title={Translate(lang, 'orgList.title')} id='orgListModal' />
				</div>
			</div>
			<div id='orgListModal'>
				{!load ? (
					<div className={ft.table}>
						<table id='table' className={ft.table__main}>
							<thead>
								<tr>
									<td>#</td>
									<td>{Translate(lang, 'orgList.orgName')}</td>
									<td>{Translate(lang, 'orgList.phoneNumber')}</td>
									<td>{Translate(lang, 'orgList.address')}</td>
									<td>{Translate(lang, 'orgList.email')}</td>
									<td>{Translate(lang, 'orgList.marked')}</td>
									<td>{Translate(lang, 'orgList.wayOfMarking')}</td>
								</tr>
							</thead>
							<tbody>
								{data.map((d, i) => (
									<tr>
										<td>{i + 1}</td>
										<td>{d.name[lang]}</td>
										<td className='text-nowrap'>{d?.phoneNumber && d?.phoneNumber != 0 ? d.phoneNumber : '---'}</td>
										<td>{d?.legalAddress ? d.legalAddress : '---'}</td>
										<td>{d.email ? d.email : '---'}</td>

										<td>{d?.count?.length ? `${d?.count[0]}/${d?.test?.length ? '14' : '13'}` : `0/${d?.test?.length ? '14' : '13'}`}</td>
										<td>{d?.test?.length ? Translate(lang, 'orgList.all') : Translate(lang, 'orgList.notAll')}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className='d-flex justify-content-center'>
						<Loading size='md' cl='text-center' />
					</div>
				)}
			</div>
		</CustomModal>
	)
}
