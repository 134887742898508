import React, { useEffect, useState } from 'react';

import { edit } from '../../components/icons';

import { orgTypesApi } from '../../services/orgTypesService';

import Pagin from '../../components/pagin';
import Translate from '../../components/lang';
import Loading from '../../components/loading';
import Container from '../../components/container';

import AddModal from './addModal';
import EditModal from './editModal';

import ft from '../../components/mainStyles.module.scss';

export default function OrgTypes(props) {
	const [data, setData] = useState([]),
		[editData, setEditData] = useState([]),
		[show, setShow] = useState(false),
		[showEdit, setShowEdit] = useState(false),
		[loading, setLoading] = useState(true),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[id, setId] = useState(null),
		{ lang, token } = props

	useEffect(() => {
		orgTypesApi.all(page).then((res) => {
			setPage((prev) => {
				let temp = { ...prev };
				if (res.data.num < 1) {
					temp.num = 1;
				} else temp.num = Math.trunc(res.data.num);
				return temp;
			});
			setPageNum(Math.ceil(res.data.num));
			setPc((page.page - 1) * page.limit);
			setData(res.data.data);
		}).finally(() => {
			setLoading(false);
		})
	}, []);

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'navbar.orgTypes')}</h5>
					<div className={ft.btns}>
						<button className={ft.add__btn} onClick={() => setShow(true)}>
							<span className={ft.icon}>+</span>
							{Translate(lang, 'global.add')}
						</button>
					</div>
				</div>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'table.expert')}</td>
								<td>{Translate(lang, 'global.name')}</td>
								<td style={{ width: 200 }} className={`text-center pl-0  ${ft.removeOnPrint}`}>
									{Translate(lang, 'global.status')}
								</td>
								<td style={{ width: '10%' }} className='text-center'>
									{Translate(lang, 'table.action')}
								</td>
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, i) => (
									<tr key={i}>
										<td>{pc + i + 1}</td>
										<td>{d?.expert?.fullName}</td>
										<td>{d?.name[lang]}</td>
										<td className={`text-center  ${ft.removeOnPrint}`}>
											{d.status === '0'
												? <div className={ft.mark__active}>{Translate(lang, 'global.active')}</div>
												: <div className={ft.mark__inactive}>{Translate(lang, 'global.inactive')}</div>
											}
										</td>
										<td className='d-flex justify-content-center'>
											<button
												className={ft.edit__btn}
												onClick={() => {
													setId(d._id);
													setEditData(d);
													setShowEdit(true);
												}}
											>
												{edit}
											</button>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={5}>
										<Loading big={true} size='md' cl='mt-3' />
									</td>
								</tr>
							)}
						</tbody>
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			{!!show && <AddModal {...props} show={show} setShow={setShow} setPageNum={setPageNum} setPc={setPc} setLoading={setLoading} setData={setData} />}

			{!!showEdit && <EditModal {...props} id={id} show={showEdit} setShow={setShowEdit} setData={setData} setLoading={setLoading} data={editData} />}
		</div>
	)
}
