import React, { useState } from 'react';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';

import axiosInstance from '../../services/api';

import { ExcelCells, formatDate } from '../../utils';

import Translate from '../lang';
import Loading from '../loading';

import ft from '../mainStyles.module.scss';

const ExportExcel = ({ fileName, columns, periods, selPer, lang }) => {
	const [loading, setLoading] = useState(false),
		fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

	const fTableData = (data) => {
		let arr = []
		data.forEach((element) => {
			const temp = []
			columns.forEach((key, id) => {
				const value = element[key.key]

				temp.push(value)
			})
			arr.push(temp)
		})

		return [...arr]
	}

	const fHeading = () => {
		const temp = [],
			temp2 = []
		columns.forEach((key) => {
			temp.push(key.header)
			if (key.code) temp2.push(key.code)
			else temp2.push('')
		})
		return [temp2, temp]
	}

	const formExcel = (data) => {
		let workbook = new ExcelJs.Workbook()
		let worksheet = workbook.addWorksheet('Книга 1')
		const datas = [...fHeading(), ...fTableData(data)]

		worksheet.mergeCells('A1:O1')
		worksheet.getCell('A1').value = 'Жамиятда ҳуқуқий маданиятни юксалтириш бўйича тадбирлар ҳақида'
		worksheet.getCell('A1').font = {
			bold: true,
			size: 14,
			name: 'Times New Roman',
		}
		worksheet.getCell('A1').alignment = { horizontal: 'center' }

		worksheet.mergeCells('A2:O2')
		worksheet.getCell('A2').value = `Маълумот (${formatDate(periods[selPer].start)}-${formatDate(periods[selPer].end)})`
		worksheet.getCell('A2').font = {
			bold: true,
			size: 14,
			name: 'Times New Roman',
		}
		worksheet.getCell('A2').alignment = { horizontal: 'center' }

		worksheet.addRow([])
		worksheet.addRows(datas)
		worksheet.mergeCells('A4:A5')
		worksheet.getCell('A4').value = 'Ташкилот номи'
		worksheet.getRow(4).alignment = { wrapText: true }

		columns.forEach((col, i) => {
			worksheet.getColumn(i + 1).width = 20
			worksheet.getColumn(i + 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
		})

		datas.forEach((d, i) => {
			d.forEach((el, j) => {
				const text = `${ExcelCells(j)}${i + 4}`
				if (i === 0 || i === 1) {
					worksheet.getCell(text).fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: {
							argb: 'D9D9D9',
						},
					}
					worksheet.getCell(text).font = {
						bold: true,
						size: 14,
						name: 'Times New Roman',
					}
				} else if (i === datas.length - 1) {
					worksheet.getCell(text).fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: {
							argb: 'FFFF00',
						},
					}
					worksheet.getCell(text).font = {
						bold: true,
						size: 14,
						name: 'Times New Roman',
					}
				} else
					worksheet.getCell(text).font = {
						size: 14,
						name: 'Times New Roman',
					}
				worksheet.getCell(text).border = {
					top: { style: 'thin', color: { argb: '00000000' } },
					left: { style: 'thin', color: { argb: '00000000' } },
					bottom: { style: 'thin', color: { argb: '00000000' } },
					right: { style: 'thin', color: { argb: '00000000' } },
				}
			})
		})

		workbook.xlsx.writeBuffer().then((data) => {
			const blob = new Blob([data], { type: fileType })
			FileSaver.saveAs(blob, fileName)
		})
	}

	const exportToExcel = () => {
		setLoading(true)

		axiosInstance
			.post('/advocacy/exp-excell', {
				period: periods[selPer].id,
			})
			.then((res) => {
				const DATA = []

				res.data.data.forEach((d) => {
					const temp = {
						count1: 0,
						count2: 0,
						count3: 0,
						count4: 0,
						count5: 0,
						count6: 0,
						count7: 0,
						count8: 0,
						count9: 0,
						count10: 0,
						count101: 0,
						count11: 0,
						count12: 0,
						count13: 0,
					}

					temp.name = d?.name?.oz ? d.name.oz : '';

					if (d.detailed.length && d.detailed._id !== 2)
						d.detailed.forEach((det) => {
							temp[`count${det._id}`] = det.count
						})
					if (d.code2.length) {
						temp.count2 = d.code2[0].countTwo
					}
					if (d.code10.length) {
						let sum = 0
						d.code10.forEach((c) => {
							if (c?.countThree && c?.countThree >= 0) {
								sum += c.countThree
							}
						})
						temp.count101 = sum
					}
					DATA.push(temp)
				})

				let temp = {
					name: 'ЖАМИ',
					count1: 0,
					count2: 0,
					count3: 0,
					count4: 0,
					count5: 0,
					count6: 0,
					count7: 0,
					count8: 0,
					count9: 0,
					count10: 0,
					count101: 0,
					count11: 0,
					count12: 0,
					count13: 0,
				}

				DATA.forEach((d) => {
					temp.count1 += d.count1
					temp.count2 += d.count2
					temp.count3 += d.count3
					temp.count4 += d.count4
					temp.count5 += d.count5
					temp.count6 += d.count6
					temp.count7 += d.count7
					temp.count8 += d.count8
					temp.count9 += d.count9
					temp.count10 += d.count10
					temp.count101 += d.count101
					temp.count11 += d.count11
					temp.count12 += d.count12
					temp.count13 += d.count13
				})
				DATA.push(temp)

				formExcel(DATA)
				setLoading(false)
			})
	}

	return (
		<button className={ft.export__btn} onClick={(e) => exportToExcel()}>
			<i className='far fa-file-excel mr-1' />
			{Translate(lang, 'global.exportExcel')}
			{loading && <Loading cl='ml-2' />}
		</button>
	)
}

export default ExportExcel
