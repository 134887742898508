import axiosInstance from './api';

const headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const homeApi = {
	all: (data, token) => {
		return axiosInstance.post(
			'/organizations/final-result',
			data,
			token
				? {
						headers: {
							Authorization: `Bearer ${token}`,
						},
				  }
				: {
						headers: {
							Authorization: `Bearer ${sessionStorage.getItem('token')}`,
						},
				  }
		)
	},
	ratings: (data, token) => {
		return axiosInstance.post(
			'/ratings/all',
			data,
			token
				? {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
				: {
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
		)
	},
	home: (data) => axiosInstance.post('/assignments/get-sum-exp', data, headers),
}
