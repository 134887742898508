import axiosInstance from '../api';

const api = '/doc-receiveds';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const docReceivedApi = {
	getList: (data) => axiosInstance.post(`${api}/all`, data, headers),
	delete: (id) => axiosInstance.delete(`${api}/${id}`, headers),
}
