import React from 'react'
import st from './loading.module.scss'

export default function Loading({ size, cl, big }) {
	return (
		<div className={big ? st.loading__big : st.loading__small}>
			<div className={`${st.loading} ${cl} ${size === 'lg' ? st.lg_l : size === 'md' ? st.md_l : st.sm_l}`}>
				<div className={`${st.loader} ${size === 'lg' ? st.lg : size === 'md' ? st.md : st.sm}`}>
					<div className={st.face}>
						<div className={st.circle}></div>
					</div>
				</div>
			</div>
		</div>
	)
}
