import st from './circle.module.scss';

export default function Circle({ num, col, bgCol, cl }) {
	return (
		<div
			className={`${st.circle} circle__${cl}`}
			style={{
				color: `rgb(${col})`,
				backgroundColor: `rgba(${bgCol ? `${bgCol}, 1` : `${col}, .1`})`,
			}}>
			{num}
		</div>
	)
}
