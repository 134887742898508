import React, { useMemo, useState } from 'react';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';

import { docStatisticApi } from '../../../../services/doc/docStatisticService';

import { ExcelCells, formatDate } from '../../../../utils';

import Translate from '../../../../components/lang';
import Loading from '../../../../components/loading';

import ft from '../../../../components/mainStyles.module.scss';

const ExportExcelTask = ({ lang, filter, period }) => {
	const [loading, setLoading] = useState(false);

	const fileName = 'Topshiriqlari statistikasi';
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

	const columns = useMemo(() => [
		{ header: Translate(lang, 'global.orgName'), key: 'name' },
		{ header: Translate(lang, 'status.NEW'), code: 1, key: 'count1' },
		{ header: Translate(lang, 'status.SEEN'), code: 2, key: 'count2' },
		{ header: Translate(lang, 'status.DONE'), code: 3, key: 'count3' },
		{ header: Translate(lang, 'status.REJECT'), code: 4, key: 'count4' },
		{ header: Translate(lang, 'status.CLOSE'), code: 5, key: 'count5' },
		{ header: Translate(lang, 'home.allTasks'), code: 6, key: 'count6' }
	], []);

	const fTableHead = () => {
		const temp = [];
		const	temp2 = [];

		columns.forEach(column => {
			temp.push(column.header);

			if (column.code) {
				temp2.push(column.code);
			} else {
				temp2.push('');
			}
		})

		return [temp2, temp];
	}

	const fTableBody = (data) => {
		let arr = [];

		data.forEach(element => {
			const temp = [];

			columns.forEach(column => {
				const value = element[column.key];
				temp.push(value);
			});

			arr.push(temp);
		});

		return [...arr];
	}

	const formExcel = (data) => {
		let workbook = new ExcelJs.Workbook();
		let worksheet = workbook.addWorksheet('Книга 1');

		const datas = [...fTableHead(), ...fTableBody(data)];

		worksheet.mergeCells('A1:O1');
		worksheet.getCell('A1').value = fileName;
		worksheet.getCell('A1').font = {
			bold: true,
			size: 14,
			name: 'Times New Roman'
		};
		worksheet.getCell('A1').alignment = { horizontal: 'center' };

		worksheet.mergeCells('A2:O2');
		worksheet.getCell('A2').value = `${formatDate(period.start)}-${formatDate(period.end)}`;
		worksheet.getCell('A2').font = {
			bold: true,
			size: 14,
			name: 'Times New Roman'
		};
		worksheet.getCell('A2').alignment = { horizontal: 'center' };

		worksheet.addRow([]);
		worksheet.addRows(datas);
		worksheet.mergeCells('A4:A5');
		worksheet.getCell('A4').value = Translate(lang, 'global.orgName');
		worksheet.getRow(4).alignment = { wrapText: true };

		columns.forEach((col, i) => {
			worksheet.getColumn(i + 1).width = 20;
			worksheet.getColumn(i + 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
		});

		datas.forEach((d, i) => {
			d.forEach((el, j) => {
				const text = `${ExcelCells(j)}${i + 4}`;

				if (i === 0 || i === 1) {
					worksheet.getCell(text).fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: {
							argb: 'D9D9D9'
						}
					};
					worksheet.getCell(text).font = {
						bold: true,
						size: 14,
						name: 'Times New Roman'
					};
				} else if (i === datas.length - 1) {
					worksheet.getCell(text).fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: {
							argb: 'FFFF00'
						}
					};
					worksheet.getCell(text).font = {
						bold: true,
						size: 14,
						name: 'Times New Roman'
					};
				} else {
					worksheet.getCell(text).font = {
						size: 14,
						name: 'Times New Roman'
					};
				}
				worksheet.getCell(text).border = {
					top: { style: 'thin', color: { argb: '00000000' } },
					left: { style: 'thin', color: { argb: '00000000' } },
					bottom: { style: 'thin', color: { argb: '00000000' } },
					right: { style: 'thin', color: { argb: '00000000' } }
				};
			});
		});

		workbook.xlsx.writeBuffer()
			.then(data => {
				const blob = new Blob([data], { type: fileType });
				FileSaver.saveAs(blob, fileName);
			});
	}

	const exportToExcel = () => {
		setLoading(true);

		docStatisticApi
			.exportToExcelTasks(period.id, filter)
			.then(res => {
				const DATA = [];

				res.data.data.forEach(item => {
					const temp = {
						name: item?.organization?.name[lang] || '',
						count1: item.NEW || 0,
						count2: item.SEEN || 0,
						count3: item.DONE || 0,
						count4: item.REJECT || 0,
						count5: item.CLOSE || 0,
						count6: item.totalCount || 0
					}

					DATA.push(temp);
				});

				let temp = {
					name: 'ЖАМИ',
					count1: 0,
					count2: 0,
					count3: 0,
					count4: 0,
					count5: 0,
					count6: 0
				};

				DATA.forEach(item => {
					temp.count1 += item.count1
					temp.count2 += item.count2
					temp.count3 += item.count3
					temp.count4 += item.count4
					temp.count5 += item.count5
					temp.count6 += item.count6
				});

				DATA.push(temp);

				formExcel(DATA);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<button className={ft.export__btn} onClick={exportToExcel}>
			<i className='far fa-file-excel mr-1' />
			{Translate(lang, 'global.exportExcel')}
			{loading && <Loading cl='ml-2' />}
		</button>
	)
}

export default ExportExcelTask
