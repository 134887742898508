import { useState } from 'react'
import CustomModal from '../../../components/customModal'
import { Row, Col, Form } from 'react-bootstrap'
import ft from '../../../components/mainStyles.module.scss'
import { expApi } from '../../../services/expertsService'
import Loading from '../../../components/loading'
import Translate from '../../../components/lang'

export default function ExpertEdit(props) {
	const { data, id, setShow, token, setData, setLoading, size, lang } = props,
		[err, setErr] = useState(false),
		[errPh, setErrPh] = useState(false),
		[errNum, setErrNum] = useState(false),
		[load, setLoad] = useState(false)

	const handleSubmit = (e) => {
		e.preventDefault()
		const val = e.target[3].value.match(/^(\+)([998]{3})([\d]{2})(\d+)/)
		if (val && val[4].length === 7) {
			setErrPh(false)
			if (Number(e.target[4].value)) {
				setErrNum(false)
				setErr(false)
				setLoad(true)
				const data = {
					fullName: e.target[0].value,
					position: {
						uz: e.target[1].value,
						ru: e.target[2].value,
					},
					phone: +e.target[3].value,
					additionalPhone: +e.target[4].value,
				}
				expApi
					.edit(data, id)
					.then((res) => {
						if (res.data.success) {
							setLoad(false)
							setLoading(true)
							expApi.all({ limit: 10, page: 1 }).then((res) => {
								if (res.data.success) {
									setData(res.data.data)
									setLoading(false)
								}
							})
							setShow(false)
						}
					})
					.catch((err) => {
						setErr(true)
						setLoad(false)
					})
			} else {
				setErrNum(true)
			}
		} else {
			setErrPh(true)
		}
	}

	return (
		<CustomModal {...props} modalTitle='Редактирование эксперта' size='xs'>
			<Form className={ft.form} onSubmit={handleSubmit}>
				<Form.Label className='w-100'>
					<div className={ft.lab}>{Translate(lang, 'table.expert')}</div>
					<Form.Control type='text' placeholder='F.I.O' defaultValue={data?.fullName} required />
				</Form.Label>
				<Form.Label className='w-100'>
					<div className={ft.lab}>{Translate(lang, 'experts.position')} (Uzb)</div>
					<Form.Control type='text' placeholder='' defaultValue={data?.position?.uz} required />
				</Form.Label>
				<Form.Label className='w-100'>
					<div className={ft.lab}>{Translate(lang, 'experts.position')} (Рус)</div>
					<Form.Control type='text' placeholder='' defaultValue={data?.position?.ru} required />
				</Form.Label>
				<Row>
					<Col md={8} className='mx-0'>
						<Form.Label>
							<div className={ft.lab}>{Translate(lang, 'experts.phoneNumber')}</div>
							<Form.Control
								type='tel'
								required
								style={
									errPh
										? {
												backgroundColor: 'rgba(255, 82, 82, 0.1)',
										  }
										: {}
								}
								defaultValue={`+${data?.phone}`}
							/>
						</Form.Label>
					</Col>
					<Col md={4} className='mx-0'>
						<Form.Label>
							<div className={ft.lab}>{Translate(lang, 'experts.internalNumber')}</div>
							<Form.Control
								type='tel'
								maxLength={4}
								required
								style={
									errNum
										? {
												backgroundColor: 'rgba(255, 82, 82, 0.1)',
										  }
										: {}
								}
								defaultValue={data?.additionalPhone}
							/>
						</Form.Label>
					</Col>
				</Row>
				{errPh ? <span className={ft.err__mes}>{Translate(lang, 'experts.internalNumber')}</span> : ''}
				{errNum ? <span className={ft.err__mes}>{Translate(lang, 'experts.wrongInternalN')}</span> : ''}
				<Row>
					<Col md={12}>
						<Form.Label>
							<div className={ft.lab}>{Translate(lang, 'global.login')}</div>
							<Form.Control
								type='text'
								required
								style={
									err
										? {
												backgroundColor: 'rgba(255, 82, 82, 0.1)',
										  }
										: {}
								}
								value={data?.login}
							/>
						</Form.Label>
					</Col>
				</Row>
				{err ? <span className={ft.err__mes}>{Translate(lang, 'login.err')}</span> : ''}
				<div className={ft.modalBtns}>
					<button
						className={ft.close__btn}
						type='button'
						onClick={() => {
							setShow(false)
							setLoad(false)
						}}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} d-flex align-items-center ml-3`} type='submit' disabled={load}>
						{Translate(lang, 'global.save')} {load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</Form>
		</CustomModal>
	)
}
