import React from 'react';

import Translate from "../../components/lang";
import Container from "../../components/container";

import ExportExcel from "./components/ExportExcel";

import ft from "../../components/mainStyles.module.scss";

const columns = [
  { header: 'Tashkilot nomi', key: 'name' },
  { header: 'Hammasi', code: 1, key: 'indicator1', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 1, key: 'indicator1', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 1, key: 'indicator1', nestedKey: 'rejected' },
  { header: 'Baho', code: 1, key: 'indicator1', nestedKey: 'ball' },
  { header: 'Xodimlar soni', code: 2, key: 'indicator2', nestedKey: 'employeeCount' },
  { header: 'O\'qitilgan xodimlar soni', code: 2, key: 'indicator2', nestedKey: 'trainedEmployeeCount' },
  { header: 'Baho', code: 2, key: 'indicator2', nestedKey: 'ball' },
  { header: 'Hammasi', code: 3, key: 'indicator3', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 3, key: 'indicator3', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 3, key: 'indicator3', nestedKey: 'rejected' },
  { header: 'Baho', code: 3, key: 'indicator3', nestedKey: 'ball' },
  { header: 'Hammasi', code: 4, key: 'indicator4', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 4, key: 'indicator4', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 4, key: 'indicator4', nestedKey: 'rejected' },
  { header: 'Baho', code: 4, key: 'indicator4', nestedKey: 'ball' },
  { header: 'Hammasi', code: 5, key: 'indicator5', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 5, key: 'indicator5', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 5, key: 'indicator5', nestedKey: 'rejected' },
  { header: 'Baho', code: 5, key: 'indicator5', nestedKey: 'ball' },
  { header: 'Hammasi', code: 6, key: 'indicator6', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 6, key: 'indicator6', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 6, key: 'indicator6', nestedKey: 'rejected' },
  { header: 'Baho', code: 6, key: 'indicator6', nestedKey: 'ball' },
  { header: 'Hammasi', code: 7, key: 'indicator7', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 7, key: 'indicator7', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 7, key: 'indicator7', nestedKey: 'rejected' },
  { header: 'Baho', code: 7, key: 'indicator7', nestedKey: 'ball' },
  { header: 'Hammasi', code: 8, key: 'indicator8', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 8, key: 'indicator8', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 8, key: 'indicator8', nestedKey: 'rejected' },
  { header: 'Baho', code: 8, key: 'indicator8', nestedKey: 'ball' },
  { header: 'Hammasi', code: 9, key: 'indicator9', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 9, key: 'indicator9', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 9, key: 'indicator9', nestedKey: 'rejected' },
  { header: 'Baho', code: 9, key: 'indicator9', nestedKey: 'ball' },
  { header: 'Joriy foydalanuvchilar', code: 10, key: 'indicator10', nestedKey: 'currentUser' },
  { header: 'Avvalgi foydalanuvchilar', code: 10, key: 'indicator10', nestedKey: 'previousUser' },
  { header: 'Baho', code: 10, key: 'indicator10', nestedKey: 'ball' },
  { header: 'Hammasi', code: 11, key: 'indicator11', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 11, key: 'indicator11', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 11, key: 'indicator11', nestedKey: 'rejected' },
  { header: 'Baho', code: 11, key: 'indicator11', nestedKey: 'ball' },
  { header: 'Hammasi', code: 12, key: 'indicator12', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 12, key: 'indicator12', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 12, key: 'indicator12', nestedKey: 'rejected' },
  { header: 'Baho', code: 12, key: 'indicator12', nestedKey: 'ball' },
  { header: 'Hammasi', code: 13, key: 'indicator13', nestedKey: 'all' },
  { header: 'Qabul qilinganlari', code: 13, key: 'indicator13', nestedKey: 'accepted' },
  { header: 'Qabul qilinmaganlari', code: 13, key: 'indicator13', nestedKey: 'rejected' },
  { header: 'Baho', code: 13, key: 'indicator13', nestedKey: 'ball' }
];

export default function Statistics(props) {
  const { lang, periods } = props;

  return (
    <div>
      <Container>
        <div className={ft.content__head}>
          <h5 className={ft.title}>{Translate(lang, 'markTimes.startDate')}</h5>
        </div>

        <div className={ft.table}>
          <table className={ft.table__main}>
            <thead>
              <tr>
                <td>#</td>
                <td>{Translate(lang, 'statistics.period')}</td>
                <td>{Translate(lang, 'statistics.file')}</td>
              </tr>
            </thead>
            <tbody>
              {periods.slice(1).map((period, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {`
                      ${period.year}-${Translate(lang, 'time.year')} 
                      ${period.half}-${Translate(lang, 'time.half')}
                    `}
                  </td>
                  <td>
                    <ExportExcel
                      {...{ lang, period, columns }}
                      fileName='Jamiyatda huquqiy madaniyatni yuksaltirish bo‘yicha tadbirlar haqida'
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {!periods && !periods.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
        </div>
      </Container>
    </div>
  )
}