import { Modal } from 'react-bootstrap';

import Translate from '../lang';

import st from './customModal.module.scss';
import ft from '../mainStyles.module.scss';

const icon = (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M18 6L6 18' stroke='#FF5252' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M6 6L18 18' stroke='#FF5252' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

const exp = (
	<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15'
			stroke='#29A0E3'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M7 10L12 15L17 10' stroke='#29A0E3' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M12 15V3' stroke='#29A0E3' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

export default function CustomModal(props) {
	const { show, setShow, modalTitle, size, children, lg, del, noHead, clBody, expBtn, lang, modalId } = props;

	return (
		<Modal
			id={modalId}
			size={size}
			show={show}
			backdrop='static'
			onHide={() => setShow(!show)}
			className={del ? 'd-flex align-items-center' : ''}
			style={del ? { height: '100vh' } : {}}>
			<Modal.Header className={`align-items-center justify-content-between ${del ? 'd-none' : 'd-flex'}`}>
				<h5 className={`m-0 ${noHead ? 'd-none' : 'd-block'}`} style={lg ? { fontSize: '24px' } : { fontSize: '20px' }}>
					{modalTitle}
				</h5>
				<div>
					<a href='' className={` ${expBtn ? ft.export__btn : 'd-none'} `} style={{ marginRight: '1.5em' }}>
						<span className='mr-0'>{Translate(lang, 'global.export')}</span>
						{exp}
					</a>
					<span style={{ cursor: 'pointer' }} onClick={() => setShow(false)}>
						{icon}
					</span>
				</div>
			</Modal.Header>
			<Modal.Body className={`${st.modalBody} ${clBody} pt-0 ${del ? 'w-100' : ''}`} style={{ height: 'fit-content' }}>
				{children}
			</Modal.Body>
			<style jsx>
				{`
					h5 {
						color: #07a287;
					}
				`}
			</style>
		</Modal>
	)
}
