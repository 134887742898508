import React, { useEffect, useState } from 'react'
import Container from '../../../components/container'
import ft from '../../../components/mainStyles.module.scss'
import Filter from '../../../components/filter'
import TaskCheckModal from './taskCheckModal'
import { Form } from 'react-bootstrap'
import CustomSelect from '../../../components/customSelect'
import { exp } from '../../../components/icons'
import Loading from '../../../components/loading'
import { tasksApi } from '../../../services/tasksService'
import { orgApi } from '../../../services/orgListService'
import { BeautyDate } from '../../../utils'
import Translate from '../../../components/lang'
import Pagin from '../../../components/pagin'
import TaskCheckListModal from './taskCheckListModal'

export default function TasksCheck(props) {
	const [data, setData] = useState([]),
		[options, setOptions] = useState([]),
		[selected, setSelected] = useState([]),
		[checkData, setCheckData] = useState({}),
		[show, setShow] = useState(false),
		[showFilter, setShowFilter] = useState(false),
		[showTaskCheckListModal, setShowTaskCheckListModal] = useState(false),
		[filter, setFilter] = useState({}),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[id, setId] = useState(false),
		[load, setLoad] = useState(false),
		[loading, setLoading] = useState(true),
		{ lang, periods, selPer, markTime } = props

	useEffect(() => {
		orgApi.getAll().then((res) => {
			if (res.data.success) {
				const DATA = []
				res.data.data.map((d) => {
					DATA.push({
						value: d._id,
						label: d.name.uz,
					})
				})
				setOptions(DATA)
			}
		})
	}, [])

	useEffect(() => {
		setLoading(true)
		tasksApi
			.check({
				...page,
				...filter,
				start: periods[selPer].start,
				end: periods[selPer].end,
				period: periods[selPer].id,
			})
			.then((res) => {
				if (res.data.success) {
					setPageNum(Math.ceil(res.data.num))
					setPc((page.page - 1) * page.limit)
					setData(res.data.data)
					setLoading(false)
				}
			})
	}, [page])

	const handleChangeStatus = (e) => {
		setLoad(true)
		tasksApi.edit({ status: e ? 2 : 3 }, id).then((res) => {
			if (res.data.success) {
				setLoad(false)
				setShow(false)
				setLoading(true)
				tasksApi.check(page).then((res) => {
					if (res.data.success) {
						setPageNum(Math.ceil(res.data.num))
						setPc(0)
						setData(res.data.data)
						setLoading(false)
					}
				})
			}
		})
	}

	function handleChange(selected) {
		setSelected(selected)
	}

	const handleFilter = (e) => {
		e.preventDefault()
		setLoading(true)
		setPage({ ...page, page: 1 })
		const selId = []
		selected.map((s) => {
			selId.push(s.value)
		})
		const data = {}
		if (selId.length) data.organizations = selId
		if (e.target[1].value) data.docName = e.target[1].value
		if (e.target[2].value !== 'all') data.status = +e.target[2].value
		if (e.target[3].value) data.start = e.target[3].value
		if (e.target[4].value) data.end = e.target[4].value

		setFilter(data)
		setShowFilter(false)
		tasksApi.check({ limit: page.limit, page: 1 }, data).then((res) => {
			if (res.data.success) {
				const DATA = res.data.data
				const temp = []
				DATA.forEach((d) => {
					if (d.status !== 0) temp.push(d)
				})
				setPageNum(Math.ceil(res.data.num))
				setPc(0)
				setData(temp)
				setLoading(false)
			}
		})
	}

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'taskCheck.title')}</h5>
					<div className={ft.btns}>
						<button className={ft.export__btn} onClick={() => setShowTaskCheckListModal(true)}>
							<span className='mr-1'>{Translate(lang, 'global.export')}</span> {exp}
						</button>
						<button className={`${ft.primary__btn} ml-2`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button>
					</div>
				</div>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'taskSend.taskMeaning')}</td>
								<td>{Translate(lang, 'taskSend.deadline')}</td>
								<td>{Translate(lang, 'taskSend.fileName')}</td>
								<td>{Translate(lang, 'global.orgName')}</td>
								<td className='text-center'>{Translate(lang, 'taskCheck.sentDate')}</td>
								<td className='text-center'>{Translate(lang, 'orgAll.status')}</td>
								{selPer === 0 && !!!markTime?.status && (
									<td className={`text-center  ${ft.removeOnPrint}`}>{Translate(lang, 'table.action')}</td>
								)}
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, index) => (
									<tr>
										<td>{pc + index + 1}</td>
										<td>{d.description}</td>
										<td className='text-nowrap'>{BeautyDate(d.deadline)}</td>
										<td>{d.docName}</td>
										<td>{d.org.name[lang]}</td>
										<td className='text-center'>{d?.complete ? BeautyDate(d?.complete) : '---'}</td>
										<td className='text-center'>
											{d.status === 0 ? (
												<span className={ft.isNotDone}>{Translate(lang, 'home.uncompleted')}</span>
											) : d.status === 1 ? (
												<span className={ft.isWaiting}>{Translate(lang, 'taskCheck.fileSent')}</span>
											) : d.status === 2 ? (
												<span className={ft.isDone}>{Translate(lang, 'homeAll.received')}</span>
											) : (
												<span className={ft.isNotDone}>{Translate(lang, 'homeAll.notReceived')}</span>
											)}
										</td>
										{/* {selPer === 0 && !!!markTime?.status && ( */}
										{selPer === 0 && (
											<td className={`text-center  ${ft.removeOnPrint}`}>
												<button
													className={ft.info__btn}
													disabled={d.status === 0}
													onClick={() => {
														setId(d._id)
														setCheckData(d)
														setShow(true)
													}}>
													{Translate(lang, 'taskCheck.check')}
												</button>
											</td>
										)}
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && !!data.length && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			{!!show && (
				<TaskCheckModal {...props} show={show} setShow={setShow} id={id} checkData={checkData} load={load} handleChangeStatus={handleChangeStatus} />
			)}

			{!!showTaskCheckListModal && <TaskCheckListModal {...props} show={showTaskCheckListModal} setShow={setShowTaskCheckListModal} filter={filter} />}

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.orgName')}</div>
						<CustomSelect {...props} selected={selected} handleChange={handleChange} options={options} />
					</Form.Label>
					<Form.Label className='my-2'>
						<Form.Control type='text' placeholder={Translate(lang, 'global.fileName')} defaultValue={filter?.docName} />
					</Form.Label>

					<select className='my-2' defaultChecked={filter?.status}>
						<option value='all'>{Translate(lang, 'global.all')}</option>
						<option value={1}>{Translate(lang, 'homeAll.fileSent')}</option>
						<option value={2}>{Translate(lang, 'homeAll.received')}</option>
						<option value={3}>{Translate(lang, 'homeAll.notReceived')}</option>
					</select>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.from')}</div>
						<Form.Control type='date' defaultValue={filter?.start} />
					</Form.Label>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.to')}</div>
						<Form.Control type='date' defaultValue={filter?.end} />
					</Form.Label>
					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'orgList.search')}
					</button>
				</Form>
			</Filter>
		</div>
	)
}
