import axiosInstance from './api';

const api = '/suggestions';

const	headers = {
   headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
   }
};

export const suggestionApi = {
   getList: (data) => axiosInstance.post(`${api}/all`, data, headers),
}
