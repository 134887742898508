import axiosInstance from './api';

const api = '/assignments';

const headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const tasksApi = {
	all: (page, filter) => axiosInstance.post(`${api}/filter`, { ...page, ...filter }, headers),
	check: (page, filter) => axiosInstance.post(`${api}/filter-check`, { ...page, ...filter }, headers),
	checkRight: (page, filter) => axiosInstance.post(`${api}/filter-stat`, { ...page, ...filter }, headers),
	stat: (page, filter) => axiosInstance.post(`${api}/get-stat`, { ...page, ...filter }, headers),
	statExp: (data) => axiosInstance.post('/organizations/modal-all', data, headers),
	statModal: (data) => axiosInstance.post(`${api}/modal`, data, headers),
	add: (data) => axiosInstance.post(`${api}/create`, data, headers),
	edit: (data, id) => axiosInstance.put(`${api}/update/${id}`, data, headers),
	delete: (id) => axiosInstance.delete(`${api}/delete/${id}`, headers),
	delFile: (file, id) => axiosInstance.put(`${api}/edit/${file}/${id}`, headers),
}
