import React, { useEffect, useState } from 'react';
import { Link, Route } from 'react-router-dom';

import { exp } from '../../components/icons';

import { orgApi } from '../../services/orgListService';
import { orgTypesApi } from "../../services/orgTypesService";

import Limit from '../../components/limit';
import Pagin from '../../components/pagin';
import Translate from '../../components/lang';
import Loading from '../../components/loading';
import Container from '../../components/container';

import OrgAdd from './orgAdd';
import OrgAll from './orgAll';
import OrgInfo from './orgInfo';
import OrgMark from './orgMark';
import OrgStat from './orgStat';
import OrgListModal from './orgListModal';

import st from '../../components/input/input.module.scss';
import ft from '../../components/mainStyles.module.scss';

export default function OrgList(props) {
	return (
		<div>
			<Route path='/orgList' exact render={() => <OrgListMain {...props} />} />
			<Route path='/orgList/all' exact render={() => <OrgAll {...props} />} />
			<Route path='/orgList/mark' exact render={(temp) => <OrgMark {...props} {...temp} />} />
		</div>
	);
}

function OrgListMain(props) {
	const [data, setData] = useState([]),
		[types, setTypes] = useState([]),
		[type, setType] = useState('all'),
		[status, setStatus] = useState('all'),
		[statTitle, setStatTitle] = useState([]),
		[show, setShow] = useState(false),
		[change, setChange] = useState(false),
		[showInfo, setShowInfo] = useState(false),
		[showStat, setShowStat] = useState(false),
		[showListModal, setShowListModal] = useState(false),
		[loading, setLoading] = useState(true),
		[orgInfo, setOrgInfo] = useState({}),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[name, setName] = useState(''),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[id, setId] = useState(null),
		{ lang, selPer, periods, token } = props;

	const handleFilter = (e) => {
		e.preventDefault();

		setLoading(true);

		setPage({ ...page, page: 1 });
		setName(e.target[0].value);

		const payload = {
			page: 1,
			limit: page.limit,
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?.id,
			name: e.target[0].value
		};

		if (type !== 'all') {
			payload.type = type;
		}

		if (status !== 'all') {
			payload.status = status;
		}

		orgApi.all(payload, token)
			.then(res => {
				setPageNum(Math.ceil(res.data.num));
				setPc(0);
				setData(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setLoading(true);

		const payload = {
			...page,
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?.id,
		};

		if (type !== 'all') {
			payload.type = type;
		}

		if (status !== 'all') {
			payload.status = status;
		}

		if (name.length) {
			payload.name = name;
		}

		orgApi.all(payload, token)
			.then(res => {
				setPageNum(Math.ceil(res.data.num));
				setPc((page.page - 1) * page.limit);
				setData(res.data.data);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [page, change, type, status]);

	useEffect(() => {
		orgTypesApi.allActive()
			.then((res) => {
				setTypes(res.data.data);
			})
			.catch(e => {
				console.log(e);
			});
	}, []);

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>
						{Translate(lang, 'orgList.title')}
					</h5>

					<div className={ft.btns}>
						<button className={ft.export__btn} onClick={() => setShowListModal(true)}>
							<span className='mr-1'>{Translate(lang, 'global.export')}</span> {exp}
						</button>

						<div className='mx-2'>
							<Limit {...props} setPage={setPage} />
						</div>

						<div className='mx-2'>
							<select
								className={ft.select}
								onChange={e => {
									setPage({ limit: 10, page: 1 });
									setType(e.target.value);
								}}
							>
								<option value='all'>{Translate(lang, 'global.all')}</option>
								{types.map(type => (
									<option value={type._id} key={type._id}>{type.name[lang]}</option>
								))}
							</select>
						</div>

						<div className="mx-2">
							<select
								className={ft.select}
								onChange={e => {
									setPage({ limit: 10, page: 1 });
									setStatus(e.target.value);
								}}
							>
								<option value='all'>{Translate(lang, 'global.all')}</option>
								<option value={0}>{Translate(lang, 'global.active')}</option>
								<option value={1}>{Translate(lang, 'global.inactive')}</option>
							</select>
						</div>

						<form className='m-0' onSubmit={handleFilter}>
							<div className={`position-relative ${st.inputContainer}`}>
								<input
									type='text'
									placeholder={Translate(lang, 'orgList.search')}
									className={st.input}
								/>
								<button type='submit' className={st.icon}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
											stroke='#232323'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M20.9999 21L16.6499 16.65'
											stroke='#232323'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</button>
							</div>
						</form>

						<button className={ft.add__btn} onClick={() => setShow(true)}>
							<span className={ft.icon}>+</span>
							{Translate(lang, 'global.add')}
						</button>
					</div>
				</div>

				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'orgList.orgName')}</td>
								<td>{Translate(lang, 'orgList.orgType')}</td>
								<td>{Translate(lang, 'orgList.phoneNumber')}</td>
								<td>{Translate(lang, 'orgList.email')}</td>
								<td>{Translate(lang, 'orgList.marked')}</td>
								<td>{Translate(lang, 'orgList.wayOfMarking')}</td>
								<td className={`text-center pl-0  ${ft.removeOnPrint}`}>
									{Translate(lang, 'global.status')}
								</td>
								<td className={`text-center pl-0  ${ft.removeOnPrint}`}>
									{Translate(lang, 'table.mark')}
								</td>
								<td className={`text-center pl-0  ${ft.removeOnPrint}`}>
									{Translate(lang, 'global.more')}
								</td>
							</tr>
						</thead>
						<tbody>
							{ loading ? (
									<tr>
										<td colSpan={9}>
											<Loading big={true} size='md' cl='mt-3' />
										</td>
									</tr>
								) : data?.map((d, i) => (
								<tr
									onDoubleClick={() => {
										setStatTitle(d.name[lang]);
										setId(d._id);
										setShowStat(true);
									}}>
									<td>{pc + i + 1}</td>
									<td>{d.name[lang]}</td>
									<td>{d?.organizationType?.length ? d?.organizationType[0]?.name[lang] : '---'}</td>
									<td className='text-nowrap'>
										{d?.phoneNumber && d?.phoneNumber != 0 ? d.phoneNumber : '---'}
									</td>
									<td>{d.email ? d.email : '---'}</td>
									<td>
										{d?.count?.length
											? `${d?.count[0]}/${d?.test?.length ? '14' : '13'}`
											: `0/${d?.test?.length ? '14' : '13'}`}
									</td>
									<td>
										{d?.test?.length
											? Translate(lang, 'orgList.all')
											: Translate(lang, 'orgList.notAll')}
									</td>
									<td className={`text-center  ${ft.removeOnPrint}`}>
										{!d?.status
											? '---'
											: d.status === '0'
											? <div className={ft.mark__active}>{Translate(lang, 'global.active')}</div>
											: <div className={ft.mark__inactive}>{Translate(lang, 'global.inactive')}</div>
										}
									</td>
									<td className={`text-center  ${ft.removeOnPrint}`}>
										<button className={ft.mark__btn}>
											<Link to={`/orgList/all?id=${d._id}`}>
												{Translate(lang, 'table.mark')}
											</Link>
										</button>
									</td>
									<td className={`text-center  ${ft.removeOnPrint}`}>
										<button
											className={ft.info__btn}
											onClick={() => {
												setId(d._id);
												setOrgInfo(d);
												setShowInfo(true);
											}}
										>
											{Translate(lang, 'global.more')}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					{!loading && !data?.length &&
						<h6 className={ft.noData}>
							{Translate(lang, 'table.notFound')}
						</h6>
					}

					{!loading &&
						<Pagin
							pageNum={pageNum}
							page={page}
							setPage={setPage}
						/>
					}
				</div>
			</Container>

			{!!show && (
				<OrgAdd
					{...props}
					show={show}
					types={types}
					setShow={setShow}
					setChange={setChange}
				/>
			)}

			{!!showInfo && (
				<OrgInfo
					{...props}
					id={id}
					types={types}
					show={showInfo}
					setOrgInfo={setOrgInfo}
					orgInfo={orgInfo}
					setShow={setShowInfo}
					setChange={setChange}
				/>
			)}

			{!!showStat &&
				<OrgStat
					{...props}
					show={showStat}
					setShow={setShowStat}
					statTitle={statTitle}
					id={id}
				/>
			}

			{!!showListModal &&
				<OrgListModal
					{...props}
					show={showListModal}
					setShow={setShowListModal}
					name={name}
				/>
			}
		</div>
	);
}
