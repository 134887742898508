import ft from '../../../../components/mainStyles.module.scss';
import Circle from '../../../../components/circle';
import { Link } from 'react-router-dom';
import Translate from '../../../../components/lang';
import Loading from '../../../../components/loading';
import { BeautyDate } from '../../../../utils';

export default function LeftSide(props) {
	const { lang, history, data, loading, isHund, sumOfAll } = props,
		colBlack = '51, 51, 51',
		colRed = '255, 62, 62',
		colYell = '255, 166, 62',
		colGreen = '7, 162, 135';

	const firstColor = (score) => {
		if (score === 0) return colBlack;
		else if (score === 1) return colRed;
		else if (score === 2 || score === 3) return colYell;
		else if (score >= 4) return colGreen;
	}; //1

	const secColor = (max, score) => {
		const temp = (score * 100) / max;
		if (temp === 0) return colBlack;
		else if (temp > 0 && temp <= 30) return colRed;
		else if (temp > 30 && temp <= 70) return colYell;
		else if (temp > 71) return colGreen;
	}; //2

	const thirdColor = (max, score) => {
		const temp = (score * 100) / max;
		if (temp === 0) return colBlack;
		else if (temp > 0 && temp <= 42.9) return colRed;
		else if (temp > 42.9 && temp <= 71.5) return colYell;
		else if (temp > 71.5) return colGreen;
	}; //3 & 4

	const fifthColor = (max, score) => {
		const temp = (score * 100) / max;
		if (temp === 0) return colBlack;
		else if (temp > 0 && temp <= 37.5) return colRed;
		else if (temp > 37.5 && temp <= 87.5) return colYell;
		else if (temp > 87.5) return colGreen;
	}; //5

	const sixColor = (max, score) => {
		const temp = (score * 100) / max;
		if (temp === 0) return colBlack;
		else if (temp > 0 && temp <= 35) return colRed;
		else if (temp > 35 && temp <= 70) return colYell;
		else if (temp > 70) return colGreen;
	}; //6 & 7 & 11

	const eightColor = (max, score) => {
		const temp = (score * 100) / max;
		if (temp === 0) return colBlack;
		else if (temp > 0 && temp <= 40) return colRed;
		else if (temp > 40 && temp <= 70) return colYell;
		else if (temp > 70) return colGreen;
	}; //8 & 9 & 10

	const twelfColor = (max, score) => {
		const temp = (score * 100) / max;
		if (temp === 0) return colBlack;
		else if (temp > 0 && temp <= 41.7) return colRed;
		else if (temp > 41.7 && temp <= 75) return colYell;
		else if (temp > 75) return colGreen;
	}; //12

	const thrteenColor = (max, score) => {
		const temp = (score * 100) / max;
		if (temp === 0) return colBlack;
		else if (temp > 0 && temp <= 33.35) return colRed;
		else if (temp > 33.35 && temp <= 66.67) return colYell;
		else if (temp > 66.67) return colGreen;
	}; //13

	return (
		<div className='p-0'>
			<div className={ft.content__head}>
				<div className={ft.table}>
					<table className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'orgAll.indicator')}</td>
								<td className='text-center'>{Translate(lang, 'orgAll.maxPoint')}</td>
								<td className='text-center'>{Translate(lang, 'table.expert')}</td>
								<td className='text-center'>{Translate(lang, 'orgAll.status')}</td>
								<td className='text-center'>{Translate(lang, 'orgAll.date')}</td>
								<td className='text-center'>{Translate(lang, 'table.action')}</td>
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, index) => {
									if (index !== 13)
										return (
											<tr>
												<td>1.{index + 1}</td>
												<td>{d.title[lang]}</td>
												<td>
													<div className='d-flex justify-content-center'>
														<Circle num={d.maxNum} col={'7, 162, 135'} cl='table' fs={15} />
													</div>
												</td>
												<td className='text-center'>
													{d?.expert?.fullName?.length ? d.expert.fullName : '---'}
												</td>
												<td>
													<div className='d-flex justify-content-center'>
														{+d?.score >= 0 ? (
															index === 0 ? (
																<Circle
																	num={d.score}
																	col={firstColor(d.score)}
																	cl='table'
																	fs={15}
																/>
															) : index === 1 ? (
																<Circle
																	num={d.score}
																	col={secColor(d.maxNum, d.score)}
																	cl='table'
																	fs={15}
																/>
															) : index === 2 || index === 3 ? (
																<Circle
																	num={d.score}
																	col={thirdColor(d.maxNum, d.score)}
																	cl='table'
																	fs={15}
																/>
															) : index === 4 ? (
																<Circle
																	num={d.score}
																	col={fifthColor(d.maxNum, d.score)}
																	cl='table'
																	fs={15}
																/>
															) : index === 5 || index === 6 || index === 10 ? (
																<Circle
																	num={d.score}
																	col={sixColor(d.maxNum, d.score)}
																	cl='table'
																	fs={15}
																/>
															) : index === 7 || index === 8 || index === 9 ? (
																<Circle
																	num={d.score}
																	col={eightColor(d.maxNum, d.score)}
																	cl='table'
																	fs={15}
																/>
															) : index === 11 ? (
																<Circle
																	num={d.score}
																	col={twelfColor(d.maxNum, d.score)}
																	cl='table'
																	fs={15}
																/>
															) : (
																<Circle
																	num={d.score}
																	col={thrteenColor(d.maxNum, d.score)}
																	cl='table'
																	fs={15}
																/>
															)
														) : (
															<p className='text-danger m-0'>
																{Translate(lang, 'orgMark.notMarked')}
															</p>
														)}
													</div>
												</td>
												<td className='text-nowrap text-center'>
													{d.date.length ? BeautyDate(d.date) : ''}
												</td>
												<td>
													<button
														className={ft.info__btn}
														style={{
															padding: '5px 15px',
															borderRadius: '20px',
														}}>
														<Link
															to={`/orgList/mark?id=${history.location.search.slice(
																4
															)}&code=${index + 1}&isHund=${isHund}`}>
															{Translate(lang, 'home.seeAll')}
														</Link>
													</button>
												</td>
											</tr>
										);
								})
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
							{!loading && (
								<tr>
									<td></td>
									<td></td>
									<td>
										<h5 className='text-center'>{isHund ? 100 : 70}</h5>
									</td>
									<td></td>
									<td>
										<h5 className='text-center'>{sumOfAll}</h5>
									</td>
									<td></td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
