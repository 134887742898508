import React, { useEffect, useState } from 'react'
import Container from '../../../components/container'
import ft from '../../../components/mainStyles.module.scss'
import TaskSendAdd from './taskSendAdd'
import Filter from '../../../components/filter'
import Del from '../../../components/del'
import Loading from '../../../components/loading'
import { tasksApi } from '../../../services/tasksService'
import { del, edit } from '../../../components/icons'
import TaskSendEdit from './taskSendEdit'
import { orgApi } from '../../../services/orgListService'
import CustomSelect from '../../../components/customSelect'
import { Form } from 'react-bootstrap'
import { BeautyDate } from '../../../utils'
import Translate from '../../../components/lang'
import Pagin from '../../../components/pagin'
import TaskSendListModal from './taskSendListModal'
import { exp } from '../../../components/icons'
import config from "../../../config";

export default function TasksSend(props) {
	const [data, setData] = useState([]),
		[options, setOptions] = useState([]),
		[activeOptions, setActiveOptions] = useState([]),
		[selected, setSelected] = useState([]),
		[editData, setEditData] = useState({}),
		[show, setShow] = useState(false),
		[showDel, setShowDel] = useState(false),
		[showEdit, setShowEdit] = useState(false),
		[showFilter, setShowFilter] = useState(false),
		[showTaskSendListModal, setShowTaskSendListModal] = useState(false),
		[filter, setFilter] = useState({}),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[id, setId] = useState(false),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[load, setLoad] = useState(false),
		[loading, setLoading] = useState(true),
		{ lang, user, periods, selPer, markTimes } = props

	useEffect(() => {
		orgApi.getAll().then((res) => {
			if (res.data.success) {
				const DATA = [];
				const activeData = [];

				res.data.data.map((d) => {
					if (d.status === "0") {
						activeData.push({
							value: d._id,
							label: d.name.uz,
						});
					}

					DATA.push({
						value: d._id,
						label: d.name.uz,
					});
				});

				setOptions(DATA);
				setActiveOptions(activeData);
			}
		})
	}, [])

	useEffect(() => {
		setLoading(true)
		tasksApi
			.all(
				{
					...page,
				},
				filter
			)
			.then((res) => {
				if (res.data.success) {
					setPageNum(Math.ceil(res.data.num))
					setPc((page.page - 1) * page.limit)
					setData(res.data.data)
					setLoading(false)
				}
			})
	}, [page])

	const handleDelete = (id) => {
		setLoad(true)
		tasksApi.delete(id).then((res) => {
			if (res.data.success) {
				setLoad(false)
				setShowDel(false)
				setLoading(true)
				tasksApi.all({ limit: 10, page: 1 }, filter).then((res) => {
					if (res.data.success) {
						setPageNum(Math.ceil(res.data.num))
						setPc(0)
						setData(res.data.data)
						setLoading(false)
					}
				})
			}
		})
	}

	function handleChange(selected) {
		setSelected(selected)
	}

	const handleFilter = (e) => {
		e.preventDefault()
		setLoading(true)
		setPage({ ...page, page: 1 })
		const selId = []
		selected.map((s) => {
			selId.push(s.value)
		})
		const data = {}
		if (selId.length) data.organizations = selId
		if (e.target[1].value) data.docName = e.target[1].value
		if (e.target[2].value !== 'all') data.status = +e.target[2].value
		if (e.target[3].value) data.start = e.target[3].value
		if (e.target[4].value) data.end = e.target[4].value
		setFilter(data)
		setShowFilter(false)
		tasksApi.all({ limit: 10, page: 1 }, data).then((res) => {
			if (res.data.success) {
				setPageNum(Math.ceil(res.data.num))
				setPc(0)
				setLoading(false)
				setData(res.data.data)
			}
		})
	}

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'taskSend.title')}</h5>
					<div className={ft.btns}>
						<button className={ft.export__btn} onClick={() => setShowTaskSendListModal(true)}>
							<span className='mr-1'>{Translate(lang, 'global.export')}</span> {exp}
						</button>
						<button className={`${ft.primary__btn} ml-2`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button>
						{selPer === 0 && !!!markTimes?.status && (
							<button className={ft.add__btn} onClick={() => setShow(true)}>
								<span className={ft.icon}>+</span> {Translate(lang, 'global.add')}
							</button>
						)}
					</div>
				</div>

				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'taskSend.taskMeaning')}</td>
								<td className='text-center'>{Translate(lang, 'taskSend.deadline')}</td>
								<td>{Translate(lang, 'global.fileName')}</td>
								<td>{Translate(lang, 'table.file')}</td>
								<td>{Translate(lang, 'global.orgName')}</td>
								<td>{Translate(lang, 'table.expert')}</td>
								<td className={`text-center  ${ft.removeOnPrint}`}>{Translate(lang, 'taskCheck.sentDate')}</td>
								{selPer === 0 && !!!markTimes?.status && <td className='text-center'>{Translate(lang, 'table.action')}</td>}
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, index) => (
									<tr>
										<td>{pc + index + 1}</td>
										<td>{d.description}</td>
										<td className='text-nowrap text-center'>{BeautyDate(d.deadline)}</td>
										<td>{d.docName}</td>
										<td className='text-nowrap'>
											{d?.files.map((i) => {
												return (
													<div>
														<a target='_blank' href={`${config.api.baseUrl}${i?.path}`}>
															{i?.path?.slice(15)}
														</a>
													</div>
												)
											})}
										</td>
										<td>{d?.org?.name[lang]}</td>
										<td>{d?.exp?.fullName}</td>
										<td className='text-center'>{d?.complete ? BeautyDate(d?.complete) : '---'}</td>
										{/* {selPer === 0 && !!!markTimes?.status && ( */}
										<td className={`text-center  ${ft.removeOnPrint}`}>
											{selPer === 0 && d.status === 0 ? (
												<div className='d-flex'>
													<button
														className={ft.edit__btn}
														onClick={() => {
															setId(d._id)
															setEditData(d)
															setShowEdit(true)
														}}>
														{edit}
													</button>
													<button
														className={ft.del__btn}
														onClick={() => {
															setId(d._id)
															setShowDel(true)
														}}>
														{del}
													</button>
												</div>
											) : (
												'---'
											)}
										</td>
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			{!!show &&
				<TaskSendAdd
					{...props}
					page={page}
					show={show}
					setShow={setShow}
					setPageNum={setPageNum}
					setPc={setPc}
					setLoading={setLoading}
					setData={setData}
					options={activeOptions}
				/>
			}

			{!!showEdit &&
				<TaskSendEdit
					{...props}
					id={id}
					show={showEdit}
					setShow={setShowEdit}
					setData={setData}
					setLoading={setLoading}
					data={editData}
				/>
			}

			{!!showDel &&
				<Del
					{...props}
					id={id}
					load={load}
					show={showDel}
					setShow={setShowDel}
					handleDelete={handleDelete}
				/>
			}

			{!!showTaskSendListModal &&
				<TaskSendListModal
					{...props}
					show={showTaskSendListModal}
					setShow={setShowTaskSendListModal}
					filter={filter}
				/>
			}

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.orgName')}</div>
						<CustomSelect lang={lang} selected={selected} handleChange={handleChange} options={options} />
					</Form.Label>
					<Form.Label className='my-2'>
						<Form.Control type='text' defaultValue={filter.name} placeholder={Translate(lang, 'global.fileName')} />
					</Form.Label>
					<select className='my-2' defaultChecked={filter?.status}>
						<option value='all'>{Translate(lang, 'global.all')}</option>
						<option value={1}>{Translate(lang, 'homeAll.fileSent')}</option>
						<option value={2}>{Translate(lang, 'homeAll.received')}</option>
						<option value={3}>{Translate(lang, 'homeAll.notReceived')}</option>
					</select>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.from')}</div>
						<Form.Control type='date' defaultValue={filter.start} />
					</Form.Label>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.to')}</div>
						<Form.Control type='date' defaultValue={filter.end} />
					</Form.Label>
					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'orgList.search')}
					</button>
				</Form>
			</Filter>
		</div>
	)
}
