import CustomModal from '../../../components/customModal';
import ft from '../../../components/mainStyles.module.scss';
import Translate from '../../../components/lang';
import ExportPdf from '../../../components/exportPdf';
import { useEffect, useState } from 'react';
import Loading from '../../../components/loading';
import { sessionsApi } from '../../../services/sessionsService';
import { BeautyFullTime } from '../../../utils';
import { logsApi } from '../../../services/logsService';

export default function LogsListModal(props) {
	const [data, setData] = useState([]),
		[load, setLoad] = useState(true),
		{ lang, show } = props;

	useEffect(() => {
		setLoad(true);
		logsApi.all({ page: 1, limit: 10000 }).then((res) => {
			setData(res.data.data);
			setLoad(false);
		});
	}, [show]);

	return (
		<CustomModal {...props} size='lg'>
			<div className={`${ft.content__head} justify-content-between`}>
				<div className={`${ft.btns} w-100 justify-content-end`}>
					<ExportPdf {...props} title={Translate(lang, 'orgList.title')} id='sessionsListModal' />
				</div>
			</div>
			<div id='sessionsListModal'>
				{!load ? (
					<div className={ft.table}>
						<table id='table' className={ft.table__main}>
							<thead className='text-center'>
								<tr>
									<td>#</td>
									<td className='text-left'>{Translate(lang, 'global.login')}</td>
									<td className='text-center'>{Translate(lang, 'logs.section')}</td>
									<td className='text-center'>{Translate(lang, 'logs.name')}</td>
									<td className='text-center'>{Translate(lang, 'table.action')}</td>
									<td className='text-center'>{Translate(lang, 'logs.time')}</td>
								</tr>
							</thead>
							<tbody className='text-center'>
								{data.map((d, i) => (
									<tr>
										<td>{i + 1}</td>
										<td className='text-left'>{d.user}</td>
										<td>{d.category}</td>
										<td>{d.product}</td>
										<td>
											{d.action == '1' ? (
												<span className='text-success'>{Translate(lang, 'logs.added')}</span>
											) : d.action == '2' ? (
												<span className='text-warning'>{Translate(lang, 'logs.edited')}</span>
											) : (
												<span className='text-danger'>{Translate(lang, 'logs.del')}</span>
											)}
										</td>
										<td>{BeautyFullTime(d.createdAt)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className='d-flex justify-content-center'>
						<Loading size='md' cl='text-center' />
					</div>
				)}
			</div>
		</CustomModal>
	);
}
