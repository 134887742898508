import axiosInstance from './api';

const api = '/organization-types';

const headers = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  },
}

export const orgTypesApi = {
  allActive: () => {
    return axiosInstance.get(`${api}/get-all`, headers)
  },
  all: (d) => {
    return axiosInstance.get(`${api}/all?limit=${d.limit}&page=${d.page}`, headers)
  },
  add: (data) => axiosInstance.post(`${api}/create`, data, headers),
  edit: (data, id) => axiosInstance.put(`${api}/update/${id}`, data, headers),
}
