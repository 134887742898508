import axiosInstance from './api';

const api = '/recommendations';

const headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
}

export const advicesApi = {
	all: (page, token) => {
		return axiosInstance.get(
			`${api}/all?limit=${page.limit}&page=${page.page}`,
			{
				headers: {
					Authorization: `Bearer ${token ? token : sessionStorage.getItem('token')}`,
				},
			},
			{
				headers: {
					Authorization: `Bearer ${token ? token : sessionStorage.getItem('token')}`,
				},
			}
		)
	},
	add: (data) => axiosInstance.post(`${api}/create`, data, headers),
	edit: (data, id) => axiosInstance.put(`${api}/update/${id}`, data, headers),
	delete: (id) => axiosInstance.delete(`${api}/delete/${id}`, headers),
	delFile: (file, id) => axiosInstance.put(`${api}/edit/${file}/${id}`, headers),
}
