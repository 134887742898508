import Container from '../../components/container';
import ft from '../../components/mainStyles.module.scss';
import React, { useEffect, useState } from 'react';
import { expApi } from '../../services/expertsService';
import Loading from '../../components/loading';
import ExpertAdd from './expertAdd';
import Del from '../../components/del';
import Translate from '../../components/lang';
import ExpertEditPass from './expertEditPass';
import { edit, del, block } from '../../components/icons';
import ExpertEdit from './expertEdit';
import Pagin from '../../components/pagin';

export default function Experts(props) {
	const [data, setData] = useState([]),
		[editData, setEditData] = useState({}),
		[show, setShow] = useState(false),
		[showEdit, setShowEdit] = useState(false),
		[showDel, setShowDel] = useState(false),
		[showPass, setShowPass] = useState(false),
		[loading, setLoading] = useState(true),
		[load, setLoad] = useState(false),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[id, setId] = useState(null),
		{ lang, user } = props;

	useEffect(() => {
		setLoading(true);
		expApi.all(page).then((res) => {
			setData(res.data.data);
			setPageNum(Math.ceil(res.data.num));
			setPc((page.page - 1) * page.limit);
			setLoading(false);
		});
	}, [page]);

	const handleDelete = (id) => {
		setLoad(true);
		expApi.delete(id).then((res) => {
			if (res.data.success) {
				setLoad(false);
				setShowDel(false);
				expApi.all({ limit: 10, page: 1 }).then((res) => {
					if (res.data.success) {
						setPageNum(Math.ceil(res.data.num));
						setPc(0);
						setData(res.data.data);
						setLoading(false);
					}
				});
			}
		});
	};

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'experts.title')}</h5>
					<div className={ft.btns}>
						<button className={ft.add__btn} onClick={() => setShow(true)}>
							<span className={ft.icon}>+</span>
							{Translate(lang, 'global.add')}
						</button>
					</div>
				</div>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'table.expert')}</td>
								<td>{Translate(lang, 'experts.position')}</td>
								<td>{Translate(lang, 'experts.phoneNumber')}</td>
								<td>{Translate(lang, 'experts.internalNumber')}</td>
								<td>{Translate(lang, 'global.login')}</td>
								<td className={`text-center  ${ft.removeOnPrint}`}>{Translate(lang, 'table.action')}</td>
							</tr>
						</thead>
						{!loading ? (
							<tbody>
								{data.map((d, i) => (
									<tr>
										<td>{pc + i + 1}</td>
										<td>{d.fullName}</td>
										<td>{d.position[lang]}</td>
										<td className='text-nowrap'>+{d.phone}</td>
										<td className='text-nowrap'>{d.additionalPhone}</td>
										<td>{d.login}</td>
										<td className={`text-center  ${ft.removeOnPrint}`}>
											<div className='d-flex justify-content-center'>
												<button
													className={ft.edit__btn}
													onClick={() => {
														setId(d._id);
														setEditData(d);
														setShowEdit(true);
													}}>
													{edit}
												</button>
												<button
													className={ft.edit__btn}
													onClick={() => {
														setId(d._id);
														setShowPass(true);
													}}>
													{block}
												</button>
												{user._id !== d._id && (
													<button
														className={ft.del__btn}
														onClick={() => {
															setId(d._id);
															setShowDel(true);
														}}>
														{del}
													</button>
												)}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						) : (
							<Loading big={true} size='md' cl='mt-3' />
						)}
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>
			<ExpertAdd
				{...props}
				show={show}
				setShow={setShow}
				setPageNum={setPageNum}
				setPc={setPc}
				loading={loading}
				setLoading={setLoading}
				setData={setData}
			/>
			<ExpertEdit {...props} id={id} show={showEdit} setShow={setShowEdit} setLoading={setLoading} data={editData} setData={setData} />
			<ExpertEditPass {...props} id={id} show={showPass} setShow={setShowPass} setLoading={setLoading} setData={setData} />
			<Del {...props} id={id} load={load} show={showDel} setShow={setShowDel} handleDelete={handleDelete} />
		</div>
	);
}
