import React from 'react';

import RoleDenied from '../pages/roleDenied';

const CheckRole = ({ role, accessRoles, page }) => {
  if (!accessRoles.length) return null;

  if (accessRoles.includes(role)) return <>{page}</>;

  return <RoleDenied />;
};

export default CheckRole;
