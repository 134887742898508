import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { orgApi } from '../../../services/orgListService';
import { docStatisticApi } from '../../../services/doc/docStatisticService';

import Pagin from '../../../components/pagin';
import Filter from '../../../components/filter';
import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import Container from '../../../components/container';
import CustomSelect from '../../../components/customSelect';

import ExportReceivedExcel from './components/ExportExcelReceived';

import ft from '../../../components/mainStyles.module.scss';

const ReceivedMonitoring = ({ lang, periods, selPer }) =>  {
   const [data, setData] = useState([]);
   const [options, setOptions] = useState([]);
   const [selected, setSelected] = useState([]);
   const [showFilter, setShowFilter] = useState(false);
   const [page, setPage] = useState({ limit: 10, page: 1 });
   const [pageNum, setPageNum] = useState(1);
   const [pc, setPc] = useState(0);
   const [loading, setLoading] = useState(true);
   const [filter, setFilter] = useState({});

   const handleChange = (selected) => {
      setSelected(selected);
   }

   const handleFilter = (e) => {
      e.preventDefault();

      const orgIds = selected.map(s => s.value);

      const { status, start, end } = e.target.elements;

      const newFilter = {};

      if (orgIds.length) newFilter.orgIds = orgIds;
      if (status.value !== 'all') newFilter.status = status.value;
      if (start.value) newFilter.start = start.value;
      if (end.value) newFilter.end = end.value;

      setFilter(newFilter);

      setShowFilter(false);

      setPage({ ...page, page: 1 });
   }

   useEffect(() => {
      setLoading(true);

      docStatisticApi
         .getReceivedStatistics({ ...page, ...filter })
         .then(res => {
            if (res.data.success) {
               setPageNum(Math.ceil(res.data.num));
               setPc((page.page - 1) * page.limit);
               setData(res.data.data);
            }
         })
         .catch(err => {
            console.log(err);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [page]);

   useEffect(() => {
      orgApi.getAll()
         .then(res => {
            if (res.data.success) {
               const DATA = [];

               res.data.data.map((d) => {
                  DATA.push({
                     value: d._id,
                     label: d.name.uz
                  });
               });

               setOptions(DATA);
            }
         })
         .catch(err => {
            console.log(err);
         });
   }, []);

   return (
      <div>
         <Container>
            <div className={ft.content__head}>
               <h5 className={ft.title}>{Translate(lang, 'navbar.additionalReceivedMonitoring')}</h5>

               <div className={ft.btns}>
                  <ExportReceivedExcel
                     lang={lang}
                     filter={filter}
                     period={periods[selPer]}
                  />

                  <button
                     className={`${ft.primary__btn} ml-2`}
                     onClick={() => setShowFilter(true)}
                  >
                     {Translate(lang, 'global.filter')}
                  </button>
               </div>
            </div>

            <div className={ft.table}>
               <table className={ft.table__main}>
                  <thead>
                     <tr>
                        <td>#</td>
                        <td>{Translate(lang, 'global.orgName')}</td>
                        <td>{Translate(lang, 'doc.number')}</td>
                        <td>{Translate(lang, 'doc.description')}</td>
                        <td>{Translate(lang, 'table.status')}</td>
                     </tr>
                  </thead>

                  <tbody>
                     {!loading ? (
                        data?.map((item, index) => (
                           <tr key={index}>
                              <td>{pc + index + 1}</td>
                              <td>{item.organization.name[lang]}</td>
                              <td>{item.doc.number}</td>
                              <td>{item.doc.description}</td>
                              <td>{Translate(lang, `status.${item.status}`)}</td>
                           </tr>
                        ))
                     ) : (
                        <tr>
                           <td colSpan={5}>
                              <Loading cl='mt-3' big={true} size='md' />
                           </td>
                        </tr>
                     )}
                  </tbody>
               </table>

               <iframe name='print_frame' width='0' height='0' frameBorder='0' src='about:blank'></iframe>

               {!loading && !data.length && (
                  <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>
               )}

               {!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
            </div>
         </Container>

         <Filter show={showFilter} setShow={setShowFilter}>
            <Form className={ft.form} onSubmit={handleFilter}>
               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.orgName')}</div>
                  <CustomSelect handleChange={handleChange} options={options} selected={selected} lang={lang} />
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.status')}</div>
                  <select name='status' defaultValue={filter.type}>
                     <option value='all'>{Translate(lang, 'global.all')}</option>
                     <option value='NEW'>{Translate(lang, 'status.NEW')}</option>
                     <option value='SEEN'>{Translate(lang, 'status.SEEN')}</option>
                  </select>
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.from')}</div>
                  <Form.Control name='start' defaultValue={filter.start} type='date' />
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.to')}</div>
                  <Form.Control name='end' defaultValue={filter.end} type='date' />
               </Form.Label>

               <button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
                  {Translate(lang, 'global.search')}
               </button>
            </Form>
         </Filter>
      </div>
   )
}

export default ReceivedMonitoring;
