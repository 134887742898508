import Container from '../../components/container'
import ft from '../../components/mainStyles.module.scss'
import React, { useEffect, useState } from 'react'
import { infosApi } from '../../services/infosService'
import Loading from '../../components/loading'
import Del from '../../components/del'
import Translate from '../../components/lang'
import AddInfosModal from './addInfosModal'
import { edit, del } from '../../components/icons'
import EditInfosModal from './editInfosModal'
import Pagin from '../../components/pagin'
import config from "../../config";

export default function Infos(props) {
	const [data, setData] = useState([]),
		[editData, setEditData] = useState([]),
		[show, setShow] = useState(false),
		[showEdit, setShowEdit] = useState(false),
		[showDel, setShowDel] = useState(false),
		[loading, setLoading] = useState(true),
		[load, setLoad] = useState(false),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[pageNum, setPageNum] = useState(1),
		[pc, setPc] = useState(0),
		[id, setId] = useState(null),
		{ lang, token } = props

	useEffect(() => {
		infosApi.all(page).then((res) => {
			setPage((prev) => {
				let temp = { ...prev }
				if (res.data.num < 1) {
					temp.num = 1
				} else temp.num = Math.trunc(res.data.num)
				return temp
			})
			setPageNum(Math.ceil(res.data.num))
			setPc((page.page - 1) * page.limit)
			setData(res.data.data)
			setLoading(false)
		})
	}, [])

	const handleDelete = (id) => {
		setLoad(true)
		infosApi.delete(id).then((res) => {
			if (res.data.success) {
				setLoad(false)
				setShowDel(false)
				setLoading(true)
				infosApi.all({ limit: 10, page: 1 }).then((res) => {
					setPageNum(Math.ceil(res.data.num))
					setPc(0)
					setData(res.data.data)
					setLoading(false)
				})
			}
		})
	}

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'navbar.yoq')}</h5>
					<div className={ft.btns}>
						<button className={ft.add__btn} onClick={() => setShow(true)}>
							<span className={ft.icon}>+</span>
							{Translate(lang, 'global.add')}
						</button>
					</div>
				</div>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'table.expert')}</td>
								<td>{Translate(lang, 'table.file')}</td>
								<td style={{ width: '10%' }} className='text-center'>
									{Translate(lang, 'table.action')}
								</td>
							</tr>
						</thead>
						<tbody>
							{!loading ? (
								data.map((d, i) => (
									<tr key={i}>
										<td>{pc + i + 1}</td>
										<td>{d?.expert?.fullName}</td>
										<td className='text-nowrap'>
											{d.files.length
												? d?.files.map((i) => {
														return (
															<div>
																<a target='_blank' href={`${config.api.baseUrl}${i?.path}`}>
																	{i?.path?.slice(15)}
																</a>
															</div>
														)
												  })
												: '---'}
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<button
													className={ft.edit__btn}
													onClick={() => {
														setId(d._id)
														setEditData(d)
														setShowEdit(true)
													}}>
													{edit}
												</button>
												<button
													className={ft.del__btn}
													onClick={() => {
														setId(d._id)
														setShowDel(true)
													}}>
													{del}
												</button>
											</div>
										</td>
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>
					{!loading && !data.length && <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>}
					{!loading && <Pagin pageNum={pageNum} page={page} setPage={setPage} />}
				</div>
			</Container>

			{!!show && <AddInfosModal {...props} show={show} setShow={setShow} setPageNum={setPageNum} setPc={setPc} setLoading={setLoading} setData={setData} />}

			{!!showEdit && <EditInfosModal {...props} id={id} show={showEdit} setShow={setShowEdit} setData={setData} setLoading={setLoading} data={editData} />}

			{!!showDel && <Del {...props} id={id} load={load} show={showDel} setShow={setShowDel} handleDelete={handleDelete} />}
		</div>
	)
}
