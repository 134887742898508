import Translate from '../lang';
import Loading from '../loading';
import CustomModal from '../customModal';

import ft from '../mainStyles.module.scss';

export default function Del({ id, show, setShow, handleDelete, load, lang }) {
	return (
		<CustomModal size='sm' del={true} show={show} setShow={setShow}>
			<div
				className='position-relative w-100'
				style={{
					backgroundColor: '#fff',
					borderRadius: 10,
				}}>
				<h5 className='mt-3 mb-3' style={{ fontSize: '18px', color: '#333' }}>
					{Translate(lang, 'delete.title')}
				</h5>
				<div className='w-100 d-flex align-items-center justify-content-between'>
					<button className={`${ft.close__btn} mr-1 text-center`} style={{ width: '130px' }} onClick={() => setShow(false)}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.delete__btn} ml-1 justify-content-center`} style={{ width: '130px' }} onClick={() => handleDelete(id)}>
						{load ? <Loading size='sm' cl='ml-1' /> : Translate(lang, 'delete.del')}
					</button>
				</div>
			</div>
		</CustomModal>
	)
}
