import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import config from '../../../../../config';

import { docReceivedAnswerApi } from '../../../../../services/doc/docReceivedAnswerService';

import Translate from '../../../../../components/lang';
import Loading from '../../../../../components/loading';
import CustomModal from '../../../../../components/customModal';

import { exp } from '../../../../../components/icons';

import { BeautyDate } from '../../../../../utils';

import ft from '../../../../../components/mainStyles.module.scss';
import cls from './CheckTask.module.scss';

const CheckTask = ({ lang, task, onSuccess }) => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState(null);

	const onSubmit = (e) => {
		e.preventDefault();

		const payload = {
			status: status
		};

		const reason = e.target.elements.reason;

		if	(reason) {
			payload.reason = reason.value;
		}

		docReceivedAnswerApi.updateStatus(data._id, payload)
			.then(res => {
				if (res.data.success) {
					setStatus(null);
					onSuccess();
				}
			});
	}

	useEffect(() => {
		setLoading(true);

		docReceivedAnswerApi
			.getLastByTask(task._id)
			.then(res => {
				if (res.data.success) {
					setData(res.data.data);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, [task]);

	return (
		<div className={cls.wrapper}>
			{!loading ? (
				<>
					<div className={cls.detail}>
						<div className={cls.title}>{Translate(lang, 'taskCheck.more')}:</div>
						<div className={cls.value}>{!!task.description ? task.description : '---'}</div>
					</div>

					<div className={cls.detail}>
						<div className={cls.title}>{Translate(lang, 'taskSend.deadline')}:</div>
						<div className={cls.value}>{!!task.deadline ? BeautyDate(task.deadline) : '---'}</div>
					</div>

					<div className={cls.detail}>
						<div className={cls.title}>{Translate(lang, 'taskCheck.attachedFile')}</div>
						<div className={cls.links}>
							{task?.doc?.files?.map((f, index) => (
								<div className={cls.link} key={index}>
									<a href={`${config.api.baseUrl}/${f.path}`} target='_blank'>
										{f.path.slice(15)} {exp}
									</a>
								</div>
							))}
						</div>
					</div>

					<div className={cls.detail}>
						<div className={cls.title}>{Translate(lang, 'doc.link')}:</div>
						<div className={cls.value}>{!!data.link ? data.link : '---'}</div>
					</div>

					<div className={cls.detail}>
						<span className={cls.title}>{Translate(lang, 'taskCheck.executionFile')}</span>
						<div className={cls.links}>
							{!!data?.files?.length
								? data?.files?.map((f, index) => (
									<div className={cls.link} key={index}>
										<a href={`${config.api.baseUrl}/${f.path}`} target='_blank'>
											{f.path.slice(15)} {exp}
										</a>
									</div>
								))
								: `${Translate(lang, 'taskCheck.notUploaded')}`}
						</div>
					</div>

					<div className={cls.btns}>
						<button
							className={`${cls.btn} ${cls.btn__red}`}
							onClick={() => setStatus('REJECT')}
						>
							{Translate(lang, 'taskCheck.notAccepted')}
						</button>

						<button
							className={`${cls.btn} ${cls.btn__green}`}
							onClick={() => setStatus('ACCEPT')}
						>
							{Translate(lang, 'taskCheck.accepted')}
						</button>
					</div>
				</>
			) : (
				<div className={cls.loading}>
					<Loading/>
				</div>
			)}

			<CustomModal
				modalTitle={status === 'ACCEPT' ? `${Translate(lang, 'modal.acceptAnswer')}` : `${Translate(lang, 'modal.reject')}`}
				setShow={() => setStatus(null)}
				show={!!status}
				lang={lang}
			>
				<Form className={ft.form} onSubmit={onSubmit}>
					{status === 'REJECT' && (
						<Form.Label>
							<div className={ft.lab}>Sababi</div>
							<Form.Control name='reason' as='textarea' required />
						</Form.Label>
					)}

					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'global.save')}
					</button>
				</Form>
			</CustomModal>
		</div>
	)
}

export default CheckTask;