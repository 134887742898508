import CustomModal from '../../../../components/customModal'
import ft from '../../../../components/mainStyles.module.scss'
import Translate from '../../../../components/lang'
import ExportPdf from '../../../../components/exportPdf'
import { useEffect, useState } from 'react'
import Loading from '../../../../components/loading'
import { orgApi } from '../../../../services/orgListService'
import Circle from '../../../../components/circle'
import { GetStat } from '../../../../utils'

export default function TaskExpAll(props) {
	const [data, setData] = useState([]),
		[total, setTotal] = useState({
			notDone: 0,
			sended: 0,
			done: 0,
			desmiss: 0,
			total: 0,
		}),
		[load, setLoad] = useState(true),
		{ lang, periods, selPer, filter } = props

	useEffect(() => {
		setLoad(true)
		orgApi
			.getStat({
				page: 1,
				limit: 100000,
				...filter,
				start: periods[selPer]?.start,
				end: periods[selPer]?.end,
				period: periods[selPer]?.id,
			})
			.then((res) => {
				if (res.data.success) {
					const DATA = res.data.data
					setData(GetStat(DATA))
					const temp = { notDone: 0, sended: 0, done: 0, desmiss: 0, total: 0 }
					if (DATA.length) {
						DATA.forEach((d) => {
							if (d?.stat0) temp.notDone += d.stat0
							if (d?.stat1) temp.sended += d.stat1
							if (d?.stat2) temp.done += d.stat2
							if (d?.stat3) temp.desmiss += d.stat3
							if (d?.sum) temp.total += d.sum
						})
					}
					setTotal(temp)
					setLoad(false)
				}
			})
	}, [])

	return (
		<CustomModal {...props} size='super-lg'>
			<div className={`${ft.content__head} justify-content-between`}>
				<div className={`${ft.btns} w-100 justify-content-end`}>
					<ExportPdf {...props} title={Translate(lang, 'navbar.statTask')} id='taskStatExp' />
				</div>
			</div>
			<div id='taskStatExp'>
				<div className={ft.table}>
					<table id='table' className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'global.orgName')}</td>
								<td className='text-center'>{Translate(lang, 'home.uncompleted')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.fileSent')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.received')}</td>
								<td className='text-center'>{Translate(lang, 'homeAll.notReceived')}</td>
								<td className='text-center'>{Translate(lang, 'home.allTasks')}</td>
							</tr>
						</thead>
						<tbody>
							{!load ? (
								data?.map((d, index) => (
									<tr>
										<td>{index + 1}</td>
										<td>{d?.name[lang]}</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat0 ? d?.stat0 : 0} col='255, 0, 0' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat1 ? d?.stat1 : 0} col='46, 143, 233' />
											</div>
										</td>

										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat2 ? d?.stat2 : 0} col='7, 162, 136' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d?.stat3 ? d?.stat3 : 0} col='239, 93, 168' />
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle cl='table' num={d.sum} col='51, 51, 51' />
											</div>
										</td>
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
							{!!!load && (
								<tr>
									<td style={{ paddingTop: '20px', borderTop: '1px solid #ddd', borderBottom: 'none' }}></td>
									<td style={{ paddingTop: '20px', borderTop: '1px solid #ddd', borderBottom: 'none' }}>
										<h5 className='m-0 text-dark'>Jami:</h5>
									</td>
									<td className='text-center' style={{ paddingTop: '20px', borderTop: '1px solid #ddd', borderBottom: 'none' }}>
										<h5 className='m-0 text-dark'>{total.notDone}</h5>
									</td>
									<td className='text-center' style={{ paddingTop: '20px', borderTop: '1px solid #ddd', borderBottom: 'none' }}>
										<h5 className='m-0 text-dark'>{total.sended}</h5>
									</td>
									<td className='text-center' style={{ paddingTop: '20px', borderTop: '1px solid #ddd', borderBottom: 'none' }}>
										<h5 className='m-0 text-dark'>{total.done}</h5>
									</td>
									<td className='text-center' style={{ paddingTop: '20px', borderTop: '1px solid #ddd', borderBottom: 'none' }}>
										<h5 className='m-0 text-dark'>{total.desmiss}</h5>
									</td>
									<td className='text-center' style={{ paddingTop: '20px', borderTop: '1px solid #ddd', borderBottom: 'none' }}>
										<h5 className='m-0 text-dark'>{total.total}</h5>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</CustomModal>
	)
}
